import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ValeurAnswer, ValeurAnswerEntityState } from '@get/api-interfaces';
import { ValeurAnswerRelationsIds } from '@get/store/ids-interfaces';

export const ValeurAnswerGeneratedActions = createActionGroup({
  source: 'Valeur Answer Generated',
  events: {
    'Upsert One Valeur Answer': props<{ valeurAnswer: Partial<ValeurAnswer>; ids?: ValeurAnswerRelationsIds; }>(),
    'Upsert Many Valeur Answers': props<{ valeurAnswers: Partial<ValeurAnswer>[]; ids?: ValeurAnswerRelationsIds; }>(),
    'Upsert Many Valeur Answers Success': props<{ valeurAnswers: ValeurAnswerEntityState[] }>(),
    'Delete One Valeur Answer Success': props<{ idValeurAnswer: number }>(),
    'Normalize Many Valeur Answers After Upsert': props<{ valeurAnswers: ValeurAnswerEntityState[] }>(),
    'Valeur Answers Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Valeur Answers': emptyProps(),
    'Add Valeur Success': props<{ idValeurAnswer: number; idValeur: number }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idValeurAnswers: number[] }>(),
    'Add Patrimoine Success': props<{ idValeurAnswer: number; idPatrimoine: number }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idValeurAnswers: number[] }>()
  }
});
