import { BoardStateEffects } from './board-state.effects';
import { CampagneEffects } from './campagne.effects';
import { CampagnePatrimoineEffects } from './campagne-patrimoine.effects';
import { CampagneSocieteCaracteristiqueEffects } from './campagne-societe-caracteristique.effects';
import { CampagneUserEffects } from './campagne-user.effects';
import { CaracteristiqueTemplateEffects } from './caracteristique-template.effects';
import { CaracteristiqueChoixTemplateEffects } from './caracteristique-choix-template.effects';
import { ComposantEffects } from './composant.effects';
import { ComposantAttenduEffects } from './composant-attendu.effects';
import { ComposantGroupeEffects } from './composant-groupe.effects';
import { ComposantTemplateEffects } from './composant-template.effects';
import { DroitEffects } from './droit.effects';
import { EspaceEffects } from './espace.effects';
import { FichierEffects } from './fichier.effects';
import { NewsletterEffects } from './newsletter.effects';
import { OrganisationAzureLoginEffects } from './organisation-azure-login.effects';
import { OrganisationEffects } from './organisation.effects';
import { OrganisationNewsletterEffects } from './organisation-newsletter.effects';
import { PatrimoineEffects } from './patrimoine.effects';
import { PatrimoineAncetreEffects } from './patrimoine-ancetre.effects';
import { PatrimoineHierarchieTemplateEffects } from './patrimoine-hierarchie-template.effects';
import { ProgBiblioInterventionEffects } from './prog-biblio-intervention.effects';
import { ProgBiblioInterventionScenarioEffects } from './prog-biblio-intervention-scenario.effects';
import { ProgBudgetEffects } from './prog-budget.effects';
import { ProgComposantEffects } from './prog-composant.effects';
import { ProgComposantGroupeEffects } from './prog-composant-groupe.effects';
import { ProgComposantSousGroupeEffects } from './prog-composant-sous-groupe.effects';
import { ProgEtatTechniqueEffects } from './prog-etat-technique.effects';
import { ProgInterventionEffects } from './prog-intervention.effects';
import { ProgInterventionFamilleEffects } from './prog-intervention-famille.effects';
import { ProgInterventionPatrimoineEffects } from './prog-intervention-patrimoine.effects';
import { ProgInterventionScenarioEffects } from './prog-intervention-scenario.effects';
import { ProgPatrimoineInfoEffects } from './prog-patrimoine-info.effects';
import { ProgScenarioEffects } from './prog-scenario.effects';
import { ProgUniteEffects } from './prog-unite.effects';
import { SocieteEffects } from './societe.effects';
import { SocieteCaracteristiqueEffects } from './societe-caracteristique.effects';
import { SocieteCaracteristiqueChoixEffects } from './societe-caracteristique-choix.effects';
import { SocieteConfigAnalyseSyntheseEffects } from './societe-config-analyse-synthese.effects';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEffects } from './societe-config-analyse-synthese-societe-caracteristique.effects';
import { SocieteComposantEffects } from './societe-composant.effects';
import { SocieteComposantFamilleEffects } from './societe-composant-famille.effects';
import { SocieteComposantRattachementEffects } from './societe-composant-rattachement.effects';
import { SocieteEspaceEffects } from './societe-espace.effects';
import { SocieteEspaceFamilleEffects } from './societe-espace-famille.effects';
import { SocietePatrimoineHierarchieEffects } from './societe-patrimoine-hierarchie.effects';
import { SocietePatrimoineHierarchieAncetreEffects } from './societe-patrimoine-hierarchie-ancetre.effects';
import { SocieteProfilEffects } from './societe-profil.effects';
import { SocieteProfilDroitEffects } from './societe-profil-droit.effects';
import { SocieteTerritoireEffects } from './societe-territoire.effects';
import { SocieteTerritoirePatrimoineEffects } from './societe-territoire-patrimoine.effects';
import { SocieteTerritoireUserEffects } from './societe-territoire-user.effects';
import { UsageEffects } from './usage.effects';
import { UsageComposantTemplateEffects } from './usage-composant-template.effects';
import { UsageCaracteristiqueTemplateEffects } from './usage-caracteristique-template.effects';
import { UserEffects } from './user.effects';
import { UserNewsletterEffects } from './user-newsletter.effects';
import { UserPatrimoineEffects } from './user-patrimoine.effects';
import { UserSocieteProfilEffects } from './user-societe-profil.effects';
import { ValeurEffects } from './valeur.effects';
import { ValeurFichierEffects } from './valeur-fichier.effects';
import { ValeurAnswerEffects } from './valeur-answer.effects';
import { ValeurComposantEffects } from './valeur-composant.effects';
import { ValeurPatrimoineEffects } from './valeur-patrimoine.effects';
import { ValeurProgInterventionEffects } from './valeur-prog-intervention.effects';

export const generatedEffects = [
  BoardStateEffects,
  CampagneEffects,
  CampagnePatrimoineEffects,
  CampagneSocieteCaracteristiqueEffects,
  CampagneUserEffects,
  CaracteristiqueTemplateEffects,
  CaracteristiqueChoixTemplateEffects,
  ComposantEffects,
  ComposantAttenduEffects,
  ComposantGroupeEffects,
  ComposantTemplateEffects,
  DroitEffects,
  EspaceEffects,
  FichierEffects,
  NewsletterEffects,
  OrganisationAzureLoginEffects,
  OrganisationEffects,
  OrganisationNewsletterEffects,
  PatrimoineEffects,
  PatrimoineAncetreEffects,
  PatrimoineHierarchieTemplateEffects,
  ProgBiblioInterventionEffects,
  ProgBiblioInterventionScenarioEffects,
  ProgBudgetEffects,
  ProgComposantEffects,
  ProgComposantGroupeEffects,
  ProgComposantSousGroupeEffects,
  ProgEtatTechniqueEffects,
  ProgInterventionEffects,
  ProgInterventionFamilleEffects,
  ProgInterventionPatrimoineEffects,
  ProgInterventionScenarioEffects,
  ProgPatrimoineInfoEffects,
  ProgScenarioEffects,
  ProgUniteEffects,
  SocieteEffects,
  SocieteCaracteristiqueEffects,
  SocieteCaracteristiqueChoixEffects,
  SocieteConfigAnalyseSyntheseEffects,
  SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEffects,
  SocieteComposantEffects,
  SocieteComposantFamilleEffects,
  SocieteComposantRattachementEffects,
  SocieteEspaceEffects,
  SocieteEspaceFamilleEffects,
  SocietePatrimoineHierarchieEffects,
  SocietePatrimoineHierarchieAncetreEffects,
  SocieteProfilEffects,
  SocieteProfilDroitEffects,
  SocieteTerritoireEffects,
  SocieteTerritoirePatrimoineEffects,
  SocieteTerritoireUserEffects,
  UsageEffects,
  UsageComposantTemplateEffects,
  UsageCaracteristiqueTemplateEffects,
  UserEffects,
  UserNewsletterEffects,
  UserPatrimoineEffects,
  UserSocieteProfilEffects,
  ValeurEffects,
  ValeurFichierEffects,
  ValeurAnswerEffects,
  ValeurComposantEffects,
  ValeurPatrimoineEffects,
  ValeurProgInterventionEffects,
];
