import { IRowNode } from '@ag-grid-community/core';
import { SocietePatrimoineHierarchie, SocieteTerritoire } from '@get/api-interfaces';
import { compareStrings } from './string.utils';

export function comparePatrimoineHierarchieFn(a: SocietePatrimoineHierarchie, b: SocietePatrimoineHierarchie): boolean {
  return a?.idSocietePatrimoineHierarchie === b?.idSocietePatrimoineHierarchie;
}

export function compareTerritoireFn(a: SocieteTerritoire, b: SocieteTerritoire): boolean {
  return a?.idSocieteTerritoire === b?.idSocieteTerritoire;
}

export function compareValueWithSubgroupFn<T, U>(valueA: T, valueB: T, nodeA: IRowNode<U>, nodeB: IRowNode<U>): number {
  if (nodeA?.group && !nodeB?.group) {
    return -1;
  } else if (!nodeA?.group && nodeB?.group) {
    return 1;
  } else if (valueA && !valueB) {
    return -1;
  } else if (!valueA && valueB) {
    return 1;
  } else if (typeof valueA === 'string' && typeof valueB === 'string') {
    return compareStrings(valueA, valueB);
  } else {
    return 0;
  }
}
