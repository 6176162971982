import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { ValeurComposant, ValeurComposantEntityState } from '@get/api-interfaces';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { ValeurPatrimoine, ValeurPatrimoineEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { ValeurFichier, ValeurFichierEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { ValeurProgIntervention, ValeurProgInterventionEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { ValeurAnswer, ValeurAnswerEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ValeurState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const valeurRelations: string[] = ['valeurComposants','composants','valeurPatrimoines','patrimoines','valeurFichiers','fichiers','valeurProgInterventions','progInterventions','valeurAnswers','societeCaracteristiqueChoices','societeCaracteristiques','users',];

export const { selectEntities, selectAll } = ValeurState.adapter.getSelectors();

export const selectValeurState = createFeatureSelector<ValeurState.IState>(ValeurState.valeurFeatureKey);

export const selectIsLoadedValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => state.isLoaded
);

export const selectIsLoadingValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => state.isLoading
);

export const selectIsReadyValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => state.isLoaded && !state.isLoading
);

export const selectValeursEntities = createSelector(selectValeurState, selectEntities);

export const selectValeursArray = createSelector(selectValeurState, selectAll);

const valeursInObject = (valeurs: Dictionary<ValeurEntityState>) => ({ valeurs })

const selectValeursEntitiesDictionary = createSelector(selectValeursEntities, valeursInObject);

const selectAllValeursObject = createSelector(selectValeursEntities, valeurs => {
  return hydrateAll({ valeurs });
});

const selectOneValeurDictionary = (idValeur : number) =>
  createSelector(selectValeursEntities, valeurs => ({
    valeurs: { [idValeur]: valeurs[idValeur] }
  }));

const selectOneValeurDictionaryWithoutChild = (idValeur : number) =>
  createSelector(selectValeursEntities, valeurs => ({
    valeur: valeurs[idValeur]
  }));

const selectAllValeursSelectors: Dictionary<Selector> = {};
export function selectAllValeurs(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Valeur>(
      schema,
      selectAllValeursSelectors,
      selectValeursEntitiesDictionary,
      getRelationSelectors,
      valeurRelations,
      hydrateAll,
      'valeur'
    );
  } else {
    return selectAllValeursObject;
  }
}

export function selectAllValeursDictionary(
  schema: SelectSchema = {},
  customKey = 'valeurs'
): Selector {
  return createSelector(selectAllValeurs(schema), result => {
    const res = { [customKey]: {} as Dictionary<ValeurEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.valeurs.length; i++) {
      res[customKey][result.valeurs[i].idValeur] = result.valeurs[i];
    }
    return res;
  });
}

export function selectOneValeur(
  schema: SelectSchema = {},
  idValeur: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneValeurDictionary(idValeur)];
  selectors.push(...getRelationSelectors(schema, valeurRelations, 'valeur'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneValeurDictionaryWithoutChild(idValeur);
  }
}

interface hydrateArgs {
  valeurs: Dictionary<ValeurEntityState>;
  societeCaracteristiqueChoices?: Dictionary<SocieteCaracteristiqueChoixEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
  users?: Dictionary<UserEntityState>;
  valeurComposants?: Dictionary<ValeurComposantEntityState>;
  composants?: Dictionary<ComposantEntityState>;
  valeurPatrimoines?: Dictionary<ValeurPatrimoineEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  valeurFichiers?: Dictionary<ValeurFichierEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  valeurProgInterventions?: Dictionary<ValeurProgInterventionEntityState>;
  progInterventions?: Dictionary<ProgInterventionEntityState>;
  valeurAnswers?: Dictionary<ValeurAnswerEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { valeurs: (Valeur | null)[] } {
  const {
    valeurs,
    societeCaracteristiqueChoices,
    societeCaracteristiques,
    users,
    valeurComposants,
    composants,
    valeurPatrimoines,
    patrimoines,
    valeurFichiers,
    fichiers,
    valeurProgInterventions,
    progInterventions,
    valeurAnswers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    valeurs: Object.keys(valeurs).map(idValeur =>
      hydrate(
        valeurs[idValeur] as ValeurEntityState,
        societeCaracteristiqueChoices,
        societeCaracteristiques,
        users,
        valeurComposants,
        composants,
        valeurPatrimoines,
        patrimoines,
        valeurFichiers,
        fichiers,
        valeurProgInterventions,
        progInterventions,
        valeurAnswers
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { valeur: ValeurEntityState | null } {
  const {
    valeurs,
    societeCaracteristiqueChoices,
    societeCaracteristiques,
    users,
    valeurComposants,
    composants,
    valeurPatrimoines,
    patrimoines,
    valeurFichiers,
    fichiers,
    valeurProgInterventions,
    progInterventions,
    valeurAnswers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const valeur = Object.values(valeurs)[0];
  return {
    valeur: hydrate(
      valeur as ValeurEntityState,
      societeCaracteristiqueChoices,
      societeCaracteristiques,
      users,
      valeurComposants,
      composants,
      valeurPatrimoines,
      patrimoines,
      valeurFichiers,
      fichiers,
      valeurProgInterventions,
      progInterventions,
      valeurAnswers
    )
  };
}

function hydrate(
  valeur: ValeurEntityState,
  societeCaracteristiqueChoixEntities?: Dictionary<SocieteCaracteristiqueChoixEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  valeurComposantEntities?: Dictionary<ValeurComposantEntityState>,
  composantEntities?: Dictionary<ComposantEntityState>,
  valeurPatrimoineEntities?: Dictionary<ValeurPatrimoineEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  valeurFichierEntities?: Dictionary<ValeurFichierEntityState>,
  fichierEntities?: Dictionary<FichierEntityState>,
  valeurProgInterventionEntities?: Dictionary<ValeurProgInterventionEntityState>,
  progInterventionEntities?: Dictionary<ProgInterventionEntityState>,
  valeurAnswerEntities?: Dictionary<ValeurAnswerEntityState>,
): Valeur | null {
  if (!valeur) {
    return null;
  }

  const valeurHydrated: ValeurEntityState = { ...valeur };
  if (societeCaracteristiqueChoixEntities) {
    valeurHydrated.societeCaracteristiqueChoix = societeCaracteristiqueChoixEntities[valeur.societeCaracteristiqueChoix as number] as SocieteCaracteristiqueChoix;
  } else {
    delete valeurHydrated.societeCaracteristiqueChoix;
  }
  if (societeCaracteristiqueEntities) {
    valeurHydrated.societeCaracteristique = societeCaracteristiqueEntities[valeur.societeCaracteristique as number] as SocieteCaracteristique;
  } else {
    delete valeurHydrated.societeCaracteristique;
  }
  if (userEntities) {
    valeurHydrated.user = userEntities[valeur.user as number] as User;
  } else {
    delete valeurHydrated.user;
  }

  if (valeurComposantEntities) {
    valeurHydrated.valeurComposants = ((valeurHydrated.valeurComposants as number[]) || []).map(
      id => valeurComposantEntities[id]
    ) as ValeurComposant[];
  } else {
    delete valeurHydrated.valeurComposants;
  }

  if (composantEntities) {
    valeurHydrated.composants = ((valeurHydrated.composants as number[]) || []).map(
      id => composantEntities[id]
    ) as Composant[];
  } else {
    delete valeurHydrated.composants;
  }

  if (valeurPatrimoineEntities) {
    valeurHydrated.valeurPatrimoines = ((valeurHydrated.valeurPatrimoines as number[]) || []).map(
      id => valeurPatrimoineEntities[id]
    ) as ValeurPatrimoine[];
  } else {
    delete valeurHydrated.valeurPatrimoines;
  }

  if (patrimoineEntities) {
    valeurHydrated.patrimoines = ((valeurHydrated.patrimoines as number[]) || []).map(
      id => patrimoineEntities[id]
    ) as Patrimoine[];
  } else {
    delete valeurHydrated.patrimoines;
  }

  if (valeurFichierEntities) {
    valeurHydrated.valeurFichiers = ((valeurHydrated.valeurFichiers as number[]) || []).map(
      id => valeurFichierEntities[id]
    ) as ValeurFichier[];
  } else {
    delete valeurHydrated.valeurFichiers;
  }

  if (fichierEntities) {
    valeurHydrated.fichiers = ((valeurHydrated.fichiers as number[]) || []).map(
      id => fichierEntities[id]
    ) as Fichier[];
  } else {
    delete valeurHydrated.fichiers;
  }

  if (valeurProgInterventionEntities) {
    valeurHydrated.valeurProgInterventions = ((valeurHydrated.valeurProgInterventions as number[]) || []).map(
      id => valeurProgInterventionEntities[id]
    ) as ValeurProgIntervention[];
  } else {
    delete valeurHydrated.valeurProgInterventions;
  }

  if (progInterventionEntities) {
    valeurHydrated.progInterventions = ((valeurHydrated.progInterventions as number[]) || []).map(
      id => progInterventionEntities[id]
    ) as ProgIntervention[];
  } else {
    delete valeurHydrated.progInterventions;
  }

  if (valeurAnswerEntities) {
    valeurHydrated.valeurAnswers = ((valeurHydrated.valeurAnswers as number[]) || []).map(
      id => valeurAnswerEntities[id]
    ) as ValeurAnswer[];
  } else {
    delete valeurHydrated.valeurAnswers;
  }

  return valeurHydrated as Valeur;
}
