import { CaracteristiqueTypeEnum } from '@enums';
import {
  DynamicType,
  Nullable,
  Patrimoine,
  SocieteCaracteristique,
  SocieteCaracteristiqueChoix,
  Valeur,
  ValeurPatrimoineToUpdate,
  ValeurToUpdate
} from '@get/api-interfaces';
import { dayjsDate, formatDateFullYear } from '../date.utils';
import { MAX_INT, MIN_INT } from '../number.utils';
import { filterValeurFichiers, isFichierDocument, isFichierImage } from './fichier.utils';

export function getValeurRawValue(valeur: Valeur, societeCaracteristique: SocieteCaracteristique) {
  if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.booleen) {
    return valeur?.valeurBooleen;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.choix) {
    return valeur?.idSocieteCaracteristiqueChoix;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.decimal) {
    return valeur?.valeurDecimal;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.integer) {
    return valeur?.valeurInteger;
  } else if (
    valeur &&
    (societeCaracteristique?.type === CaracteristiqueTypeEnum.string ||
      societeCaracteristique?.type === CaracteristiqueTypeEnum.phone ||
      societeCaracteristique?.type === CaracteristiqueTypeEnum.email)
  ) {
    return valeur?.valeurString;
  } else if (
    valeur &&
    (societeCaracteristique?.type === CaracteristiqueTypeEnum.longText ||
      societeCaracteristique?.type === CaracteristiqueTypeEnum.hyperlink)
  ) {
    return valeur?.valeurLongText;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.date) {
    return dayjsDate(valeur?.valeurDate)?.toISOString() || null;
  }
  return null;
}

export function getActualValue(
  valeur?: Valeur,
  societeCaracteristique?: SocieteCaracteristique,
  patrimoinesValeurAnswerDict?: DynamicType<Patrimoine>,
  preferLibelleTechnique = false
) {
  if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.booleen) {
    return valeur?.valeurBooleen;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.choix) {
    return preferLibelleTechnique
      ? valeur?.societeCaracteristiqueChoix?.libelleTechnique
      : valeur?.societeCaracteristiqueChoix?.libelle;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.decimal) {
    return valeur?.valeurDecimal;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.integer) {
    return valeur?.valeurInteger;
  } else if (
    valeur &&
    (societeCaracteristique?.type === CaracteristiqueTypeEnum.string ||
      societeCaracteristique?.type === CaracteristiqueTypeEnum.phone ||
      societeCaracteristique?.type === CaracteristiqueTypeEnum.email)
  ) {
    return valeur?.valeurString;
  } else if (
    valeur &&
    (societeCaracteristique?.type === CaracteristiqueTypeEnum.longText ||
      societeCaracteristique?.type === CaracteristiqueTypeEnum.hyperlink)
  ) {
    return valeur?.valeurLongText;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.date) {
    return dayjsDate(valeur?.valeurDate)?.toISOString() || null;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.images) {
    const images = filterValeurFichiers(valeur?.valeurFichiers, isFichierImage);
    return `${images?.length || 'Aucune'} image${images?.length > 1 ? 's' : ''}`;
  } else if (valeur && societeCaracteristique?.type === CaracteristiqueTypeEnum.documents) {
    const documents = filterValeurFichiers(valeur?.valeurFichiers, isFichierDocument);
    return `${documents?.length || 'Aucun'} document${documents?.length > 1 ? 's' : ''}`;
  } else if (
    valeur &&
    societeCaracteristique?.type === CaracteristiqueTypeEnum.patrimoines &&
    patrimoinesValeurAnswerDict
  ) {
    return valeur?.valeurAnswers?.map(
      el =>
        (patrimoinesValeurAnswerDict[el?.idPatrimoine]?.libelle?.length
          ? patrimoinesValeurAnswerDict[el?.idPatrimoine]?.libelle
          : patrimoinesValeurAnswerDict[el?.idPatrimoine]?.reference) ?? ''
    );
  }
  return null;
}

export function sumValeurInteger(valeurs: { valeurInteger: number }[]): number {
  let nbToAdd = -1;
  for (let idx = 0; idx < valeurs?.length; idx++) {
    const val = valeurs[idx];
    if (
      !isNaN(val.valeurInteger) &&
      // Obligatoire car null & false ne correspondent pas à NaN donc on pourrait les accepter alors qu'il ne faut pas
      (val.valeurInteger > 0 || val.valeurInteger === 0)
    ) {
      nbToAdd = nbToAdd < 0 ? val.valeurInteger : nbToAdd + val.valeurInteger;
    }
  }
  // Si il n'y a pas de valeur de type decompte ou qu'aucune n'est remplie, on ajoute 1
  if (nbToAdd < 0) {
    nbToAdd = 1;
  }
  return nbToAdd;
}

export function getTypeValeurToUpdate(valeurToUpdate: ValeurToUpdate): Nullable<Partial<Valeur>> {
  const propertyToUpdate: Nullable<Partial<Valeur>> = {
    idSocieteCaracteristique: valeurToUpdate.idSocieteCaracteristique,
    valeurDate: null,
    valeurBooleen: null,
    valeurDecimal: null,
    valeurInteger: null,
    valeurString: null,
    valeurLongText: null,
    idSocieteCaracteristiqueChoix: null
  };
  if (valeurToUpdate.idValeur) {
    propertyToUpdate.idValeur = valeurToUpdate.idValeur;
  }
  if (valeurToUpdate.type === CaracteristiqueTypeEnum.integer) {
    propertyToUpdate.valeurInteger = valeurToUpdate.valeur;
  } else if (
    valeurToUpdate.type === CaracteristiqueTypeEnum.string ||
    valeurToUpdate.type === CaracteristiqueTypeEnum.phone ||
    valeurToUpdate.type === CaracteristiqueTypeEnum.email
  ) {
    propertyToUpdate.valeurString = valeurToUpdate.valeur;
  } else if (
    valeurToUpdate.type === CaracteristiqueTypeEnum.longText ||
    valeurToUpdate.type === CaracteristiqueTypeEnum.hyperlink
  ) {
    propertyToUpdate.valeurLongText = valeurToUpdate.valeur;
  } else if (valeurToUpdate.type === CaracteristiqueTypeEnum.decimal) {
    propertyToUpdate.valeurDecimal = valeurToUpdate.valeur;
  } else if (valeurToUpdate.type === CaracteristiqueTypeEnum.date) {
    propertyToUpdate.valeurDate = valeurToUpdate.valeur ? new Date(valeurToUpdate.valeur) : valeurToUpdate.valeur;
  } else if (valeurToUpdate.type === CaracteristiqueTypeEnum.booleen) {
    propertyToUpdate.valeurBooleen = valeurToUpdate.valeur;
  } else if (valeurToUpdate.type === CaracteristiqueTypeEnum.choix) {
    propertyToUpdate.idSocieteCaracteristiqueChoix = valeurToUpdate.valeur;
  }
  return propertyToUpdate;
}

export function getValeurDependingOnSocieteCaracType(
  valeursToUpdate: ValeurPatrimoineToUpdate[],
  valeurs: Valeur[]
): Partial<Valeur>[] {
  const valeursObj: DynamicType<Valeur> = {};
  let i = 0;
  while (i < valeurs.length) {
    const valeur = valeurs[i];
    valeursObj[`${valeur.idSocieteCaracteristique}-${valeur.valeurPatrimoines[0].idPatrimoine}`] = valeur;
    i++;
  }
  return valeursToUpdate.map(valeurToUpdate => {
    const valeurInDb = valeursObj[`${valeurToUpdate.idSocieteCaracteristique}-${valeurToUpdate.idPatrimoine}`];
    return {
      ...getTypeValeurToUpdate(valeurToUpdate),
      idValeur: valeurInDb.idValeur
    } as Valeur;
  });
}

// TODO: Tester cette fonction car elle permet de mettre à jour ou non une valeur dans indexedDb
// Permet de comparer une valeur dans indexedDb et une valeur retournée par le back
// En prenant en compte que certains objets possèdent les clés à null et d'autres ne les ont pas donc undefined
export function areValeursEqual(valeurA: Valeur, valeurB: Valeur): boolean {
  return (
    (valeurA.idSocieteCaracteristiqueChoix === valeurB.idSocieteCaracteristiqueChoix ||
      (!valeurA.idSocieteCaracteristiqueChoix && !valeurB.idSocieteCaracteristiqueChoix)) &&
    (valeurA.valeurBooleen === valeurB.valeurBooleen ||
      (!valeurA.valeurBooleen &&
        !valeurB.valeurBooleen &&
        valeurA.valeurBooleen !== false &&
        valeurB.valeurBooleen !== false)) &&
    (valeurA.valeurDate === valeurB.valeurDate || (!valeurA.valeurDate && !valeurB.valeurDate)) &&
    (valeurA.valeurDecimal === valeurB.valeurDecimal ||
      (valeurA.valeurDecimal !== 0 &&
        valeurB.valeurDecimal !== 0 &&
        !valeurA.valeurDecimal &&
        !valeurB.valeurDecimal)) &&
    (valeurA.valeurInteger === valeurB.valeurInteger ||
      (valeurA.valeurInteger !== 0 &&
        valeurB.valeurInteger !== 0 &&
        !valeurA.valeurInteger &&
        !valeurB.valeurInteger)) &&
    (valeurA.valeurLongText === valeurB.valeurLongText || (!valeurA.valeurLongText && !valeurB.valeurLongText)) &&
    (valeurA.valeurString === valeurB.valeurString || (!valeurA.valeurString && !valeurB.valeurString)) &&
    (valeurA.valeurFichiers?.length || 0) === (valeurB.valeurFichiers?.length || 0)
  );
}

export function getValeurByTypeAndReturnArray(
  type: CaracteristiqueTypeEnum,
  valeur: Valeur,
  societeCaracteristiqueChoicesDict: DynamicType<SocieteCaracteristiqueChoix>
) {
  if (type === CaracteristiqueTypeEnum.string) {
    return valeur?.valeurString ?? '';
  } else if (type === CaracteristiqueTypeEnum.longText) {
    return valeur?.valeurLongText ?? '';
  } else if (type === CaracteristiqueTypeEnum.integer) {
    return valeur?.valeurInteger ?? '';
  } else if (type === CaracteristiqueTypeEnum.decimal) {
    return valeur?.valeurDecimal ?? '';
  } else if (type === CaracteristiqueTypeEnum.booleen) {
    return valeur?.valeurBooleen ? 'Oui' : 'Non';
  } else if (type === CaracteristiqueTypeEnum.date) {
    return formatDateFullYear(valeur?.valeurDate) ?? '';
  } else if (type === CaracteristiqueTypeEnum.email) {
    return valeur?.valeurString ?? '';
  } else if (type === CaracteristiqueTypeEnum.hyperlink) {
    return valeur?.valeurLongText ?? '';
  } else if (type === CaracteristiqueTypeEnum.phone) {
    return valeur?.valeurString ?? '';
  } else if (type === CaracteristiqueTypeEnum.choix) {
    return societeCaracteristiqueChoicesDict[valeur?.idSocieteCaracteristiqueChoix]?.libelleTechnique ?? '';
  }
  return '';
}

export function isValeurErrored(valeur: Valeur): boolean {
  if (valeur) {
    if (
      (valeur.valeurDecimal &&
        (valeur.valeurDecimal < MIN_INT || valeur.valeurDecimal > MAX_INT || isNaN(+valeur.valeurDecimal))) ||
      Array.isArray(valeur.valeurDecimal)
    ) {
      return true;
    }
    if (
      (valeur.valeurInteger &&
        (valeur.valeurInteger < MIN_INT || valeur.valeurInteger > MAX_INT || isNaN(+valeur.valeurInteger))) ||
      Array.isArray(valeur.valeurInteger)
    ) {
      return true;
    }
    if (Array.isArray(valeur.valeurDate)) {
      return true;
    }
    if (valeur.valeurDate) {
      const dayDate = dayjsDate(valeur.valeurDate);
      const year = dayDate?.year() as number;
      return !dayDate || !dayDate.isValid() || year >= 10000 || year < 1000;
    }
  }
  return false;
}
