import { Injectable } from '@angular/core';
import { CaracteristiqueTypeEnum, InfoAdditionnelleEnum } from '@enums';
import {
  Composant,
  ComposantEntityState,
  ComposantWithIdPatrimoine,
  DynamicType,
  InfosToMutateOfflineDbWithNewComposant,
  MassModificationComposant,
  Patrimoine,
  SocieteCaracteristique,
  SocieteComposant,
  SocietePatrimoineHierarchie,
  Valeur,
  ValeurAnswer,
  ValeurEntityState,
  ValeurFichier
} from '@get/api-interfaces';
import {
  ComposantAttenduDocument,
  ComposantDocument,
  MyDatabaseCollections,
  PatrimoineDocument,
  ValeurDocument
} from '@get/interfaces';
import { ComposantActions, ComposantGeneratedActions, EspaceGeneratedActions } from '@get/store/actions';
import { ComposantApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { AppState } from '@get/store/configs/reducers';
import { getDefaultDeleteComposantActions, getDefaultDeleteValeurActions } from '@get/store/effects';
import { ComposantSelectors, ValeurSelectors } from '@get/store/selectors';
import { compareOfflineAndBackComposantAttendus } from '@get/utils';
import { Action, Store } from '@ngrx/store';
import { areValeursEqual, transformArrayToObject } from '@utils';
import { RxDocument } from 'rxdb';
import { Observable, catchError, combineLatest, first, map, of, switchMap, tap } from 'rxjs';
import { v4 as uuidGenerator } from 'uuid';
import { ComposantAttenduDbService } from './composant-attendu.db.service';
import { DbService } from './db.service';

@Injectable({ providedIn: 'root' })
export class ComposantDbService {
  constructor(
    private dbService: DbService,
    private store$: Store<AppState>,
    private composantApiService: ComposantApiService,
    private readonly composantAttenduDbService: ComposantAttenduDbService
  ) {}

  // ====================================================== //
  // ====================== CREATION ====================== //
  // ====================================================== //
  public async createComposant(
    params: {
      idSocieteComposant: number;
      idEspace: number;
      patrimoine: Patrimoine;
      societeComposant: SocieteComposant;
      idUser?: number;
      selectors: {
        selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
        selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
        selectAllSocieteComposants: Observable<SocieteComposant[]>;
      };
    },
    callback?: (_params: InfosToMutateOfflineDbWithNewComposant) => void
  ): Promise<string> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const { newIdComposant, newIdValeur } = await this.getLowestIdComposantAndIdValeur(databaseCollections);
    const generatedUUID = uuidGenerator();
    const lowestIdValeurAnswer = await this.getLowestIdValeurAnswer(databaseCollections);

    const newDocument = await databaseCollections.composants.insert({
      idPatrimoine: params.patrimoine.idPatrimoine,
      idComposant: newIdComposant,
      uuid: generatedUUID,
      idEspace: params.idEspace,
      espace: { idEspace: params.idEspace },
      idSocieteComposant: params.idSocieteComposant,
      societeComposant: params.idSocieteComposant,
      idUser: params.idUser,
      user: params.idUser,
      updatedAt: new Date().toISOString(),
      isSynced: false,
      valeurs:
        params.societeComposant?.societeCaracteristiques?.map((el, idx) => ({
          idValeur: newIdValeur - idx,
          idSocieteCaracteristique: el.idSocieteCaracteristique,
          societeCaracteristique: el.idSocieteCaracteristique,
          isSynced: false,
          idUser: params.idUser,
          valeurAnswers:
            el?.type === CaracteristiqueTypeEnum.patrimoines
              ? [
                  {
                    idValeurAnswer: lowestIdValeurAnswer.newIdValeurAnswer - idx,
                    idValeur: newIdValeur - idx,
                    idPatrimoine: params.patrimoine.idPatrimoine
                  }
                ]
              : []
        })) || []
    });
    const updatedComposantAttendus = await this.composantAttenduDbService.recalculateComposantAttenduTreeForPatrimoine(
      params.patrimoine.idPatrimoine,
      params.idSocieteComposant,
      params.selectors,
      newDocument,
      params.idUser
    );
    this.synchronizeIdComposantFromIndexedDb(generatedUUID, callback, updatedComposantAttendus);
    return generatedUUID;
  }

  public async massComposantCreation(
    societeComposant: SocieteComposant,
    toCreate: ComposantWithIdPatrimoine[],
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    },
    idUser?: number,
    callback?: (_params: InfosToMutateOfflineDbWithNewComposant) => void
  ): Promise<void> {
    const nbCaracteristiques = societeComposant.societeCaracteristiques?.length;
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const { newIdComposant, newIdValeur } = await this.getLowestIdComposantAndIdValeur(databaseCollections);
    const lowestIdValeurAnswer = await this.getLowestIdValeurAnswer(databaseCollections);
    let newIdValeurAnswer = lowestIdValeurAnswer.newIdValeurAnswer;

    const composantsWithValeursObj = toCreate.map(el => ({
      ...el,
      valeursObj: transformArrayToObject(el.valeurs, { key: 'idSocieteCaracteristique' }) as DynamicType<Valeur>
    }));
    const formattedComposants = composantsWithValeursObj.map((composant, composantIdx) => ({
      idPatrimoine: composant.idPatrimoine,
      idEspace: composant.idEspace,
      idSocieteComposant: composant.idSocieteComposant,
      idComposant: newIdComposant - composantIdx,
      uuid: uuidGenerator(),
      espace: { idEspace: composant.idEspace },
      societeComposant: composant.idSocieteComposant,
      idUser: idUser,
      user: idUser,
      updatedAt: new Date().toISOString(),
      isSynced: false,
      valeursObj: undefined,
      valeurs:
        societeComposant.societeCaracteristiques?.map((societeCaracteristique, caracIdx) => {
          const idValeur = newIdValeur - caracIdx - nbCaracteristiques * composantIdx;
          const valeur = {
            idValeur: idValeur,
            idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
            societeCaracteristique: societeCaracteristique.idSocieteCaracteristique,
            isSynced: false,
            idUser: idUser
          };

          if (societeCaracteristique?.type === CaracteristiqueTypeEnum.patrimoines) {
            return {
              ...valeur,
              valeurAnswers: [
                {
                  idValeurAnswer: newIdValeurAnswer--,
                  idValeur: idValeur,
                  idPatrimoine: composant.idPatrimoine
                }
              ]
            };
          }
          return !composant.valeursObj[societeCaracteristique.idSocieteCaracteristique]
            ? valeur
            : {
                ...composant.valeursObj[societeCaracteristique.idSocieteCaracteristique],
                ...valeur,
                societeCaracteristiqueChoix:
                  composant.valeursObj[societeCaracteristique.idSocieteCaracteristique]?.idSocieteCaracteristiqueChoix
              };
        }) || []
    }));
    await databaseCollections.composants.bulkInsert(formattedComposants);
    const patrimoinesInvolved: number[] = [];
    await Promise.all(
      formattedComposants.map(async composant => {
        const patrimoineDocument = await this.findPatrimoineDocumentForEspace({
          idEspace: composant.idEspace,
          databaseCollections
        });
        if (patrimoineDocument) {
          patrimoinesInvolved.push(+patrimoineDocument.idPatrimoine);
        }
        return;
      })
    );

    const patrimoinesDocuments = await databaseCollections.patrimoines.find().exec();
    const patrimoines = patrimoinesDocuments
      .map(el => el.getLatest().toJSON() as unknown as Patrimoine)
      .map(el => ({ ...el, idPatrimoine: +el.idPatrimoine }));
    const patrimoinesObj = transformArrayToObject(patrimoines, { key: 'idPatrimoine' });

    const highestPatrimoines: number[] = [];
    for (let i = 0; i < patrimoinesInvolved?.length; i++) {
      const highestPatrimoine = this.composantAttenduDbService.findHighestAncestorId(
        patrimoinesObj,
        patrimoinesInvolved[i]
      );
      if (highestPatrimoine && !highestPatrimoines.includes(highestPatrimoine)) {
        highestPatrimoines.push(highestPatrimoine);
      }
    }

    const updatedComposantAttendus = [];
    for (let i = 0; i < highestPatrimoines?.length; i++) {
      const composantAttendus = await this.composantAttenduDbService.recalculateComposantAttenduTreeForPatrimoine(
        highestPatrimoines[i],
        societeComposant.idSocieteComposant,
        selectors,
        undefined,
        idUser
      );
      updatedComposantAttendus.push(...composantAttendus);
    }
    this.synchronizeFromIndexedDb(callback, updatedComposantAttendus);
  }

  public async duplicateComposant(
    params: {
      composant: Composant;
      idEspace: number;
      patrimoine: Patrimoine;
      idUser?: number;
      selectors: {
        selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
        selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
        selectAllSocieteComposants: Observable<SocieteComposant[]>;
      };
    },
    callback?: (_params: InfosToMutateOfflineDbWithNewComposant) => void
  ): Promise<string> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const { newIdComposant, newIdValeur } = await this.getLowestIdComposantAndIdValeur(databaseCollections);
    const generatedUUID = uuidGenerator();
    const lowestIdValeurAnswer = await this.getLowestIdValeurAnswer(databaseCollections);

    const newDocument = await databaseCollections.composants.insert({
      idPatrimoine: params.patrimoine.idPatrimoine,
      idComposant: newIdComposant,
      uuid: generatedUUID,
      idEspace: params.idEspace,
      espace: { idEspace: params.idEspace },
      idSocieteComposant: params.composant.idSocieteComposant,
      societeComposant: params.composant.idSocieteComposant,
      idUser: params.idUser,
      user: params.idUser,
      updatedAt: new Date().toISOString(),
      isSynced: false,
      valeurs:
        params.composant?.valeurs?.map((el, idx) => ({
          ...el,
          idValeur: newIdValeur - idx,
          societeCaracteristique: el.idSocieteCaracteristique,
          societeCaracteristiqueChoix: el.idSocieteCaracteristiqueChoix,
          isSynced: false,
          idUser: params.idUser,
          valeurFichiers: undefined,
          valeurAnswers:
            el?.societeCaracteristique?.type === CaracteristiqueTypeEnum.patrimoines
              ? el?.valeurAnswers?.map((valeurAnswer, idxValeurAnswer) => ({
                  idValeurAnswer: lowestIdValeurAnswer.newIdValeurAnswer - idxValeurAnswer,
                  idValeur: newIdValeur - idx,
                  idPatrimoine: valeurAnswer?.idPatrimoine
                })) ?? []
              : []
        })) || []
    });

    const updatedComposantAttendus = await this.composantAttenduDbService.recalculateComposantAttenduTreeForPatrimoine(
      params.patrimoine.idPatrimoine,
      params.composant.idSocieteComposant,
      params.selectors,
      newDocument,
      params.idUser
    );
    this.synchronizeIdComposantFromIndexedDb(generatedUUID, callback, updatedComposantAttendus);

    return generatedUUID;
  }

  public async massComposantDuplication(
    toCreate: ComposantWithIdPatrimoine[],
    societeCaracteristiques: SocieteCaracteristique[],
    idsSocieteComposant: number[],
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    },
    idUser?: number,
    callback?: (_params: InfosToMutateOfflineDbWithNewComposant) => void
  ): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const { newIdComposant, newIdValeur } = await this.getLowestIdComposantAndIdValeur(databaseCollections);
    const composantsWithValeursObj = toCreate.map(el => ({
      ...el,
      valeursObj: transformArrayToObject(el.valeurs, { key: 'idSocieteCaracteristique' }) as DynamicType<Valeur>
    }));
    const lowestIdValeurAnswer = await this.getLowestIdValeurAnswer(databaseCollections);
    let newIdValeurAnswer = lowestIdValeurAnswer.newIdValeurAnswer;

    const formattedComposants = composantsWithValeursObj.map((composant, composantIdx) => {
      const societeCaracteristiquesFiltered = societeCaracteristiques.filter(
        el => el.idSocieteComposant === composant.idSocieteComposant
      );
      return {
        idPatrimoine: composant.idPatrimoine,
        idEspace: composant.idEspace,
        idSocieteComposant: composant.idSocieteComposant,
        idComposant: newIdComposant - composantIdx,
        uuid: uuidGenerator(),
        espace: { idEspace: composant.idEspace },
        societeComposant: composant.idSocieteComposant,
        idUser: idUser,
        user: idUser,
        updatedAt: new Date().toISOString(),
        isSynced: false,
        valeursObj: undefined,
        valeurs:
          societeCaracteristiquesFiltered?.map((societeCaracteristique, caracIdx) => {
            const idValeur = newIdValeur - caracIdx - societeCaracteristiquesFiltered?.length * composantIdx;
            const valeur = {
              idValeur: idValeur,
              idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
              societeCaracteristique: societeCaracteristique.idSocieteCaracteristique,
              isSynced: false,
              valeurFichiers: undefined,
              idUser: idUser
            };
            if (societeCaracteristique?.type === CaracteristiqueTypeEnum.patrimoines) {
              const valeurAnswers = (
                composant.valeursObj[societeCaracteristique.idSocieteCaracteristique]?.valeurAnswers ?? []
              ).map(valeurAnswer => ({
                idValeurAnswer: newIdValeurAnswer--,
                idPatrimoine: valeurAnswer.idPatrimoine,
                idValeur: idValeur
              }));
              if (!valeurAnswers.some(el => el.idPatrimoine === composant?.idPatrimoine)) {
                valeurAnswers.push({
                  idValeurAnswer: newIdValeurAnswer--,
                  idPatrimoine: composant?.idPatrimoine,
                  idValeur: idValeur
                });
              }
              return {
                ...valeur,
                valeurAnswers
              };
            }
            return !composant.valeursObj[societeCaracteristique.idSocieteCaracteristique]
              ? valeur
              : {
                  ...composant.valeursObj[societeCaracteristique.idSocieteCaracteristique],
                  ...valeur,
                  societeCaracteristiqueChoix:
                    composant.valeursObj[societeCaracteristique.idSocieteCaracteristique]?.idSocieteCaracteristiqueChoix
                };
          }) || []
      };
    });

    await databaseCollections.composants.bulkInsert(formattedComposants);
    const patrimoinesInvolved: number[] = [];
    await Promise.all(
      formattedComposants.map(async composant => {
        const patrimoineDocument = await this.findPatrimoineDocumentForEspace({
          idEspace: composant.idEspace,
          databaseCollections
        });
        if (patrimoineDocument) {
          patrimoinesInvolved.push(+patrimoineDocument.idPatrimoine);
        }
        return;
      })
    );

    const patrimoinesDocuments = await databaseCollections.patrimoines.find().exec();
    const patrimoines = patrimoinesDocuments
      .map(el => el.getLatest().toJSON() as unknown as Patrimoine)
      .map(el => ({ ...el, idPatrimoine: +el.idPatrimoine }));
    const patrimoinesObj = transformArrayToObject(patrimoines, { key: 'idPatrimoine' });

    const highestPatrimoines: number[] = [];
    for (let i = 0; i < patrimoinesInvolved?.length; i++) {
      const highestPatrimoine = this.composantAttenduDbService.findHighestAncestorId(
        patrimoinesObj,
        patrimoinesInvolved[i]
      );
      if (highestPatrimoine && !highestPatrimoines.includes(highestPatrimoine)) {
        highestPatrimoines.push(highestPatrimoine);
      }
    }
    const updatedComposantAttendus = [];
    for (let i = 0; i < highestPatrimoines?.length; i++) {
      for (let j = 0; j < idsSocieteComposant?.length; j++) {
        const composantAttendus = await this.composantAttenduDbService.recalculateComposantAttenduTreeForPatrimoine(
          highestPatrimoines[i],
          idsSocieteComposant[j],
          selectors,
          undefined,
          idUser
        );
        updatedComposantAttendus.push(...composantAttendus);
      }
    }
    this.synchronizeFromIndexedDb(callback, updatedComposantAttendus);
  }

  // ====================================================== //
  // ==================== MODIFICATION ==================== //
  // ====================================================== //
  public async updateComposantValeur(params: {
    valeur: Valeur;
    composant: Composant;
    patrimoine: Patrimoine;
    idUser?: number;
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    };
  }): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const composantDocument = await databaseCollections['composants']
      .findOne({ selector: { uuid: params.composant.uuid } })
      .exec();
    if (composantDocument) {
      const composantToUpdate = composantDocument.getLatest().toJSON();
      const oldValeur = params.composant?.valeurs?.find(valeur => valeur.idValeur === params.valeur.idValeur);
      const lowestIdValeurFichier = await this.getLowestIdValeurFichier(databaseCollections);
      const lowestIdValeurAnswer = await this.getLowestIdValeurAnswer(databaseCollections);

      await composantDocument.incrementalPatch({
        idUser: params.idUser,
        user: params.idUser,
        updatedAt: new Date().toISOString(),
        isSynced: false,
        valeurs: composantToUpdate.valeurs?.map(valeur =>
          valeur.idValeur !== params.valeur.idValeur
            ? valeur
            : {
                ...valeur,
                ...params.valeur,
                isSynced: false,
                idUser: params.idUser,
                valeurFichiers: !params.valeur.valeurFichiers
                  ? params.valeur.valeurFichiers
                  : valeur.valeurFichiers?.concat(
                      params.valeur.valeurFichiers?.map((valeurFichier, idx) => ({
                        ...valeurFichier,
                        idValeurFichier: valeurFichier.idValeurFichier ?? lowestIdValeurFichier.newIdValeurFichier - idx
                      })) || []
                    ),
                valeurAnswers: !params.valeur.valeurAnswers
                  ? params.valeur.valeurAnswers
                  : params.valeur?.valeurAnswers?.map((valeurAnswer, idx) => ({
                      ...valeurAnswer,
                      idValeurAnswer: valeurAnswer.idValeurAnswer ?? lowestIdValeurAnswer.newIdValeurAnswer - idx
                    })) || []
              }
        ) as ValeurDocument[]
      });
      let updatedComposantAttendus: ComposantAttenduDocument[] = [];
      if (
        (oldValeur?.societeCaracteristique?.type === CaracteristiqueTypeEnum.integer &&
          oldValeur?.societeCaracteristique?.infoAdditionnelle === InfoAdditionnelleEnum.decompte) ||
        oldValeur?.societeCaracteristique?.completion
      ) {
        updatedComposantAttendus = await this.composantAttenduDbService.recalculateComposantAttenduTreeForPatrimoine(
          params.patrimoine.idPatrimoine,
          params.composant.societeComposant?.idSocieteComposant,
          params.selectors,
          undefined,
          params.idUser
        );
      }
      this.synchronizeIdComposantFromIndexedDb(params.composant?.uuid, undefined, updatedComposantAttendus);
    }
  }

  public async massComposantModification(
    toModify: MassModificationComposant[],
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    },
    idUser?: number
  ): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const valeursObj: DynamicType<Valeur> = transformArrayToObject(toModify[0]?.modifiedValeurs, { key: 'idValeur' });
    const formattedComposantsTemp: Partial<ComposantDocument>[][] = [];
    const actions: Action[] = [];
    const composantsDocuments = await databaseCollections.composants.find().exec();
    const composants = composantsDocuments.map(el => el.getLatest().toMutableJSON());
    const composantsDict = transformArrayToObject(composants, {
      key: 'idComposant'
    });
    for (let i = 0; i < toModify?.length; i++) {
      formattedComposantsTemp.push(
        toModify[i]?.composants.map(composant => {
          if (toModify[i].idEspace && toModify[i].idEspace !== composant.idEspace) {
            actions.push(
              ComposantGeneratedActions.addEspaceSuccess({
                idComposant: composant.idComposant,
                idEspace: toModify[i].idEspace as number
              })
            );
            actions.push(
              ComposantGeneratedActions.deleteManyEspaceSuccess({
                idComposants: [composant.idComposant],
                idEspaces: [composant.idEspace]
              })
            );
            actions.push(
              EspaceGeneratedActions.addManyComposantSuccess({
                idEspace: toModify[i].idEspace as number,
                idComposants: [composant.idComposant]
              })
            );
            actions.push(
              EspaceGeneratedActions.deleteManyComposantSuccess({
                idComposants: [composant.idComposant],
                idEspaces: [composant.idEspace]
              })
            );
          }
          return {
            ...(composantsDict[composant.idComposant] ?? { idComposant: composant.idComposant, uuid: composant.uuid }),
            idEspace: toModify[i].idEspace ?? composant.idEspace,
            espace: { idEspace: toModify[i].idEspace ?? composant.idEspace },
            idUser: idUser,
            user: idUser,
            updatedAt: new Date().toISOString(),
            isSynced: false,
            valeurs: composantsDict[composant.idComposant]?.valeurs?.map((valeur: Valeur) =>
              !valeursObj[valeur.idValeur]
                ? valeur
                : {
                    ...valeursObj[valeur.idValeur],
                    isSynced: false,
                    idUser: idUser,
                    societeCaracteristique: valeur.idSocieteCaracteristique,
                    societeCaracteristiqueChoix: valeursObj[valeur.idValeur]?.idSocieteCaracteristiqueChoix
                  }
            )
          };
        })
      );
    }
    if (actions?.length) {
      this.store$.dispatch(getMultiAction(actions, '[Composant] move composants'));
    }
    const formattedComposants = formattedComposantsTemp.flat();
    await databaseCollections.composants.bulkUpsert(formattedComposants);

    const patrimoinesInvolved: number[] = [];
    const societeComposants: number[] = [];
    await Promise.all(
      formattedComposants.map(async composant => {
        const patrimoineDocument = await this.findPatrimoineDocumentForEspace({
          idEspace: composant.idEspace,
          databaseCollections
        });
        if (patrimoineDocument) {
          patrimoinesInvolved.push(+patrimoineDocument.idPatrimoine);
        }
        if (!societeComposants?.includes(composant.idSocieteComposant as number)) {
          societeComposants.push(composant.idSocieteComposant as number);
        }
        return;
      })
    );

    const patrimoinesDocuments = await databaseCollections.patrimoines.find().exec();
    const patrimoines = patrimoinesDocuments
      .map(el => el.getLatest().toJSON() as unknown as Patrimoine)
      .map(el => ({ ...el, idPatrimoine: +el.idPatrimoine }));
    const patrimoinesObj = transformArrayToObject(patrimoines, { key: 'idPatrimoine' });

    const highestPatrimoines: number[] = [];
    for (let i = 0; i < patrimoinesInvolved?.length; i++) {
      const highestPatrimoine = this.composantAttenduDbService.findHighestAncestorId(
        patrimoinesObj,
        patrimoinesInvolved[i]
      );
      if (highestPatrimoine && !highestPatrimoines.includes(highestPatrimoine)) {
        highestPatrimoines.push(highestPatrimoine);
      }
    }

    const updatedComposantAttendus = [];
    for (let i = 0; i < highestPatrimoines?.length; i++) {
      for (let j = 0; j < societeComposants?.length; j++) {
        const composantAttendus = await this.composantAttenduDbService.recalculateComposantAttenduTreeForPatrimoine(
          highestPatrimoines[i],
          societeComposants[j],
          selectors,
          undefined,
          idUser
        );
        updatedComposantAttendus.push(...composantAttendus);
      }
    }
    this.synchronizeFromIndexedDb(undefined, updatedComposantAttendus);
  }

  public async updateComposantEspace(params: {
    idComposant: number;
    newIdEspace: number;
    oldIdEspace: number;
    uuid: string;
    idUser?: number;
  }): Promise<void> {
    if (!params.idComposant || !params.newIdEspace || !params.oldIdEspace) {
      return;
    }
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const composantDocument = await databaseCollections['composants']
      .findOne({ selector: { uuid: params.uuid } })
      .exec();
    if (composantDocument) {
      await composantDocument.incrementalPatch({
        isSynced: false,
        idEspace: params.newIdEspace,
        espace: { idEspace: params.newIdEspace },
        idUser: params.idUser,
        user: params.idUser
      });
    }
    const jsonComposant = composantDocument?.getLatest().toJSON();
    const idComposant = jsonComposant?.idComposant ? +jsonComposant.idComposant : params.idComposant;

    const actions: Action[] = [];
    if (params.oldIdEspace !== params.newIdEspace) {
      actions.push(
        ComposantGeneratedActions.addEspaceSuccess({
          idComposant: idComposant,
          idEspace: params.newIdEspace
        })
      );
      actions.push(
        ComposantGeneratedActions.deleteManyEspaceSuccess({
          idComposants: [idComposant],
          idEspaces: [params.oldIdEspace]
        })
      );
      actions.push(
        EspaceGeneratedActions.addManyComposantSuccess({
          idEspace: params.newIdEspace,
          idComposants: [idComposant]
        })
      );
      actions.push(
        EspaceGeneratedActions.deleteManyComposantSuccess({
          idComposants: [idComposant],
          idEspaces: [params.oldIdEspace]
        })
      );
    }
    if (actions?.length) {
      // TODO: Check si c'est vraiment nécessaire de le faire à la main car l'update du composant renormalisera les composants dans les bons espaces
      this.store$.dispatch(getMultiAction(actions, '[Composant] update composant espace'));
    }
    this.synchronizeIdComposantFromIndexedDb(params.uuid);
  }

  // ====================================================== //
  // ====================== DEPLACER ====================== //
  // ====================================================== //
  public async moveComposant(params: {
    composant: Composant;
    newIdEspace: number;
    oldIdEspace: number;
    newPatrimoine: Patrimoine;
    oldIdPatrimoine: number;
    idUser?: number;
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    };
  }): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const composantDocument = await databaseCollections['composants']
      .findOne({ selector: { uuid: params.composant.uuid } })
      .exec();
    const jsonComposant = composantDocument?.getLatest().toJSON();
    const lowestIdValeurAnswer = await this.getLowestIdValeurAnswer(databaseCollections);
    const idComposant = jsonComposant?.idComposant ? +jsonComposant.idComposant : params.composant.idComposant;

    const actions: Action[] = [];
    if (params.oldIdEspace !== params.newIdEspace) {
      actions.push(
        ComposantGeneratedActions.addEspaceSuccess({
          idComposant,
          idEspace: params.newIdEspace
        })
      );
      actions.push(
        ComposantGeneratedActions.deleteManyEspaceSuccess({
          idComposants: [idComposant],
          idEspaces: [params.oldIdEspace]
        })
      );
      actions.push(
        EspaceGeneratedActions.addManyComposantSuccess({
          idEspace: params.newIdEspace,
          idComposants: [idComposant]
        })
      );
      actions.push(
        EspaceGeneratedActions.deleteManyComposantSuccess({
          idComposants: [idComposant],
          idEspaces: [params.oldIdEspace]
        })
      );
    }
    if (actions?.length) {
      this.store$.dispatch(getMultiAction(actions, '[Composant] move composant'));
    }

    const composantDoc: Partial<ComposantDocument> = {
      isSynced: false,
      idEspace: params.newIdEspace,
      espace: { idEspace: params.newIdEspace },
      idUser: params.idUser,
      user: params.idUser,
      updatedAt: new Date().toISOString()
    };
    const valeurFormatPatrimoine = params.composant.valeurs?.find(
      el => el.societeCaracteristique.type === CaracteristiqueTypeEnum.patrimoines
    );
    const valeurAnswerToCreate: Partial<ValeurAnswer>[] = [];
    if (valeurFormatPatrimoine && jsonComposant) {
      valeurAnswerToCreate.push({
        idValeur: valeurFormatPatrimoine.idValeur,
        idPatrimoine: params.newPatrimoine.idPatrimoine,
        idValeurAnswer: lowestIdValeurAnswer.newIdValeurAnswer
      });
      composantDoc.valeurs = jsonComposant.valeurs?.map(valeur =>
        valeur.idValeur !== valeurFormatPatrimoine.idValeur
          ? valeur
          : {
              ...valeur,
              isSynced: false,
              idUser: params.idUser,
              valeurAnswers: valeurAnswerToCreate
            }
      ) as ValeurDocument[];
    }
    if (composantDocument) {
      await composantDocument.incrementalPatch(composantDoc);
    }

    const idsPatrimoine: number[] = [];
    const oldPatrimoine = await this.findPatrimoineDocumentForEspace({ idEspace: params.oldIdEspace });
    if (oldPatrimoine) {
      idsPatrimoine.push(+oldPatrimoine.idPatrimoine);
    }
    const newPatrimoine = await this.findPatrimoineDocumentForEspace({ idEspace: params.newIdEspace });
    if (newPatrimoine && newPatrimoine.idPatrimoine !== oldPatrimoine?.idPatrimoine) {
      idsPatrimoine.push(+newPatrimoine.idPatrimoine);
    }

    const patrimoinesDocuments = await databaseCollections.patrimoines.find().exec();
    const patrimoines = patrimoinesDocuments
      .map(el => el.getLatest().toJSON() as unknown as Patrimoine)
      .map(el => ({ ...el, idPatrimoine: +el.idPatrimoine }));
    const patrimoinesObj = transformArrayToObject(patrimoines, { key: 'idPatrimoine' });

    const highestPatrimoines: number[] = [];
    for (let i = 0; i < idsPatrimoine?.length; i++) {
      const highestPatrimoine = this.composantAttenduDbService.findHighestAncestorId(patrimoinesObj, idsPatrimoine[i]);
      if (highestPatrimoine && !highestPatrimoines.includes(highestPatrimoine)) {
        highestPatrimoines.push(highestPatrimoine);
      }
    }

    const updatedComposantAttendus = [];
    for (let i = 0; i < highestPatrimoines.length; i++) {
      const composantAttendus = await this.composantAttenduDbService.recalculateComposantAttenduTreeForPatrimoine(
        highestPatrimoines[i],
        params.composant.societeComposant?.idSocieteComposant,
        params.selectors,
        undefined,
        params.idUser
      );
      updatedComposantAttendus.push(...composantAttendus);
    }

    this.synchronizeIdComposantFromIndexedDb(params.composant.uuid, undefined, updatedComposantAttendus);
  }

  // ====================================================== //
  // ====================== SUPPRIMER ===================== //
  // ====================================================== //
  public async deleteComposant(
    composant: Composant,
    patrimoine: Patrimoine,
    selectors: {
      selectAllSocieteCaracteristiques: Observable<SocieteCaracteristique[]>;
      selectAllSocietePatrimoineHierarchies: Observable<SocietePatrimoineHierarchie[]>;
      selectAllSocieteComposants: Observable<SocieteComposant[]>;
    },
    idUser?: number
  ): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const composantDocument = await databaseCollections['composants']
      .findOne({ selector: { uuid: composant?.uuid } })
      .exec();
    if (composantDocument) {
      const idComposant = +composantDocument.getLatest().toJSON().idComposant;
      if (idComposant < 0) {
        await databaseCollections['composants'].bulkRemove([composant?.uuid]);
      } else {
        await composantDocument.incrementalPatch({ isSynced: false, deleted: true });
      }

      const updatedComposantAttendus =
        await this.composantAttenduDbService.recalculateComposantAttenduTreeForPatrimoine(
          patrimoine?.idPatrimoine,
          composant?.societeComposant?.idSocieteComposant,
          selectors,
          undefined,
          idUser
        );

      this.removeIdComposantFromStore(idComposant, true);
      if (idComposant < 0) {
        if (updatedComposantAttendus?.length) {
          await this.composantAttenduDbService.updateComposantAttendus(updatedComposantAttendus);
        }
        await this.composantAttenduDbService.synchronizeFromIndexedDb();
      } else {
        this.synchronizeIdComposantFromIndexedDb(composant?.uuid, undefined, updatedComposantAttendus);
      }
    }
  }

  public async removeValeurFichier(params: {
    valeurFichier: ValeurFichier;
    valeur: Valeur;
    composant: Composant;
    idUser?: number;
  }): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const composantDocument = await databaseCollections.composants
      .findOne({ selector: { uuid: params.composant?.uuid } })
      .exec();
    if (composantDocument) {
      const composant = composantDocument.getLatest().toMutableJSON();
      const formattedComposant = {
        ...composant,
        idUser: params.idUser,
        user: params.idUser,
        updatedAt: new Date().toISOString(),
        isSynced: false,
        valeurs: composant.valeurs?.map(valeur =>
          valeur.idValeur !== params.valeur.idValeur
            ? valeur
            : {
                ...valeur,
                isSynced: false,
                valeurFichiers: valeur.valeurFichiers?.filter(
                  valeurFichier => valeurFichier.idValeurFichier !== params.valeurFichier.idValeurFichier
                )
              }
        )
      };
      await composantDocument.incrementalPatch(formattedComposant);
      this.synchronizeIdComposantFromIndexedDb(composant?.uuid);
    }
  }

  // ====================================================== //
  // ======================== UTILS ======================= //
  // ====================================================== //
  private async getLowestIdComposantAndIdValeur(
    databaseCollections?: MyDatabaseCollections
  ): Promise<{ newIdValeur: number; newIdComposant: number }> {
    if (!databaseCollections) {
      databaseCollections = await this.dbService.getDatabaseCollection();
    }
    const oldComposantsDocuments = await databaseCollections.composants.find().exec();
    const oldComposants = oldComposantsDocuments.map(el => el.getLatest().toJSON());
    const lowestIdComposant = Math.min(...oldComposants.map(el => +el.idComposant));
    const lowestIdValeur = Math.min(
      ...oldComposants
        .map(el => el.valeurs?.map(valeur => valeur.idValeur))
        .flat()
        .filter(v => !!v)
    );
    return {
      newIdComposant: lowestIdComposant < 0 ? lowestIdComposant - 1 : -1,
      newIdValeur: lowestIdValeur < 0 ? lowestIdValeur - 1 : -1
    };
  }

  // TODO: Faire une seule fonction paramétrable finale dans laquelle on peut choisir quel ids on récupère (composant, valeur, valeurAnswer, valeurFichier)
  private async getLowestIdValeurFichier(
    databaseCollections?: MyDatabaseCollections
  ): Promise<{ newIdValeurFichier: number }> {
    if (!databaseCollections) {
      databaseCollections = await this.dbService.getDatabaseCollection();
    }
    const oldComposantsDocuments = await databaseCollections.composants.find().exec();
    const oldComposants = oldComposantsDocuments.map(el => el.getLatest().toJSON());
    const lowestIdValeurFichier = Math.min(
      ...oldComposants
        .map(el =>
          el.valeurs?.map(valeur => valeur.valeurFichiers?.map(valeurFichier => valeurFichier.idValeurFichier) || [])
        )
        .flat(2)
        .filter(v => !!v)
    );
    return {
      newIdValeurFichier: lowestIdValeurFichier < 0 ? lowestIdValeurFichier - 1 : -1
    };
  }

  private async getLowestIdValeurAnswer(
    databaseCollections?: MyDatabaseCollections
  ): Promise<{ newIdValeurAnswer: number }> {
    if (!databaseCollections) {
      databaseCollections = await this.dbService.getDatabaseCollection();
    }
    const oldComposantsDocuments = await databaseCollections.composants.find().exec();
    const oldComposants = oldComposantsDocuments.map(el => el.getLatest().toJSON());
    const lowestIdValeurAnswer = Math.min(
      ...oldComposants
        .map(el =>
          el.valeurs?.map(valeur => valeur?.valeurAnswers?.map(valeurAnswer => valeurAnswer.idValeurAnswer) || [])
        )
        .flat(2)
        .filter(v => !!v)
    );
    return {
      newIdValeurAnswer: lowestIdValeurAnswer < 0 ? lowestIdValeurAnswer - 1 : -1
    };
  }

  private async findPatrimoineDocumentForEspace(params: {
    idEspace?: number;
    databaseCollections?: MyDatabaseCollections;
  }): Promise<RxDocument<PatrimoineDocument> | null> {
    if (!params.databaseCollections) {
      params.databaseCollections = await this.dbService.getDatabaseCollection();
    }
    const patrimoines = await params.databaseCollections.patrimoines.find().exec();
    return (
      patrimoines?.find(pat =>
        (pat.getLatest().toJSON() as unknown as Patrimoine)?.espaces?.some(
          espace => espace.idEspace === params.idEspace
        )
      ) || null
    );
  }

  public async handleComposantIdChange(params: InfosToMutateOfflineDbWithNewComposant): Promise<void> {
    this.removeIdComposantFromStore(params.oldId);
  }

  public removeIdComposantFromStore(idComposant: number, force = false): void {
    if (force || (!isNaN(idComposant) && idComposant < 0)) {
      combineLatest([
        this.store$.select(ComposantSelectors.selectComposantState),
        this.store$.select(ValeurSelectors.selectValeurState)
      ])
        .pipe(
          first(),
          tap(([composantState, valeurState]) => {
            // Suppression des composants & valeurs associées du store (car valeurs pouvant être recréées par le code ailleurs)
            // Donc pour éviter que les id se chevauchent on les supprime directement du store
            if (composantState.entities[idComposant]) {
              const actions = getDefaultDeleteComposantActions(
                composantState.entities[idComposant] as ComposantEntityState
              );
              const valeurs = composantState.entities[idComposant]?.valeurs;
              if (valeurs?.length) {
                actions.push(
                  ...(
                    valeurs.map(valeur =>
                      getDefaultDeleteValeurActions(valeurState.entities[valeur as number] as ValeurEntityState)
                    ) || []
                  ).flat()
                );
              }
              if (actions.length === 1) {
                this.store$.dispatch(actions[0]);
              } else if (actions.length > 1) {
                this.store$.dispatch(getMultiAction(actions, '[Composant] remove one local composant'));
              }
            }
          })
        )
        .subscribe();
    }
  }

  public async removeInexistantComposant(idComposant: number): Promise<void> {
    if (idComposant && !isNaN(idComposant) && idComposant > 0) {
      const databaseCollections = await this.dbService.getDatabaseCollection();
      const composants = await databaseCollections['composants'].find().exec();
      const composantsJson = composants.map(el => el.getLatest().toJSON());
      const composantToRemove = composantsJson.find(el => el.idComposant === idComposant);
      if (composantToRemove?.uuid) {
        await databaseCollections['composants'].bulkRemove([composantToRemove?.uuid]);
      }
    }
  }

  // ====================================================== //
  // ================== SYNCHRONIZATIONS ================== //
  // ====================================================== //
  private async synchronizeRemoveOneComposantDocument(
    composantDocument: RxDocument<ComposantDocument>,
    launchComposantAttenduSynchronisation = true,
    composantAttendus?: ComposantAttenduDocument[]
  ): Promise<void> {
    const documentJson = composantDocument.getLatest().toJSON();
    return new Promise((resolve, reject) => {
      this.store$
        .select(ComposantSelectors.selectComposantState)
        .pipe(
          first(),
          switchMap(state =>
            this.composantApiService.deleteComposantWithComposantAttendu(+documentJson.idComposant).pipe(
              map(success => ({ state, success })),
              catchError(async err => {
                if (composantAttendus?.length) {
                  await this.composantAttenduDbService.updateComposantAttendus(composantAttendus);
                }
                reject();
                return err;
              })
            )
          ),
          first(),
          tap(async res => {
            if (!res.error && res.success) {
              if (res.state?.entities[+documentJson.idComposant]) {
                this.store$.dispatch(
                  getMultiAction(
                    getDefaultDeleteComposantActions(
                      res.state.entities[+documentJson.idComposant] as ComposantEntityState
                    ),
                    ComposantActions.deleteOneComposantWithComposantAttendu.type
                  )
                );
              }
              const databaseCollections = await this.dbService.getDatabaseCollection();
              await databaseCollections['composants'].bulkRemove([documentJson.uuid]);

              const { mappedComposantAttendus, shouldResync } = compareOfflineAndBackComposantAttendus(
                composantAttendus as ComposantAttenduDocument[],
                res.success.composantAttendus
              );
              if (mappedComposantAttendus?.length) {
                await this.composantAttenduDbService.updateComposantAttendus(
                  mappedComposantAttendus as unknown as ComposantAttenduDocument[],
                  databaseCollections
                );
              }
              if (launchComposantAttenduSynchronisation && shouldResync) {
                await this.composantAttenduDbService.synchronizeFromIndexedDb();
              }
              resolve();
            }
          })
        )
        .subscribe();
    });
  }

  private async synchronizeUpdateOneComposantDocument(
    composantDocument: RxDocument<ComposantDocument>,
    launchComposantAttenduSynchronisation = true,
    composantAttendus?: ComposantAttenduDocument[]
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.composantApiService
        .updateOneComposantAndValeurs(composantDocument.getLatest().toMutableJSON() as unknown as Partial<Composant>)
        .pipe(
          first(),
          tap(async res => {
            if (res.composant) {
              const mutableComposant = composantDocument.getLatest().toMutableJSON();
              const valeursObj = mutableComposant?.valeurs?.reduce((acc, curr) => {
                acc[curr.idSocieteCaracteristique] = {
                  ...curr,
                  valeurAnswers: curr.valeurAnswers?.filter(el => el.idValeur === curr.idValeur),
                  valeurFichiers: curr.valeurFichiers?.filter(
                    el => el.idValeur === curr.idValeur && !res.fichiersErroredDict[el.idFichier]
                  )
                };
                return acc;
              }, {} as DynamicType<ValeurDocument>);
              const bidirectionalValeurs = res.composant.valeurs?.map(valeur => {
                if (valeursObj[valeur.idSocieteCaracteristique]?.isSynced === false) {
                  if (
                    areValeursEqual(valeursObj[valeur.idSocieteCaracteristique] as unknown as Valeur, valeur) ||
                    res.valeursErroredDict[valeur.idValeur]
                  ) {
                    return valeur;
                  } else {
                    return {
                      ...valeursObj[valeur.idSocieteCaracteristique],
                      idValeur: valeur.idValeur
                    };
                  }
                }
                return valeur;
              }) as unknown as ValeurDocument[];
              const isEspaceUpdated = res.isEspaceErrored || res.composant.idEspace === mutableComposant.idEspace;
              await composantDocument.incrementalPatch({
                ...res.composant,
                idEspace: isEspaceUpdated ? res.composant.idEspace : mutableComposant.idEspace,
                espace: { idEspace: isEspaceUpdated ? res.composant.idEspace : mutableComposant.idEspace },
                valeurs: bidirectionalValeurs,
                isSynced: isEspaceUpdated && !bidirectionalValeurs.some(valeur => valeur.isSynced === false)
              } as unknown as ComposantDocument);
              const { mappedComposantAttendus, shouldResync } = compareOfflineAndBackComposantAttendus(
                composantAttendus as ComposantAttenduDocument[],
                res.composantAttendus
              );
              if (mappedComposantAttendus?.length) {
                await this.composantAttenduDbService.updateComposantAttendus(
                  mappedComposantAttendus as unknown as ComposantAttenduDocument[]
                );
              }
              if (launchComposantAttenduSynchronisation && shouldResync) {
                await this.composantAttenduDbService.synchronizeFromIndexedDb();
              }
              if (res.isEspaceErrored) {
                setTimeout(() => {
                  window.alert(
                    "L'espace que vous venez de sélectionner n'existe plus sur le serveur. Nous allons recharger l'application pour mettre à jour vos données."
                  );
                  window.location.reload();
                }, 1000);
              }
              resolve();
            }
          }),
          catchError(async err => {
            if (composantAttendus?.length) {
              await this.composantAttenduDbService.updateComposantAttendus(composantAttendus);
            }
            reject();
            return of(err);
          })
        )
        .subscribe();
    });
  }

  private async synchronizeCreateOneComposantDocument(
    composantDocument: RxDocument<ComposantDocument>,
    callback?: (_params: InfosToMutateOfflineDbWithNewComposant) => void,
    launchComposantAttenduSynchronisation = true,
    composantAttendus?: ComposantAttenduDocument[]
  ): Promise<void> {
    const jsonComposant = composantDocument.getLatest().toJSON() as unknown as Composant;
    return new Promise((resolve, reject) => {
      this.composantApiService
        .createOneComposantAndValeurs(jsonComposant)
        .pipe(
          first(),
          tap(async res => {
            if (res.composant) {
              const oldIdComposant = jsonComposant.idComposant;
              const mutableComposant = composantDocument.getLatest().toMutableJSON();
              const valeursObj = mutableComposant?.valeurs?.reduce((acc, curr) => {
                acc[curr.idSocieteCaracteristique] = {
                  ...curr,
                  valeurFichiers: curr.valeurFichiers?.filter(el => el.idValeur === curr.idValeur),
                  valeurAnswers: curr.valeurAnswers?.filter(el => el.idValeur === curr.idValeur)
                };
                return acc;
              }, {} as DynamicType<ValeurDocument>);
              const bidirectionalValeurs = res.composant.valeurs?.map(valeur => {
                if (valeursObj[valeur.idSocieteCaracteristique]?.isSynced === false) {
                  if (areValeursEqual(valeursObj[valeur.idSocieteCaracteristique] as unknown as Valeur, valeur)) {
                    return valeur;
                  } else {
                    return {
                      ...valeursObj[valeur.idSocieteCaracteristique],
                      idValeur: valeur.idValeur
                    };
                  }
                }
                return valeur;
              }) as unknown as ValeurDocument[];

              const isEspaceUpdated = res.isEspaceErrored || res.composant.idEspace === mutableComposant.idEspace;
              await composantDocument.incrementalPatch({
                ...res.composant,
                idEspace: isEspaceUpdated ? res.composant.idEspace : mutableComposant.idEspace,
                espace: { idEspace: isEspaceUpdated ? res.composant.idEspace : mutableComposant.idEspace },
                valeurs: bidirectionalValeurs,
                isSynced: isEspaceUpdated && !bidirectionalValeurs.some(valeur => valeur.isSynced === false)
              } as unknown as ComposantDocument);
              const params: InfosToMutateOfflineDbWithNewComposant = {
                newId: res.composant.idComposant,
                oldId: oldIdComposant,
                idEspace: res.composant.idEspace
              };
              if (callback) {
                callback?.(params);
              } else {
                this.handleComposantIdChange(params);
              }
              const { mappedComposantAttendus, shouldResync } = compareOfflineAndBackComposantAttendus(
                composantAttendus as ComposantAttenduDocument[],
                res.composantAttendus
              );
              if (mappedComposantAttendus?.length) {
                await this.composantAttenduDbService.updateComposantAttendus(
                  mappedComposantAttendus as unknown as ComposantAttenduDocument[]
                );
              }
              if (launchComposantAttenduSynchronisation && shouldResync) {
                await this.composantAttenduDbService.synchronizeFromIndexedDb();
              }
              if (res.isEspaceErrored) {
                setTimeout(() => {
                  window.alert(
                    "L'espace sur lequel vous avez créé le composant n'existe plus sur le serveur. Nous allons recharger l'application pour mettre à jour vos données."
                  );
                  window.location.reload();
                }, 1000);
              }
              resolve();
            }
          }),
          catchError(async err => {
            if (composantAttendus?.length) {
              await this.composantAttenduDbService.updateComposantAttendus(composantAttendus);
            }
            reject();
            return of(err);
          })
        )
        .subscribe();
    });
  }

  private async synchronizeOneComposantDocument(
    composantDocument: RxDocument<ComposantDocument>,
    callback?: (_params: InfosToMutateOfflineDbWithNewComposant) => void,
    launchComposantAttenduSynchronisation = true,
    composantAttendus?: ComposantAttenduDocument[]
  ): Promise<void> {
    if (composantDocument?.isInstanceOfRxDocument) {
      const jsonComposant = composantDocument.getLatest().toJSON();
      if (!isNaN(jsonComposant.idComposant)) {
        if (jsonComposant.deleted === true) {
          await this.synchronizeRemoveOneComposantDocument(
            composantDocument,
            launchComposantAttenduSynchronisation,
            composantAttendus
          );
        } else if (jsonComposant.idComposant > 0) {
          await this.synchronizeUpdateOneComposantDocument(
            composantDocument,
            launchComposantAttenduSynchronisation,
            composantAttendus
          );
        } else if (jsonComposant.idComposant < 0) {
          await this.synchronizeCreateOneComposantDocument(
            composantDocument,
            callback,
            launchComposantAttenduSynchronisation,
            composantAttendus
          );
        }
      }
    }
  }

  public async synchronizeIdComposantFromIndexedDb(
    uuid: string,
    callback?: (_params: InfosToMutateOfflineDbWithNewComposant) => void,
    composantAttendus?: ComposantAttenduDocument[]
  ): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const composantDocument = await databaseCollections['composants'].findOne({ selector: { uuid } }).exec();
    if (composantDocument) {
      const documentJson = composantDocument.getLatest().toJSON();
      if (documentJson?.isSynced === false) {
        await this.synchronizeOneComposantDocument(composantDocument, callback, true, composantAttendus);
      }
    }
  }

  public async synchronizeFromIndexedDb(
    callback?: (_params: InfosToMutateOfflineDbWithNewComposant) => void,
    composantAttendus?: ComposantAttenduDocument[]
  ): Promise<void> {
    const databaseCollections = await this.dbService.getDatabaseCollection();
    const composantDocuments = await databaseCollections['composants'].find({ selector: { isSynced: false } }).exec();
    const notSyncedComposantDocuments = composantDocuments.filter(
      composant => composant.getLatest().toJSON().isSynced === false
    );
    await Promise.all(
      notSyncedComposantDocuments?.map(composantDocument =>
        this.synchronizeOneComposantDocument(composantDocument, callback, false, composantAttendus)
      )
    );
    await this.composantAttenduDbService.synchronizeFromIndexedDb();
  }
}
