import { createEntityAdapter } from '@ngrx/entity';
import { ValeurAnswerEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<ValeurAnswerEntityState>;

export const adapter = createEntityAdapter<ValeurAnswerEntityState>({
  selectId: o => o.idValeurAnswer
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const valeurAnswerFeatureKey = 'valeurAnswer';
