import { FamilyRightsEnum, LibelleInternRightsEnum } from '@enums';
import { User, Droit, KeysWithValuesOfType, DynamicType } from '@get/api-interfaces';

export function getUserRightsProperty(
  user: Partial<User> | null,
  property: KeysWithValuesOfType<Droit, string>
): DynamicType<boolean> {
  if (!user || !property) {
    return {};
  }
  return (
    user?.societeProfils?.reduce((acc, profil) => {
      for (let i = 0; i < profil?.droits?.length; i++) {
        if (!acc[profil.droits[i]?.[property]]) {
          acc[profil.droits[i]?.[property]] = true;
        }
      }
      return acc;
    }, {} as DynamicType<boolean>) ?? {}
  );
}

export function userHaveRight(
  right: LibelleInternRightsEnum | LibelleInternRightsEnum[],
  user: Partial<User> | null
): boolean {
  const userRightsLibelle = getUserRightsProperty(user, 'libelleIntern');
  if (!right) {
    return false;
  } else if (typeof right === 'string') {
    return !!userRightsLibelle[right];
  } else if (Array.isArray(right)) {
    for (let i = 0; i < right.length; i++) {
      if (userRightsLibelle[right[i]]) {
        return true;
      }
    }
  }
  return false;
}

export function userHaveRightInFamily(
  user: Partial<User> | null,
  family: FamilyRightsEnum | FamilyRightsEnum[]
): boolean {
  const userRightsFamily = getUserRightsProperty(user, 'famille');
  if (!family) {
    return false;
  } else if (typeof family === 'string') {
    return !!userRightsFamily[family];
  } else if (Array.isArray(family)) {
    for (let i = 0; i < family.length; i++) {
      if (userRightsFamily[family[i]]) {
        return true;
      }
    }
  }
  return false;
}
