import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurAnswerApiService } from '@get/store/api-services';
import { ValeurAnswerGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeurAnswer } from '@get/store/configs/normalization';

export class GeneratedValeurAnswerEffects {
  constructor(
    protected actions$: Actions,
    protected valeurAnswerApiService: ValeurAnswerApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyValeurAnswersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurAnswerGeneratedActions.normalizeManyValeurAnswersAfterUpsert),
      concatMap(({ valeurAnswers }) => {
        const actions: Action[] = getActionsToNormalizeValeurAnswer(valeurAnswers, StoreActionType.upsert);
        return [getMultiAction(actions, '[ValeurAnswer] Normalization After Upsert Success')];
      })
    );
  });
}
