import { Injectable } from '@angular/core';
import { StoreActionType } from '@enums';
import {
  AssertEmptyComposantAttenduAllPatrimoieInterface,
  AssertEmptyComposantAttenduIdsPatrimoineInterface,
  AssertEmptyComposantAttenduInterface,
  Composant,
  ComposantAttendu,
  ComposantEntityState,
  ValeurFichierEntityState
} from '@get/api-interfaces';
import {
  ComposantActions,
  ComposantAttenduGeneratedActions,
  ComposantGeneratedActions,
  EspaceGeneratedActions,
  ValeurFichierGeneratedActions,
  ValeurGeneratedActions
} from '@get/store/actions';
import { ComposantApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { getActionsToNormalizeComposant } from '@get/store/configs/normalization';
import { AppState } from '@get/store/configs/reducers';
import { ComposantSelectors, ValeurFichierSelectors } from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, concatMap, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  GeneratedComposantEffects,
  getDefaultAddComposantActions,
  getDefaultDeleteComposantActions
} from './composant-generated.effects';
import { getDefaultDeleteValeurFichierActions } from './valeur-fichier-generated.effects';

@Injectable()
export class ComposantEffects extends GeneratedComposantEffects {
  constructor(actions$: Actions, composantApiService: ComposantApiService, store$: Store<AppState>) {
    super(actions$, composantApiService, store$);
  }

  duplicateOneComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.duplicateOneComposant),
      concatMap(({ idComposant, ids, params }) => {
        return this.composantApiService.duplicateComposant(idComposant, params).pipe(
          mergeMap((composantReturned: Composant) => getDefaultAddComposantActions(composantReturned, ids)),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        );
      })
    );
  });

  getManyComposantsPerType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.getManyComposantsPerType),
      switchMap(({ params }) =>
        this.composantApiService.getComposantsPerType(params).pipe(
          map((composants: Composant[]) => {
            return ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants });
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        )
      )
    );
  });

  upsertOneComposantModifyEspace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.upsertOneComposantModifyEspace),
      concatMap(({ composant, oldIdEspace }: { composant: Partial<Composant>; oldIdEspace: number }) => {
        return this.composantApiService.updateComposant(composant).pipe(
          switchMap((composantReturned: Composant) => {
            const actions: Action[] = getActionsToNormalizeComposant([composantReturned], StoreActionType.upsert);
            if (oldIdEspace !== composantReturned.idEspace) {
              actions.push(
                ComposantGeneratedActions.addEspaceSuccess({
                  idComposant: composantReturned.idComposant,
                  idEspace: composantReturned.idEspace
                })
              );
              actions.push(
                EspaceGeneratedActions.addManyComposantSuccess({
                  idEspace: composantReturned.idEspace,
                  idComposants: [composantReturned.idComposant]
                })
              );
              actions.push(
                EspaceGeneratedActions.deleteManyComposantSuccess({
                  idComposants: [composantReturned.idComposant],
                  idEspaces: [oldIdEspace]
                })
              );
            }
            return actions;
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        );
      })
    );
  });

  upsertOneValeurReturnComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.upsertOneValeurAndReturnComposant),
      concatMap(({ valeur, params }) => {
        return this.composantApiService.updateValeurReturnComposant(valeur, params).pipe(
          mergeMap(
            ({ composant, composantAttendus }: { composant: Composant; composantAttendus: ComposantAttendu[] }) => {
              return [
                ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants: [composant] }),
                ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
                  composantAttendus: composantAttendus
                })
              ];
            }
          ),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        );
      })
    );
  });

  updateOneComposantAndValeurs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.updateOneComposantAndValeurs),
      concatMap(({ composant }) => {
        return this.composantApiService.updateOneComposantAndValeurs(composant).pipe(
          map(res => {
            return ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants: [res.composant] });
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        );
      })
    );
  });

  assertEmptyComposantAttendu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.assertEmptyComposantAttendu),
      concatMap(({ params }: { params: AssertEmptyComposantAttenduInterface }) => {
        return this.composantApiService.assertEmptyComposantAttendu(params).pipe(
          map((composantAttenduReturned: ComposantAttendu[]) => {
            return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
              composantAttendus: composantAttenduReturned
            });
          }),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        );
      })
    );
  });

  assertEmptyComposantAttenduAllPatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.assertEmptyComposantAttenduAllPatrimoines),
      concatMap(({ params }: { params: AssertEmptyComposantAttenduAllPatrimoieInterface }) => {
        return this.composantApiService.assertEmptyComposantAttenduAllPatrimoines(params).pipe(
          map((composantAttenduReturned: ComposantAttendu[]) => {
            return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
              composantAttendus: composantAttenduReturned
            });
          }),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        );
      })
    );
  });

  assertEmptyTree$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.assertEmptyTree),
      concatMap(({ params }: { params: AssertEmptyComposantAttenduInterface }) => {
        return this.composantApiService.assertEmptyTree(params).pipe(
          map((composantAttenduReturned: ComposantAttendu[]) => {
            return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
              composantAttendus: composantAttenduReturned
            });
          }),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        );
      })
    );
  });

  assertEmptyTreeIdsPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantActions.assertEmptyTreeIdsPatrimoine),
      concatMap(({ params }: { params: AssertEmptyComposantAttenduIdsPatrimoineInterface }) => {
        return this.composantApiService.assertEmptyTreeIdsPatrimoine(params).pipe(
          map((composantAttenduReturned: ComposantAttendu[]) => {
            return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
              composantAttendus: composantAttenduReturned
            });
          }),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        );
      })
    );
  });

  deleteOneComposantWithComposantAttendu$ = createEffect(() => {
    const selectComposantState$ = this.store$.select(ComposantSelectors.selectComposantState);
    return this.actions$.pipe(
      ofType(ComposantActions.deleteOneComposantWithComposantAttendu),
      withLatestFrom(selectComposantState$),
      concatMap(([{ idComposant }, state]) =>
        this.composantApiService.deleteComposantWithComposantAttendu(idComposant).pipe(
          mergeMap((success: { affectedRows: number; composantAttendus: ComposantAttendu[] }) => {
            return [
              getMultiAction(
                getDefaultDeleteComposantActions(state.entities[idComposant] as ComposantEntityState),
                ComposantActions.deleteOneComposantWithComposantAttendu.type
              ),
              ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
                composantAttendus: success.composantAttendus
              })
            ];
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        )
      )
    );
  });

  deleteComposantsBySociete$ = createEffect(() => {
    const selectComposantState$ = this.store$.select(ComposantSelectors.selectComposantState);
    return this.actions$.pipe(
      ofType(ComposantActions.deleteComposantsBySociete),
      withLatestFrom(selectComposantState$),
      concatMap(([{ idSociete }, state]) =>
        this.composantApiService.deleteComposantsBySociete(idSociete).pipe(
          tap(),
          mergeMap(_success => [
            getMultiAction(
              Object.keys(state.entities)
                .map(id => getDefaultDeleteComposantActions(state.entities[id] as ComposantEntityState))
                .flat(),
              ComposantGeneratedActions.deleteOneComposant.type
            ),
            ComposantActions.deleteComposantsBySocieteSuccess()
          ]),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        )
      )
    );
  });

  deleteComposantsBySocieteComposant$ = createEffect(() => {
    const selectComposantState$ = this.store$.select(ComposantSelectors.selectComposantState);
    return this.actions$.pipe(
      ofType(ComposantActions.deleteComposantsBySocieteComposant),
      withLatestFrom(selectComposantState$),
      concatMap(([{ idSocieteComposant }, state]) =>
        this.composantApiService.deleteComposantsBySocieteComposant(idSocieteComposant).pipe(
          mergeMap(_success => [
            // TODO: Filtrer les composants à supprimer par idSocieteComposant (puisque là on supprime tous les composants du store)
            getMultiAction(
              Object.keys(state.entities)
                .map(id => getDefaultDeleteComposantActions(state.entities[id] as ComposantEntityState))
                .flat(),
              ComposantGeneratedActions.deleteOneComposant.type
            ),
            ComposantActions.deleteComposantsBySocieteComposantSuccess()
          ]),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        )
      )
    );
  });

  deleteOneValeurFichier$ = createEffect(() => {
    const selectValeurFichierState$ = this.store$.select(ValeurFichierSelectors.selectValeurFichierState);
    return this.actions$.pipe(
      ofType(ComposantActions.deleteOneValeurFichier),
      withLatestFrom(selectValeurFichierState$),
      concatMap(([{ idValeurFichier }, state]) =>
        this.composantApiService.deleteValeurFichier(idValeurFichier).pipe(
          mergeMap(_success =>
            getDefaultDeleteValeurFichierActions(state.entities[idValeurFichier] as ValeurFichierEntityState)
          ),
          catchError(error => of(ValeurFichierGeneratedActions.valeurFichiersFailure({ error })))
        )
      )
    );
  });
}
