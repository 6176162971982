import { ValeurAnswerState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { valeurAnswerReducersGeneratedFunctions } from './valeur-answer-generated.reducer';

const valeurAnswerReducersFunctions = [...valeurAnswerReducersGeneratedFunctions];

const valeurAnswerReducer = createReducer(ValeurAnswerState.initialState, ...valeurAnswerReducersFunctions);

export function reducer(state: ValeurAnswerState.IState | undefined, action: Action) {
  return valeurAnswerReducer(state, action);
}
