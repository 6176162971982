import {
  Composant,
  ComposantAttenduEntityState,
  DynamicType,
  Patrimoine,
  PatrimoineAncetre,
  PatrimoineWithChildren,
  PatrimoineWithChildrenAndTitles
} from '@get/api-interfaces';
import { PatrimoineDocument } from '@get/interfaces';
import { cleanLowercasedString, getDirectAncestor, transformArrayToObject, transformArrayToTreeObj } from '@utils';

export function getPatrimoineTitleWithPrefix(patrimoine: Patrimoine, separator = ' '): string {
  const prefix = cleanLowercasedString(patrimoine.libelle)?.includes(
    cleanLowercasedString(patrimoine.societePatrimoineHierarchie?.libelle)
  );
  return (
    (!prefix ? (patrimoine.societePatrimoineHierarchie?.libelle ?? '') + separator : '') + (patrimoine.libelle ?? '')
  );
}

export function mapPatrimoinesWithUsefulInfos(
  patrimoines: (PatrimoineDocument | Patrimoine)[],
  idPatrimoine?: number,
  keysToOmit: (keyof Patrimoine)[] = ['valeurs', 'valeurPatrimoines']
) {
  return patrimoines.map(patrimoine =>
    +patrimoine.idPatrimoine === idPatrimoine
      ? patrimoine
      : {
          ...patrimoine,
          ...(keysToOmit || []).reduce((acc, curr) => {
            acc[curr as string] = undefined;
            return acc;
          }, {} as DynamicType<undefined>)
        }
  );
}

export function mapPatrimoinesForConsultDetails(
  patrimoines: (PatrimoineDocument | Patrimoine)[],
  idPatrimoine?: number
): Patrimoine[] {
  return patrimoines
    .filter(patrimoine => +patrimoine.idPatrimoine === idPatrimoine)
    .map(patrimoine => ({
      ...patrimoine,
      descendants: undefined,
      espaces: undefined
    })) as Partial<Patrimoine>[] as Patrimoine[];
}

function fillToKeepUpwards(
  patrimoinesObj: DynamicType<Patrimoine & { idParent: number; toKeep: boolean }>,
  idPatrimoine: number
): void {
  patrimoinesObj[idPatrimoine].toKeep = true;
  if (
    patrimoinesObj[idPatrimoine].idParent &&
    patrimoinesObj[patrimoinesObj[idPatrimoine].idParent] &&
    !patrimoinesObj[patrimoinesObj[idPatrimoine].idParent].toKeep
  ) {
    fillToKeepUpwards(patrimoinesObj, patrimoinesObj[idPatrimoine].idParent);
  }
}

export function mapPatrimoinesForConsultComponents(
  patrimoines: (PatrimoineDocument | Patrimoine)[],
  idPatrimoine: number,
  composantAttendus: ComposantAttenduEntityState[],
  composants: Composant[]
): Patrimoine[] {
  const patrimoinesObj = patrimoines.reduce((acc, curr) => {
    acc[+curr.idPatrimoine] = {
      ...(curr as Patrimoine),
      idParent: getDirectAncestor((curr as Patrimoine).ancetres)?.idAncetrePatrimoine as number,
      toKeep: +curr.idPatrimoine === idPatrimoine
    };
    return acc;
  }, {} as DynamicType<Patrimoine & { idParent: number; toKeep: boolean }>);
  const composantAttendusObj: DynamicType<ComposantAttenduEntityState> = transformArrayToObject(composantAttendus, {
    key: 'idComposantAttendu'
  });

  const espacesHavingComposantObj = composants.reduce((acc, curr) => {
    acc[curr.espace?.idEspace || curr.idEspace] = true;
    return acc;
  }, {} as DynamicType<boolean>);

  for (let i = 0; i < patrimoines?.length; i++) {
    if (
      patrimoinesObj[+patrimoines[i].idPatrimoine] &&
      !patrimoinesObj[+patrimoines[i].idPatrimoine].toKeep &&
      ((patrimoines[i] as Patrimoine).composantAttendus?.some(ca => composantAttendusObj[ca.idComposantAttendu]) ||
        (patrimoines[i] as Patrimoine).espaces?.some(espace => espacesHavingComposantObj[espace.idEspace]))
    ) {
      fillToKeepUpwards(patrimoinesObj, +patrimoines[i].idPatrimoine);
    }
  }

  return patrimoines
    .filter(patrimoine => patrimoinesObj[+patrimoine.idPatrimoine].toKeep)
    .map(patrimoine => ({
      ...patrimoine,
      composantAttendus: (patrimoine as Patrimoine).composantAttendus?.filter(
        ca => composantAttendusObj[ca.idComposantAttendu]
      ),
      descendants: undefined,
      valeurs: undefined,
      valeurPatrimoines: undefined,
      progInterventionPatrimoines: undefined,
      progInterventions: undefined,
      choices: undefined,
      vals: undefined
    })) as Partial<Patrimoine>[] as Patrimoine[];
}

function remapTreeToPatrimoines(
  patrimoines: PatrimoineWithChildren[],
  idSocietePatrimoineHierarchie?: number
): PatrimoineWithChildren[] {
  if (patrimoines?.some(el => el.idSocietePatrimoineHierarchie === idSocietePatrimoineHierarchie)) {
    return patrimoines;
  }
  if (patrimoines?.length) {
    return patrimoines
      .map(patrimoine =>
        [patrimoine].concat(remapTreeToPatrimoines(patrimoine.children, idSocietePatrimoineHierarchie))
      )
      .flat();
  }
  return [];
}

export function mapPatrimoinesForConsultHierarchy(
  patrimoines: (PatrimoineDocument | Patrimoine)[],
  idPatrimoine: number,
  idSocietePatrimoineHierarchie?: number
): Patrimoine[] {
  const patrimoinesWithIdParent = patrimoines.map(el => ({
    ...el,
    idParent: getDirectAncestor((el as Patrimoine).ancetres)?.idAncetrePatrimoine
  }));
  const patrimoinesTreeObj: DynamicType<PatrimoineWithChildren> = transformArrayToTreeObj(patrimoinesWithIdParent, {
    key: 'idPatrimoine',
    parentKey: 'idParent',
    childrenKey: 'children'
  });
  if (!patrimoinesTreeObj[idPatrimoine]) {
    return [];
  }
  const remappedPatrimoines = remapTreeToPatrimoines([patrimoinesTreeObj[idPatrimoine]], idSocietePatrimoineHierarchie);
  return remappedPatrimoines.map(patrimoine => ({
    ...patrimoine,
    children: undefined,
    idParent: undefined,
    espaces: undefined,
    descendants: undefined,
    composantAttendus: undefined,
    valeurs: undefined,
    valeurPatrimoines: undefined,
    txCouverture: undefined,
    txCompletion: undefined,
    idSociete: undefined,
    progInterventionPatrimoines: undefined,
    progInterventions: undefined,
    choices: undefined,
    vals: undefined,
    ancetres: patrimoine.ancetres?.map(el => ({
      ...el,
      ancetrePatrimoine: undefined
    })) as Partial<PatrimoineAncetre>[]
  })) as Partial<Patrimoine>[] as Patrimoine[];
}

export function isPatrimoineChildOfGivenIdPatrimoine(
  idPatrimoineToCheck: number,
  patrimoineObj: DynamicType<Patrimoine & { idParent: number }>,
  idPatrimoineParent: number
): boolean {
  return (
    !!idPatrimoineToCheck &&
    (idPatrimoineToCheck === idPatrimoineParent ||
      isPatrimoineChildOfGivenIdPatrimoine(
        patrimoineObj[idPatrimoineToCheck]?.idParent,
        patrimoineObj,
        idPatrimoineParent
      ))
  );
}

export function findHighestAncestorId(patrimoineObj: DynamicType<Patrimoine>, idPatrimoine: number): number | null {
  const patrimoine = patrimoineObj[idPatrimoine];
  if (patrimoine) {
    const directAncestor = getDirectAncestor(patrimoine.ancetres);
    if (directAncestor) {
      return findHighestAncestorId(patrimoineObj, directAncestor.idAncetrePatrimoine);
    }
    return patrimoine.idPatrimoine;
  }
  return null;
}

export function gatherPatrimoineWithChildrenArray(patrimoine: PatrimoineWithChildren): PatrimoineWithChildren[] {
  return [patrimoine].concat(patrimoine.children?.map(el => gatherPatrimoineWithChildrenArray(el))?.flat() ?? []);
}

export function filterPatrimoinesByResearch(
  patrimoines: PatrimoineWithChildrenAndTitles[],
  search: string
): PatrimoineWithChildrenAndTitles[] {
  return (
    patrimoines?.reduce<PatrimoineWithChildrenAndTitles[]>((acc, patrimoine) => {
      const filteredChildren = filterPatrimoinesByResearch(patrimoine?.children || [], search);
      if (
        patrimoine?.reference?.toLocaleLowerCase().includes(search) ||
        patrimoine?.libelle?.toLocaleLowerCase().includes(search) ||
        patrimoine?.adresse?.toLocaleLowerCase().includes(search)
      ) {
        acc.push({ ...patrimoine, children: filteredChildren?.length ? filteredChildren : undefined });
      } else if (filteredChildren?.length > 0) {
        acc.push(...filteredChildren);
      }
      return acc;
    }, []) || []
  );
}
