import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ValeurAnswerGeneratedActions } from '@get/store/actions';
import { ValeurAnswerState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ValeurAnswerEntityState } from '@get/api-interfaces';

export const valeurAnswerReducersGeneratedFunctions: ReducerTypes<ValeurAnswerState.IState, readonly ActionCreator[]>[] = [
  on(ValeurAnswerGeneratedActions.upsertOneValeurAnswer, (state: ValeurAnswerState.IState) => setLoadingsState(state, true)),

  on(ValeurAnswerGeneratedActions.upsertManyValeurAnswersSuccess, (state: ValeurAnswerState.IState, { valeurAnswers }) =>
    ValeurAnswerState.adapter.upsertMany(valeurAnswers, setLoadingsState(state, false))
  ),
  on(ValeurAnswerGeneratedActions.deleteOneValeurAnswerSuccess, (state: ValeurAnswerState.IState, { idValeurAnswer }) =>
    ValeurAnswerState.adapter.removeOne(idValeurAnswer, setLoadingsState(state, false))
  ),

  on(ValeurAnswerGeneratedActions.clearValeurAnswers, () => ValeurAnswerState.initialState),

  on(
    ValeurAnswerGeneratedActions.addValeurSuccess,
    (state: ValeurAnswerState.IState, { idValeurAnswer, idValeur }) => {
      if (!state.entities[idValeurAnswer]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeurAnswer]: {
            ...state.entities[idValeurAnswer],
            valeur: idValeur
          }
        }
      };
    }
  ),

  on(
    ValeurAnswerGeneratedActions.deleteManyValeurSuccess,
    (state: ValeurAnswerState.IState, { idValeurs, idValeurAnswers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurAnswers.reduce((entities, idValeurAnswer) => {
            if (!state.entities[idValeurAnswer]?.valeur) {
              return entities;
            }
            entities[idValeurAnswer] = {
              ...state.entities[idValeurAnswer],
              valeur: idValeurs.some(
                (idValeur: number) => idValeur === state.entities[idValeurAnswer]?.valeur
              )
                ? undefined
                : state.entities[idValeurAnswer]?.valeur
            } as ValeurAnswerEntityState;
            return entities;
          }, {} as Dictionary<ValeurAnswerEntityState>)
        }
      };
    }
  ),

  on(
    ValeurAnswerGeneratedActions.addPatrimoineSuccess,
    (state: ValeurAnswerState.IState, { idValeurAnswer, idPatrimoine }) => {
      if (!state.entities[idValeurAnswer]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeurAnswer]: {
            ...state.entities[idValeurAnswer],
            patrimoine: idPatrimoine
          }
        }
      };
    }
  ),

  on(
    ValeurAnswerGeneratedActions.deleteManyPatrimoineSuccess,
    (state: ValeurAnswerState.IState, { idPatrimoines, idValeurAnswers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurAnswers.reduce((entities, idValeurAnswer) => {
            if (!state.entities[idValeurAnswer]?.patrimoine) {
              return entities;
            }
            entities[idValeurAnswer] = {
              ...state.entities[idValeurAnswer],
              patrimoine: idPatrimoines.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idValeurAnswer]?.patrimoine
              )
                ? undefined
                : state.entities[idValeurAnswer]?.patrimoine
            } as ValeurAnswerEntityState;
            return entities;
          }, {} as Dictionary<ValeurAnswerEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ValeurAnswerState.IState,
  isLoading: boolean,
  isLoaded = true
): ValeurAnswerState.IState {
  return { ...state, isLoaded, isLoading };
}
