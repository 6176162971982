import { Injectable } from '@angular/core';
import { ImportTypeFileExampleEnum } from '@enums';
import { Fichier } from '@get/api-interfaces';
import { FichierGeneratedActions } from '@get/store/actions';
import { FichierApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { FichierRelationsIds } from '@get/store/ids-interfaces';
import { FichierSelectors } from '@get/store/selectors';
import { catchApiActions, SelectSchema } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { GeneratedFichierService } from './fichier-generated.service';

@Injectable({
  providedIn: 'root'
})
export class FichierService extends GeneratedFichierService {
  constructor(private fichiersApiService: FichierApiService, store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public selectAllIconFichiers(schema: SelectSchema = {}): Observable<Fichier[]> {
    return this.store$.pipe(select(FichierSelectors.selectAllIconFichiers(schema))).pipe(
      switchMap(({ fichiers }: { fichiers: Fichier[] }) => {
        return this.getReady(schema).pipe(map(() => fichiers));
      })
    );
  }

  public importTemplate(
    idOrganisation: number,
    idSociete: number,
    dataTypeToImport: ImportTypeFileExampleEnum[],
    withAllDatas: boolean,
    callback: () => void
  ): void {
    let fileName = 'GET_' + idSociete + '_';
    if (dataTypeToImport.length === 1 && dataTypeToImport[0] !== ImportTypeFileExampleEnum.allTypes) {
      fileName = fileName + 'Import_' + dataTypeToImport[0];
    } else {
      fileName = fileName + 'Imports';
    }
    fileName = fileName + '_' + dayjs().format('YYYY-MM-DD_HH[h]mm') + '.xlsx';
    this.fichiersApiService
      .importTemplate(idOrganisation, idSociete, dataTypeToImport, withAllDatas)
      .pipe(
        tap(blob => {
          saveAs(blob, fileName);
          callback();
        }),
        catchError(err => {
          callback();
          return of(err);
        })
      )
      .subscribe();
  }

  public uploadGeneratedFile(idOrganisation: number, idSociete: number, pathFile: string): void {
    const fileName = pathFile.split('/');
    this.fichiersApiService
      .uploadGeneratedFile(idOrganisation, idSociete, pathFile)
      .pipe(tap(blob => saveAs(blob, fileName[fileName.length - 1])))
      .subscribe();
  }

  public upsertManyFichiers(
    fichiers: Partial<Fichier>[],
    ids: FichierRelationsIds = {},
    getResult = false
  ): void | Observable<Fichier[]> {
    this.store$.dispatch(FichierGeneratedActions.upsertManyFichiers({ fichiers, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FichierGeneratedActions.normalizeManyFichiersAfterUpsert,
        FichierGeneratedActions.fichiersFailure,
        true
      );
    }
  }
}
