import {
  CaracteristiqueObjectPerSocieteComposant,
  GridError,
  SocieteCaracteristique,
  SocieteCaracteristiqueDataRow,
  SocieteCaracteristiqueEntry,
  ValidationErrorInterface
} from '@get/api-interfaces';
import { sortByMultipleKey } from '../arrays.utils';
import { compareStrings } from '../string.utils';

export function validateSocieteCaracteristiqueProperties(
  societeCaracToCreate: Partial<SocieteCaracteristique>
): ValidationErrorInterface<SocieteCaracteristique>[] {
  const errors: ValidationErrorInterface<SocieteCaracteristique>[] = [];
  if (
    (!societeCaracToCreate?.idSocieteComposant &&
      !societeCaracToCreate?.idSocietePatrimoineHierarchie &&
      !societeCaracToCreate?.intervention) ||
    !societeCaracToCreate?.type ||
    !societeCaracToCreate?.libelleTechnique
  ) {
    errors.push({
      properties: ['idSocieteComposant', 'idSocietePatrimoineHierarchie', 'intervention', 'type', 'libelleTechnique'],
      message:
        'You need an idSocieteComposant or an idSocietePatrimoineHierarchie or intervention set to true, a type and a libelleTechnique to do this action'
    });
  }
  const keysSet: { key: keyof SocieteCaracteristique; sentence: string }[] = [];
  if (societeCaracToCreate?.idSocieteComposant) {
    keysSet.push({ key: 'idSocieteComposant', sentence: 'an idSocieteComposant' });
    if (typeof societeCaracToCreate?.idSocieteComposant !== 'number') {
      console.error('// ~~~~ ERROR CREATING CARAC COMPOSANT ~~~ //', societeCaracToCreate);
      errors.push({
        properties: ['idSocieteComposant'],
        message: 'idSocieteComposant must be a number'
      });
    }
  }
  if (societeCaracToCreate?.idSocietePatrimoineHierarchie) {
    keysSet.push({ key: 'idSocietePatrimoineHierarchie', sentence: 'an idSocietePatrimoineHierarchie' });
    if (typeof societeCaracToCreate?.idSocietePatrimoineHierarchie !== 'number') {
      console.error('// ~~~ ERROR CREATING CARAC HIERARCHIE ~~~ //', societeCaracToCreate);
      errors.push({
        properties: ['idSocietePatrimoineHierarchie'],
        message: 'idSocietePatrimoineHierarchie must be a number'
      });
    }
  }
  if (societeCaracToCreate?.intervention) {
    keysSet.push({ key: 'intervention', sentence: 'intervention' });
  }
  if (keysSet?.length > 1) {
    errors.push({
      properties: keysSet.map(el => el.key),
      message: `You can t specify ${keysSet.map(el => el.sentence).join(' and ')} to do this action`
    });
  }
  return errors;
}

export function mergeSocieteCaracteristiqueAndGridErrors(
  societeCaracteristiques: SocieteCaracteristique[],
  gridErrors: GridError[]
): SocieteCaracteristiqueDataRow[] {
  const societeCaracteristiqueDataRows: SocieteCaracteristiqueDataRow[] = [
    ...((societeCaracteristiques as SocieteCaracteristiqueDataRow[]) || [])
  ];
  for (let i = 0; i < gridErrors?.length; i++) {
    if (gridErrors[i]?.error?.idSocieteCaracteristique > 0) {
      const index = societeCaracteristiqueDataRows.findIndex(
        carac => carac?.idSocieteCaracteristique === gridErrors[i].error.idSocieteCaracteristique
      );

      if (index >= 0) {
        societeCaracteristiqueDataRows[index] = { ...gridErrors[i].error };
      }
    } else {
      societeCaracteristiqueDataRows.push({ ...gridErrors[i]?.error });
    }
  }

  return societeCaracteristiqueDataRows;
}

export function transformCaracteristiquesToObjectPerComponent(
  societeCaracteristiques: SocieteCaracteristique[]
): CaracteristiqueObjectPerSocieteComposant {
  return (
    societeCaracteristiques?.reduce((acc, curr) => {
      acc[curr?.societeComposant?.idSocieteComposant] = acc[curr?.societeComposant?.idSocieteComposant] || {
        libelle: curr?.societeComposant?.libelle?.trimEnd(),
        nb: 0
      };
      acc[curr?.societeComposant?.idSocieteComposant].nb++;
      return acc;
    }, {} as CaracteristiqueObjectPerSocieteComposant) || {}
  );
}

export function sortSocieteCaracteristiques(values: SocieteCaracteristique[]): SocieteCaracteristique[] {
  return values?.sort(sortByMultipleKey([{ name: 'titreGroupement' }, { name: 'ligne' }, { name: 'ordre' }]));
}

export function getSortedSocieteCaracteristiquesEntries(
  societeCaracteristiques: SocieteCaracteristique[]
): SocieteCaracteristiqueEntry[] {
  return Object.entries(
    societeCaracteristiques?.reduce((acc, societeCaracteristique) => {
      acc[societeCaracteristique?.titreGroupement] = {
        ...(acc[societeCaracteristique?.titreGroupement] || {}),
        [societeCaracteristique?.ligne]: (
          acc[societeCaracteristique?.titreGroupement]?.[societeCaracteristique?.ligne] || []
        ).concat(societeCaracteristique)
      };
      return acc;
    }, {} as { [grp: string]: { [ligne: number]: SocieteCaracteristique[] } }) || {}
  )
    .sort((a, b) => (a[0] === 'null' ? -1 : b[0] === 'null' ? 1 : compareStrings(a[0], b[0])))
    .map((entry, index) => ({
      grp: entry[0],
      index,
      societeCaracteristiques: Object.values(entry[1])
        .map(societeCaracs => societeCaracs.sort((a, b) => a?.ordre - b?.ordre))
        .sort((a, b) => a[0]?.ligne - b[0]?.ligne)
    }));
}
