import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AssertEmptyComposantAttenduInterface,
  Campagne,
  CampagneUser,
  CampaignInfos,
  CompletionBySocieteComposantByPatrimoineCampaignResult,
  CampaignMonitoringResult,
  CampaignMonitoringSynthesisParams,
  Composant,
  ComposantAttendu,
  ComposantWithIdPatrimoine,
  DuplicateAndMoveInterface,
  Fichier,
  Patrimoine,
  RefetchAvancement,
  Valeur
} from '@get/api-interfaces';
import { environment } from '@get/environment';
import { RepositoryService } from '@get/services/repository';
import { EMPTY, Observable, catchError, first, firstValueFrom, map } from 'rxjs';
import { GeneratedCampagneApiService } from './campagne-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CampagneApiService extends GeneratedCampagneApiService {
  constructor(repo: RepositoryService, private readonly http: HttpClient) {
    super(repo);
  }

  public getCampagnePatrimoines(params: { idCampagne: number; params?: any }): Observable<Campagne> {
    return this.repo.getData<Campagne>('campagne/patrimoines/' + params.idCampagne, params.params);
  }

  public getCompletionByPatrimoineInCampagne(
    idCampagne: number
  ): Observable<CompletionBySocieteComposantByPatrimoineCampaignResult[]> {
    return this.repo.getData<CompletionBySocieteComposantByPatrimoineCampaignResult[]>(
      'campagne/completion/' + idCampagne
    );
  }

  public downloadOneFichier(idFichier: number): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + `/campagne/fichier/download/${idFichier}`, {
        responseType: 'blob'
      })
      .pipe(first());
  }

  public sendResponsibleMail(params: {
    emails: string[];
    campaignInfos: CampaignInfos;
    launch: boolean;
  }): Observable<CampagneUser[]> {
    return this.repo.create<CampagneUser[]>(
      'campagne/send-responsible-mail/' + params.campaignInfos.idCampagne,
      params
    );
  }

  public refetchAvancement(ids: RefetchAvancement): Observable<Patrimoine[]> {
    return this.repo.create<Patrimoine[]>('campagne/refetch-avancement', ids);
  }

  public createOneComposant(
    composant: Partial<ComposantWithIdPatrimoine>
  ): Observable<Composant & { isEspaceErrored?: boolean }> {
    return this.repo.create<Composant>('campagne/composant', composant);
  }

  public createManyComposants(
    composants: Partial<Composant>[]
  ): Observable<(Composant & { isEspaceErrored?: boolean })[]> {
    return this.repo.create<(Composant & { isEspaceErrored?: boolean })[]>('campagne/composant/many', composants);
  }

  public updateOneComposant(composant: Partial<Composant>): Observable<Composant & { isEspaceErrored?: boolean }> {
    return this.repo.update('campagne/composant/update', composant);
  }

  public updateManyComposant(
    composants: Partial<Composant>[]
  ): Observable<(Composant & { isEspaceErrored?: boolean })[]> {
    return this.repo.update('campagne/composant/update-many', composants);
  }

  public duplicateComposant(idComposant: number, params?: { idCampagne?: number }): Observable<Composant> {
    return this.repo.create<Composant>('campagne/composant/duplicate/' + idComposant, null, params);
  }

  public duplicateAndMoveComposant(
    body: DuplicateAndMoveInterface[],
    params?: { idCampagne?: number }
  ): Observable<(Composant & { isEspaceErrored?: boolean })[]> {
    return this.repo.create<(Composant & { isEspaceErrored?: boolean })[]>(
      'campagne/composant/duplicate-and-move/' + body[0].idComposant,
      body,
      params
    );
  }

  public updateValeurReturnComposant(
    valeur: Partial<Valeur>,
    params?: { idCampagne?: number }
  ): Observable<{ composant: Composant; composantAttendus: ComposantAttendu[] }> {
    return this.repo.update('campagne/valeur/return-composant', valeur, params);
  }

  public updateValeursReturnComposants(
    valeurs: Partial<Valeur>[],
    params?: { idCampagne?: number }
  ): Observable<{ composants: Composant[]; composantAttendus: ComposantAttendu[] }> {
    return this.repo.update('campagne/valeur/many-return-composants', valeurs, params);
  }

  public moveComposant(
    composant: Partial<Composant & { isEspaceErrored?: boolean }>
  ): Observable<{ composant: Composant; composantAttendus: ComposantAttendu[] }> {
    return this.repo.update('campagne/composant/move', composant);
  }

  public updateValeur(valeur: Partial<Valeur>): Observable<Valeur> {
    return this.repo.update('campagne/valeur', valeur);
  }

  public assertEmptyComposantAttendu(params: AssertEmptyComposantAttenduInterface): Observable<any> {
    return this.repo.update('campagne/composant-attendu/assert-empty', params);
  }

  public assertEmptyTree(params: AssertEmptyComposantAttenduInterface): Observable<any> {
    return this.repo.update('campagne/composant-attendu/assert-empty-tree', params);
  }

  public uploadManyImages(
    files: Array<File>,
    queryParams: { idOrganisation?: number } = {}
  ): Observable<Array<Fichier>> {
    const formData = new FormData();
    const route = `${new URL(environment.apiUrl + '/campagne/composant/images').href}${
      queryParams?.idOrganisation ? '?idOrganisation=' + queryParams.idOrganisation : ''
    }`;
    files.forEach(media => {
      formData.append('files', media, encodeURIComponent(media.name));
    });

    return this.http.post(route, formData).pipe(
      first(),
      map((res: any) => res.data),
      catchError(() => EMPTY)
    );
  }

  public uploadMany(files: Array<File>, queryParams: { idOrganisation?: number } = {}): Observable<Array<Fichier>> {
    const formData = new FormData();
    const route = `${new URL(environment.apiUrl + '/campagne/composant/files').href}${
      queryParams?.idOrganisation ? '?idOrganisation=' + queryParams.idOrganisation : ''
    }`;
    files.forEach(media => {
      formData.append('files', media, encodeURIComponent(media.name));
    });

    return this.http.post(route, formData).pipe(
      first(),
      map((res: any) => res.data),
      catchError(() => EMPTY)
    );
  }

  public fetchMonitoringSynthesis(params: CampaignMonitoringSynthesisParams): Promise<CampaignMonitoringResult> {
    return firstValueFrom(this.repo.create('campagne/monitoring/synthesis', params));
  }

  public deleteComposantWithComposantAttendu(
    idComposant: number
  ): Observable<{ affectedRows: number; composantAttendus: ComposantAttendu[] }> {
    return this.repo.delete('campagne/composant/with-composant-attendu/' + +idComposant);
  }

  public deleteValeurFichier(idValeur: number): Observable<number> {
    return this.repo.delete('campagne/file/' + +idValeur);
  }
}
