import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedValeurAnswerApiService } from './valeur-answer-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ValeurAnswerApiService extends GeneratedValeurAnswerApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
