import { ComposantAttenduReponseEnum } from '@enums';
import { ComposantAttendu } from '@get/api-interfaces';

export function calculateCouverture(composantAttendus: ComposantAttendu[]): number {
  const vals = composantAttendus.reduce(
    (acc, composantAttendu) => {
      acc.num += composantAttendu.nbReponsesRempli;
      acc.denom += composantAttendu.nbReponsesAttendu;
      return acc;
    },
    { num: 0, denom: 0 }
  );
  return vals.denom === 0 ? 0 : vals.num / vals.denom;
}

export function getClassForCouverturePercentage(couverture?: number): string {
  return couverture === undefined || isNaN(couverture)
    ? 'progress-grey'
    : couverture < 40
    ? 'progress-red'
    : couverture >= 40 && couverture < 60
    ? 'progress-orange'
    : couverture >= 60 && couverture < 80
    ? 'progress-yellow'
    : couverture >= 80 && couverture < 90
    ? 'progress-green-2'
    : 'progress-green-1';
}

export function getClassForCouverturePercentageAndComposantAttendu(
  composantAttendu: ComposantAttendu,
  couverture?: number
): string {
  return composantAttendu.reponse === ComposantAttenduReponseEnum.non
    ? 'progress-red'
    : getClassForCouverturePercentage(couverture);
}

export function getProgressText(couverture?: number, precision?: number): string {
  const pow = Math.pow(10, precision ?? 0);
  return couverture === undefined || isNaN(couverture)
    ? ''
    : couverture === 0
    ? 'À collecter'
    : precision && couverture
    ? Math.round(parseFloat(couverture.toFixed(precision)) * pow) / pow + '%'
    : couverture + '%';
}
