import { DynamicType, KeysWithValuesOfType } from '@get/api-interfaces';
import { resolveSubObjectKey } from './objects.utils';
import { cleanLowercasedString, compareStrings } from './string.utils';

export function create1ToNArray(n: number): Array<number> {
  return Array.from(Array(n), (_, i) => i + 1);
}

export function filterUniqueArray<T = number | string>(v: T[]): Array<T> {
  return v && Array.isArray(v) ? v.filter((el, idx, acc) => acc.indexOf(el) === idx) : [];
}

// https://stackoverflow.com/a/47424938
export function sortByMultipleKey(
  keys: { name: string; cb?: (a: any, b: any, key?: any) => 1 | 0 | -1 }[]
): (a: any, b: any) => 1 | 0 | -1 {
  return (a, b): 1 | 0 | -1 => {
    if (!keys?.length || !keys[0]) {
      return 0; // force to equal if keys run out
    }
    let result: 1 | 0 | -1;

    const key = keys[0]; // take out the first key
    if (!key.cb) {
      result = defaultStringSort(a?.[key.name], b?.[key.name]);
    } else {
      result = key.cb(a, b, key);
    }
    return result === 0 ? sortByMultipleKey(keys.slice(1))(a, b) : result;
  };
}

function defaultStringSort(a: string, b: string): 1 | 0 | -1 {
  const res = compareStrings(a?.toString().toLowerCase(), b?.toString().toLowerCase());
  if (res < 0) {
    return -1;
  } else if (res > 0) {
    return 1;
  }
  return 0;
}

export function arraysAreEqual<T>(a: T, b: T): boolean {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

export function arrayContainsOnlyEmptyOrNullObjects(arr: Object[]): boolean {
  return arr?.every(elem => !elem || Object.values(elem)?.every(value => !value));
}

export function getElementFromSmallestKeyValue<T>(elements: T[], key: keyof T): T | null {
  return elements && Array.isArray(elements)
    ? elements.reduce((acc, element) => (!acc || element[key] < acc[key] ? element : acc), null as T | null)
    : null;
}

export function groupArrayPerSubObjectKeyValue(arr: any[], key: string): any[] {
  const obj = arr?.reduce((acc, value) => {
    const idx = resolveSubObjectKey(value, key);
    acc[idx] = (acc[idx] || []).concat(value);
    return acc;
  }, {} as DynamicType<any[]>);
  return Object.entries(obj);
}

export function findElementRecursively(
  elements: any[],
  identifier: number,
  identifierKey: string,
  childrenKey: string
): any | null {
  for (let i = 0; i < elements?.length; i++) {
    if (elements[i][identifierKey] === identifier) {
      return elements[i];
    }
    const found = findElementRecursively(elements[i][childrenKey], identifier, identifierKey, childrenKey);
    if (found) {
      return found;
    }
  }
  return null;
}

export function fillFlatArray<T>(elements: T[], finalArray: T[], childrenKey: keyof T): void {
  for (let i = 0; i < elements?.length; i++) {
    const element = elements[i];
    finalArray.push(element);
    if ((element[childrenKey] as unknown as T[])?.length) {
      fillFlatArray(element[childrenKey] as unknown as T[], finalArray, childrenKey);
    }
  }
}

export function transformArrayToChunks<T>(arr: T[], chunkSize = 50000): T[][] {
  const result: T[][] = [];
  let i = 0;
  while (i < arr?.length) {
    result.push(arr.slice(i, i + chunkSize));
    i += chunkSize;
  }
  return result;
}

export function areNumberArraysEqual(nbsA: number[], nbsB: number[]) {
  return nbsA?.length === nbsB?.length && nbsA?.every(nb => nbsB?.includes(nb));
}

export function filterArrayBySearchField<T>(
  searchValue: string,
  arrayToFilter: T[],
  options?: { filterLabel?: KeysWithValuesOfType<T, string>; multiple?: boolean }
): T[] {
  if (!searchValue || searchValue === '') {
    return arrayToFilter;
  }
  const defaultOptions = {
    multiple: false
  };
  const { multiple, filterLabel } = { ...defaultOptions, ...options };

  const lowerCaseSearchValue = cleanLowercasedString(searchValue);
  let searchValueArray: string[] = [];
  if (multiple) {
    searchValueArray = lowerCaseSearchValue.split(',').map(val => val.trim());
  } else {
    searchValueArray.push(lowerCaseSearchValue);
  }

  const filteredArrayDict: DynamicType<boolean> = {};

  return arrayToFilter.filter(possibility => {
    const lowerCasePossibility =
      filterLabel && typeof possibility?.[filterLabel] === 'string'
        ? cleanLowercasedString(possibility?.[filterLabel] as string)
        : typeof possibility === 'string'
        ? cleanLowercasedString(possibility)
        : '';
    for (let searchValuesIndex = 0; searchValuesIndex < searchValueArray.length; searchValuesIndex++) {
      if (
        lowerCasePossibility.includes(searchValueArray[searchValuesIndex]) &&
        !filteredArrayDict[lowerCasePossibility]
      ) {
        filteredArrayDict[lowerCasePossibility] = true;
        return true;
      }
    }
    return false;
  });
}
