import { Injectable } from '@angular/core';
import { Newsletter, UserNewsletter } from '@get/api-interfaces';
import { NewsletterActions, NewsletterGeneratedActions, UserNewsletterGeneratedActions } from '@get/store/actions';
import { NewsletterApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedNewsletterService } from './newsletter-generated.service';
@Injectable({
  providedIn: 'root'
})
export class NewsletterService extends GeneratedNewsletterService {
  constructor(store$: Store<AppState>, actions$: Actions, private readonly newsletterApiService: NewsletterApiService) {
    super(store$, actions$);
  }

  public getNewslettersByOrganisation(params: any = {}): Promise<Newsletter[]> {
    return this.newsletterApiService.getNewslettersByOrganisation(params);
  }

  public getOneNewsletter(params: { idNewsletter: number; idOrganisation: number }): Promise<Newsletter | null> {
    return this.newsletterApiService.getOneNewsletter(params);
  }

  public getNewslettersUnread(params: any = {}, getResult?: boolean): void | Observable<Newsletter[]> {
    this.store$.dispatch(NewsletterActions.getNewslettersUnread({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert,
        NewsletterGeneratedActions.newslettersFailure
      );
    }
  }

  public markAsRead(idsNewsletter: number[], getResult?: boolean): void | Observable<UserNewsletter[]> {
    this.store$.dispatch(NewsletterActions.markAsRead({ idsNewsletter }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserNewsletterGeneratedActions.normalizeManyUserNewslettersAfterUpsert,
        NewsletterGeneratedActions.newslettersFailure,
        true
      );
    }
  }
}
