export function fileNameWithoutExtension(fileName: string): string {
  return fileName?.substring(0, fileName?.lastIndexOf('.')) || '';
}

export function isFileImageFromFilename(filename: string): boolean {
  return !!filename?.match(/(.jpg|.jpeg|.png|.gif)/i);
}

export function isFileDocumentFromFilename(filename: string): boolean {
  return !!filename?.match(/(.doc|.docx|.xls|.xlsx|.ppt|.pptx|.pdf|.msg)/i) || isFileImageFromFilename(filename);
}
