import { CaracteristiqueTypeEnum, ProgInterventionStatutEnum } from '@enums';
import {
  DynamicType,
  MinimalValeur,
  Patrimoine,
  PatrimoineForProgrammation,
  ProgCaracteristiqueValues,
  ProgComposant,
  ProgIntervention,
  ProgPatrimoineInfo,
  ProgrammationMultiYearDataRow,
  SocieteCaracteristiqueWithChoicesObj,
  SocieteTerritoire,
  SocieteTerritoirePatrimoine
} from '@get/api-interfaces';
import { formatDateFullYear } from './date.utils';

export function calculateInterventionBasePrice(intervention: ProgIntervention): number {
  const quantite = intervention?.quantiteSaisie ?? intervention?.quantiteCalcul ?? 0;
  const puTtc = intervention?.puTtcSaisie ?? intervention?.puTtcCalcul ?? 0;
  return quantite * puTtc;
}

export function calculateInterventionTotalPrice(intervention: ProgIntervention): number {
  const prixTravauxTtc = intervention?.prixTravauxTtcSaisie ?? intervention?.prixTravauxTtcCalcul ?? 0;
  const diagForfait = intervention?.diagForfaitSaisie ?? intervention?.diagForfaitCalcul ?? 0;
  const diagTaux = prixTravauxTtc * ((intervention?.diagTauxSaisie ?? intervention?.diagTauxCalcul ?? 0) / 100);
  const honorairesForfait = intervention?.honorairesForfaitSaisie ?? intervention?.honorairesForfaitCalcul ?? 0;
  const honorairesTaux =
    prixTravauxTtc * ((intervention?.honorairesTauxSaisie ?? intervention?.honorairesTauxCalcul ?? 0) / 100);
  return prixTravauxTtc + diagTaux + honorairesTaux + diagForfait + honorairesForfait;
}

export function isProgrammationRowEditable(row: ProgrammationMultiYearDataRow): boolean {
  return row?.progIntervention?.statut === ProgInterventionStatutEnum.programmation;
}

export function formatMinimalValeurs(
  minimalValeurs: MinimalValeur[],
  societeCaracteristiquesObj: DynamicType<SocieteCaracteristiqueWithChoicesObj>
) {
  return (
    minimalValeurs
      ?.map(el => {
        const societeCaracteristique = societeCaracteristiquesObj?.[el?.idCarac];
        const formattedValue =
          societeCaracteristique?.type === CaracteristiqueTypeEnum.choix
            ? societeCaracteristique.societeCaracteristiqueChoicesObj?.[el.value]?.libelle ?? null
            : societeCaracteristique?.type === CaracteristiqueTypeEnum.date
            ? formatDateFullYear(el.value)
            : !!el?.value || el?.value === false || el?.value === 0
            ? societeCaracteristique?.type === CaracteristiqueTypeEnum.booleen
              ? el.value
                ? 'Oui'
                : 'Non'
              : el.value
            : null;
        return {
          ...(el ?? {}),
          societeCaracteristique,
          libelle: societeCaracteristique?.libelle ?? '',
          formattedValue
        };
      })
      ?.filter(el => !!el.formattedValue || el.formattedValue === false || el.formattedValue === 0) ?? []
  );
}

export function formatTerritoiresProgrammation(
  societeTerritoirePatrimoines: SocieteTerritoirePatrimoine[],
  societeTerritoiresObj: DynamicType<SocieteTerritoire>
): string[] {
  const territoirePatrimoines =
    societeTerritoirePatrimoines?.map(el => societeTerritoiresObj?.[el?.idSocieteTerritoire]) || [];
  const territoiresHavingChildren = territoirePatrimoines.reduce((acc, curr) => {
    if (curr?.idTerritoireParent) {
      acc[curr.idTerritoireParent] = true;
    }
    return acc;
  }, {} as DynamicType<boolean>);
  return territoirePatrimoines
    .filter(el => !territoiresHavingChildren[el?.idSocieteTerritoire] && el?.libelle)
    .map(el => el.libelle);
}

export function formatPatrimoineTextProgrammation(patrimoine: Patrimoine): string {
  return [patrimoine?.reference, patrimoine?.libelle, patrimoine?.commune, patrimoine?.codePostal]
    .filter(el => !!el)
    .join(' - ');
}

export function formatProgPatrimoineInfoObject(
  patrimoineInfo: ProgPatrimoineInfo,
  progComposantsObj: DynamicType<ProgComposant>
): ProgrammationMultiYearDataRow {
  if (!progComposantsObj?.[patrimoineInfo.idProgComposant]) {
    return {} as ProgrammationMultiYearDataRow;
  }
  return {
    couleur: progComposantsObj[patrimoineInfo.idProgComposant].progComposantSousGroupe?.progComposantGroupe?.couleur,
    ordreComposantGroupe:
      progComposantsObj[patrimoineInfo.idProgComposant].progComposantSousGroupe?.progComposantGroupe?.ordre,
    ordreComposantSousGroupe: progComposantsObj[patrimoineInfo.idProgComposant].progComposantSousGroupe?.ordre,
    ordreComposant: progComposantsObj[patrimoineInfo.idProgComposant].ordre,
    progComposantGroupe:
      progComposantsObj[patrimoineInfo.idProgComposant].progComposantSousGroupe?.progComposantGroupe?.libelle,
    progComposantSousGroupe: progComposantsObj[patrimoineInfo.idProgComposant].progComposantSousGroupe?.libelle,
    progComposant: progComposantsObj[patrimoineInfo.idProgComposant].libelle,
    idProgComposant: patrimoineInfo.idProgComposant
  } as ProgrammationMultiYearDataRow;
}

export function formatPrograCaracteristiques(
  progComposantsObj: DynamicType<ProgComposant>,
  societeCaracteristiquesObj: DynamicType<SocieteCaracteristiqueWithChoicesObj>,
  patrimoine: PatrimoineForProgrammation,
  idProgComposant: number
): ProgCaracteristiqueValues[] {
  const caracteristiques = progComposantsObj?.[idProgComposant as number]?.societeComposant?.societeCaracteristiques;
  return !caracteristiques?.length
    ? []
    : caracteristiques
        .map(carac => {
          if (!carac) {
            return [];
          }
          const value = patrimoine?.vals?.[carac.idSocieteCaracteristique];
          if (value) {
            return [
              {
                libelleCarac: carac.libelle,
                prefix: carac.prefixe,
                suffix: carac.suffixe,
                vals: [
                  carac.type === CaracteristiqueTypeEnum.choix
                    ? societeCaracteristiquesObj?.[carac.idSocieteCaracteristique]?.societeCaracteristiqueChoicesObj?.[
                        value as number
                      ]?.libelle ?? null
                    : carac.type === CaracteristiqueTypeEnum.date
                    ? formatDateFullYear(value as Date)
                    : value
                ]
              }
            ];
          } else if (patrimoine?.choices?.[carac.idSocieteCaracteristique]) {
            return Object.values(
              Object.keys(patrimoine.choices[carac.idSocieteCaracteristique]).reduce((acc, curr) => {
                if (!acc[carac.idSocieteCaracteristique]) {
                  acc[carac.idSocieteCaracteristique] = {
                    libelleCarac: carac.libelle,
                    prefix: carac.prefixe,
                    suffix: carac.suffixe,
                    vals: []
                  };
                }
                acc[carac.idSocieteCaracteristique].vals.push(
                  societeCaracteristiquesObj?.[carac.idSocieteCaracteristique]?.societeCaracteristiqueChoicesObj?.[curr]
                    ?.libelle ?? 'Néant'
                );
                return acc;
              }, {} as DynamicType<ProgCaracteristiqueValues>)
            );
          }
          return [];
        })
        .flat()
        .filter(val => !!val);
}

export function getSynthesisRowHeight(data: ProgrammationMultiYearDataRow): number | undefined {
  return !data?.synthesisRow
    ? undefined
    : Math.max(
        (data.synthesisInfos?.territoires?.length ? 1 : 0) +
          (data.synthesisInfos?.minimalValeurEntries?.map(entry => entry.valeurs)?.flat()?.length ?? 0) +
          (data.synthesisInfos?.descendantsInfos?.length ? 1 : 0),
        2
      ) *
        20 +
        4;
}

export function getObjWithLibelleFromSelectedValue<T extends { libelle: string }>(
  elem: ProgIntervention | undefined,
  keySaisie: keyof ProgIntervention,
  keyCalcul: keyof ProgIntervention,
  obj: DynamicType<T>
): T | null {
  if (elem?.[keySaisie] && obj?.[elem[keySaisie] as number]?.libelle) {
    return obj[elem[keySaisie] as number];
  }
  if (elem?.[keyCalcul] && obj?.[elem[keyCalcul] as number]?.libelle) {
    return obj[elem[keyCalcul] as number];
  }
  return null;
}
