/* eslint-disable max-lines */
import { StoreActionType } from '@enums';
import { Action } from '@ngrx/store';
import { BoardStateGeneratedActions } from '@get/store/actions';
import { BoardStateEntityState } from '@get/api-interfaces';
import { CampagneGeneratedActions } from '@get/store/actions';
import { CampagneEntityState } from '@get/api-interfaces';
import { CampagnePatrimoineGeneratedActions } from '@get/store/actions';
import { CampagnePatrimoineEntityState } from '@get/api-interfaces';
import { CampagneSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { CampagneSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { CampagneUserGeneratedActions } from '@get/store/actions';
import { CampagneUserEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { ComposantGeneratedActions } from '@get/store/actions';
import { ComposantEntityState } from '@get/api-interfaces';
import { ComposantAttenduGeneratedActions } from '@get/store/actions';
import { ComposantAttenduEntityState } from '@get/api-interfaces';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { ComposantGroupeEntityState } from '@get/api-interfaces';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { ComposantTemplateEntityState } from '@get/api-interfaces';
import { DroitGeneratedActions } from '@get/store/actions';
import { DroitEntityState } from '@get/api-interfaces';
import { EspaceGeneratedActions } from '@get/store/actions';
import { EspaceEntityState } from '@get/api-interfaces';
import { FichierGeneratedActions } from '@get/store/actions';
import { FichierEntityState } from '@get/api-interfaces';
import { NewsletterGeneratedActions } from '@get/store/actions';
import { NewsletterEntityState } from '@get/api-interfaces';
import { OrganisationAzureLoginGeneratedActions } from '@get/store/actions';
import { OrganisationAzureLoginEntityState } from '@get/api-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { OrganisationEntityState } from '@get/api-interfaces';
import { OrganisationNewsletterGeneratedActions } from '@get/store/actions';
import { OrganisationNewsletterEntityState } from '@get/api-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { PatrimoineEntityState } from '@get/api-interfaces';
import { PatrimoineAncetreGeneratedActions } from '@get/store/actions';
import { PatrimoineAncetreEntityState } from '@get/api-interfaces';
import { PatrimoineHierarchieTemplateGeneratedActions } from '@get/store/actions';
import { PatrimoineHierarchieTemplateEntityState } from '@get/api-interfaces';
import { ProgBiblioInterventionGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgBiblioInterventionScenarioGeneratedActions } from '@get/store/actions';
import { ProgBiblioInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgBudgetGeneratedActions } from '@get/store/actions';
import { ProgBudgetEntityState } from '@get/api-interfaces';
import { ProgComposantGeneratedActions } from '@get/store/actions';
import { ProgComposantEntityState } from '@get/api-interfaces';
import { ProgComposantGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantGroupeEntityState } from '@get/api-interfaces';
import { ProgComposantSousGroupeGeneratedActions } from '@get/store/actions';
import { ProgComposantSousGroupeEntityState } from '@get/api-interfaces';
import { ProgEtatTechniqueGeneratedActions } from '@get/store/actions';
import { ProgEtatTechniqueEntityState } from '@get/api-interfaces';
import { ProgInterventionGeneratedActions } from '@get/store/actions';
import { ProgInterventionEntityState } from '@get/api-interfaces';
import { ProgInterventionFamilleGeneratedActions } from '@get/store/actions';
import { ProgInterventionFamilleEntityState } from '@get/api-interfaces';
import { ProgInterventionPatrimoineGeneratedActions } from '@get/store/actions';
import { ProgInterventionPatrimoineEntityState } from '@get/api-interfaces';
import { ProgInterventionScenarioGeneratedActions } from '@get/store/actions';
import { ProgInterventionScenarioEntityState } from '@get/api-interfaces';
import { ProgPatrimoineInfoGeneratedActions } from '@get/store/actions';
import { ProgPatrimoineInfoEntityState } from '@get/api-interfaces';
import { ProgScenarioGeneratedActions } from '@get/store/actions';
import { ProgScenarioEntityState } from '@get/api-interfaces';
import { ProgUniteGeneratedActions } from '@get/store/actions';
import { ProgUniteEntityState } from '@get/api-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { SocieteEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposantEntityState } from '@get/api-interfaces';
import { SocieteComposantFamilleGeneratedActions } from '@get/store/actions';
import { SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { SocieteComposantRattachementGeneratedActions } from '@get/store/actions';
import { SocieteComposantRattachementEntityState } from '@get/api-interfaces';
import { SocieteEspaceGeneratedActions } from '@get/store/actions';
import { SocieteEspaceEntityState } from '@get/api-interfaces';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieAncetreGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieAncetreEntityState } from '@get/api-interfaces';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { SocieteProfilEntityState } from '@get/api-interfaces';
import { SocieteProfilDroitGeneratedActions } from '@get/store/actions';
import { SocieteProfilDroitEntityState } from '@get/api-interfaces';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireEntityState } from '@get/api-interfaces';
import { SocieteTerritoirePatrimoineGeneratedActions } from '@get/store/actions';
import { SocieteTerritoirePatrimoineEntityState } from '@get/api-interfaces';
import { SocieteTerritoireUserGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireUserEntityState } from '@get/api-interfaces';
import { UsageGeneratedActions } from '@get/store/actions';
import { UsageEntityState } from '@get/api-interfaces';
import { UsageComposantTemplateGeneratedActions } from '@get/store/actions';
import { UsageComposantTemplateEntityState } from '@get/api-interfaces';
import { UsageCaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { UsageCaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { UserEntityState } from '@get/api-interfaces';
import { UserNewsletterGeneratedActions } from '@get/store/actions';
import { UserNewsletterEntityState } from '@get/api-interfaces';
import { UserPatrimoineGeneratedActions } from '@get/store/actions';
import { UserPatrimoineEntityState } from '@get/api-interfaces';
import { UserSocieteProfilGeneratedActions } from '@get/store/actions';
import { UserSocieteProfilEntityState } from '@get/api-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { ValeurEntityState } from '@get/api-interfaces';
import { ValeurFichierGeneratedActions } from '@get/store/actions';
import { ValeurFichierEntityState } from '@get/api-interfaces';
import { ValeurAnswerGeneratedActions } from '@get/store/actions';
import { ValeurAnswerEntityState } from '@get/api-interfaces';
import { ValeurComposantGeneratedActions } from '@get/store/actions';
import { ValeurComposantEntityState } from '@get/api-interfaces';
import { ValeurPatrimoineGeneratedActions } from '@get/store/actions';
import { ValeurPatrimoineEntityState } from '@get/api-interfaces';
import { ValeurProgInterventionGeneratedActions } from '@get/store/actions';
import { ValeurProgInterventionEntityState } from '@get/api-interfaces';

type KeysWithValsOfType<T, V> = keyof { [P in keyof T as T[P] extends V ? P : never]: P };

function normalizeMultipleElements<T, U>(
  element: T,
  toNormalize: U[],
  relations: { [key: string]: number | number[] },
  childrenKey: KeysWithValsOfType<T, undefined | U[] | number[]>,
  childParentKey: KeysWithValsOfType<U, undefined | T | number>,
  primaryKey: KeysWithValsOfType<T, number>,
  childPrimaryKey: KeysWithValsOfType<U, number>
): void {
  if (
    (element[childrenKey] as undefined | U[] | number[])?.length &&
    typeof (element[childrenKey] as U[] | number[])[0] !== 'number'
  ) {
    const ids: number[] = [];
    for (let i = 0; i < (element[childrenKey] as U[] | number[]).length; i++) {
      const el: U | number = (element[childrenKey] as U[] | number[])[i];
      if (typeof el !== 'number') {
        toNormalize.push({ ...el, [childParentKey]: element[primaryKey] });
        ids.push(el[childPrimaryKey] as number);
      } else {
        ids.push(el);
      }
    }
    relations[childrenKey as string] = ids;
  }
}

function normalizeMultipleElementsDict<T, U>(
  element: T,
  toNormalize: { [id: number]: U},
  relations: { [key: string]: number | number[] },
  childrenKey: KeysWithValsOfType<T, undefined | U[] | number[]>,
  childParentKey: KeysWithValsOfType<U, undefined | T[] | number[]>,
  primaryKey: KeysWithValsOfType<T, number>,
  childPrimaryKey: KeysWithValsOfType<U, number>
): void {
  if (
    (element[childrenKey] as undefined | U[] | number[])?.length &&
    typeof (element[childrenKey] as U[] | number[])[0] !== 'number'
  ) {
    const ids: number[] = [];
    for (let i = 0; i < (element[childrenKey] as U[] | number[]).length; i++) {
      const el: U | number = (element[childrenKey] as U[] | number[])[i];
      if (typeof el !== 'number') {
        if (!toNormalize[el[childPrimaryKey] as number]) {
          toNormalize[el[childPrimaryKey] as number] = {
            ...el,
            [childParentKey]: (el[childParentKey] as T[])?.concat() ?? []
          };
        }
        (toNormalize[el[childPrimaryKey] as number][childParentKey] as number[]).push(element[primaryKey] as number);
        ids.push(el[childPrimaryKey] as number);
      } else {
        ids.push(el);
      }
    }
    relations[childrenKey as string] = ids;
  }
}

function normalizeSingleElement<T, U>(
  element: T,
  toNormalize: { [id: number]: U },
  relations: { [key: string]: number | number[] },
  parentKey: KeysWithValsOfType<T, undefined | U | number>,
  parentChildrenKey: KeysWithValsOfType<U, undefined | T[] | number[]>,
  primaryKey: KeysWithValsOfType<T, number>,
  parentPrimaryKey: KeysWithValsOfType<U, number>
): void {
  if (element[parentKey] && typeof element[parentKey] !== 'number') {
    if (!toNormalize[element[parentKey][parentPrimaryKey] as number]) {
      toNormalize[element[parentKey][parentPrimaryKey] as number] = {
        ...(element[parentKey] as U),
        [parentChildrenKey]: (element[parentKey][parentChildrenKey] as undefined | T[] | number[])?.concat() ?? []
      };
    }
    (toNormalize[element[parentKey][parentPrimaryKey] as number][parentChildrenKey] as number[]).push(
      element[primaryKey] as number
    );
    relations[parentKey] = element[parentKey][parentPrimaryKey] as number;
  }
}

/**
 *  BoardState
 */
export function getActionsToNormalizeBoardState(
  boardStates: BoardStateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedBoardStates = boardStates.map((boardState: BoardStateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      boardState,
      usersToNormalize,
      relations,
      'user',
      'boardStates',
      'idBoardState',
      'idUser'
    );

    return {
      ...boardState,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const boardStateActionCreator = BoardStateGeneratedActions.upsertManyBoardStatesSuccess;
  actions.push(boardStateActionCreator({ boardStates: normalizedBoardStates }));

  return actions;
}

/**
 *  Campagne
 */
export function getActionsToNormalizeCampagne(
  campagnes: CampagneEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const campagneUsersToNormalize: CampagneUserEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};
  const campagnePatrimoinesToNormalize: CampagnePatrimoineEntityState[] = [];
  const campagneSocieteCaracteristiquesToNormalize: CampagneSocieteCaracteristiqueEntityState[] = [];

  const normalizedCampagnes = campagnes.map((campagne: CampagneEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElementsDict(
      campagne,
      patrimoinesToNormalize,
      relations,
      'patrimoines',
      'campagnes',
      'idCampagne',
      'idPatrimoine'
    );
    normalizeMultipleElements(
      campagne,
      campagneUsersToNormalize,
      relations,
      'campagneUsers',
      'campagne',
      'idCampagne',
      'idCampagneUser'
    );
    normalizeMultipleElementsDict(
      campagne,
      usersToNormalize,
      relations,
      'users',
      'campagnes',
      'idCampagne',
      'idUser'
    );
    normalizeMultipleElementsDict(
      campagne,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristiques',
      'campagnes',
      'idCampagne',
      'idSocieteCaracteristique'
    );
    normalizeMultipleElements(
      campagne,
      campagnePatrimoinesToNormalize,
      relations,
      'campagnePatrimoines',
      'campagne',
      'idCampagne',
      'idCampagnePatrimoine'
    );
    normalizeMultipleElements(
      campagne,
      campagneSocieteCaracteristiquesToNormalize,
      relations,
      'campagneSocieteCaracteristiques',
      'campagne',
      'idCampagne',
      'idCampagneSocieteCaracteristique'
    );
    normalizeSingleElement(
      campagne,
      societesToNormalize,
      relations,
      'societe',
      'campagnes',
      'idCampagne',
      'idSociete'
    );

    return {
      ...campagne,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  if (campagneUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagneUser(campagneUsersToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }
  if (campagnePatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagnePatrimoine(campagnePatrimoinesToNormalize, actionType));
  }
  if (campagneSocieteCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagneSocieteCaracteristique(campagneSocieteCaracteristiquesToNormalize, actionType));
  }

  const campagneActionCreator = CampagneGeneratedActions.upsertManyCampagnesSuccess;
  actions.push(campagneActionCreator({ campagnes: normalizedCampagnes }));

  return actions;
}

/**
 *  CampagnePatrimoine
 */
export function getActionsToNormalizeCampagnePatrimoine(
  campagnePatrimoines: CampagnePatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedCampagnePatrimoines = campagnePatrimoines.map((campagnePatrimoine: CampagnePatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      campagnePatrimoine,
      campagnesToNormalize,
      relations,
      'campagne',
      'campagnePatrimoines',
      'idCampagnePatrimoine',
      'idCampagne'
    );
    normalizeSingleElement(
      campagnePatrimoine,
      patrimoinesToNormalize,
      relations,
      'patrimoine',
      'campagnePatrimoines',
      'idCampagnePatrimoine',
      'idPatrimoine'
    );

    return {
      ...campagnePatrimoine,
      ...relations
    };
  });
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const campagnePatrimoineActionCreator = CampagnePatrimoineGeneratedActions.upsertManyCampagnePatrimoinesSuccess;
  actions.push(campagnePatrimoineActionCreator({ campagnePatrimoines: normalizedCampagnePatrimoines }));

  return actions;
}

/**
 *  CampagneSocieteCaracteristique
 */
export function getActionsToNormalizeCampagneSocieteCaracteristique(
  campagneSocieteCaracteristiques: CampagneSocieteCaracteristiqueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};

  const normalizedCampagneSocieteCaracteristiques = campagneSocieteCaracteristiques.map((campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      campagneSocieteCaracteristique,
      campagnesToNormalize,
      relations,
      'campagne',
      'campagneSocieteCaracteristiques',
      'idCampagneSocieteCaracteristique',
      'idCampagne'
    );
    normalizeSingleElement(
      campagneSocieteCaracteristique,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristique',
      'campagneSocieteCaracteristiques',
      'idCampagneSocieteCaracteristique',
      'idSocieteCaracteristique'
    );

    return {
      ...campagneSocieteCaracteristique,
      ...relations
    };
  });
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }

  const campagneSocieteCaracteristiqueActionCreator = CampagneSocieteCaracteristiqueGeneratedActions.upsertManyCampagneSocieteCaracteristiquesSuccess;
  actions.push(campagneSocieteCaracteristiqueActionCreator({ campagneSocieteCaracteristiques: normalizedCampagneSocieteCaracteristiques }));

  return actions;
}

/**
 *  CampagneUser
 */
export function getActionsToNormalizeCampagneUser(
  campagneUsers: CampagneUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};

  const normalizedCampagneUsers = campagneUsers.map((campagneUser: CampagneUserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      campagneUser,
      usersToNormalize,
      relations,
      'user',
      'campagneUsers',
      'idCampagneUser',
      'idUser'
    );
    normalizeSingleElement(
      campagneUser,
      campagnesToNormalize,
      relations,
      'campagne',
      'campagneUsers',
      'idCampagneUser',
      'idCampagne'
    );

    return {
      ...campagneUser,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }

  const campagneUserActionCreator = CampagneUserGeneratedActions.upsertManyCampagneUsersSuccess;
  actions.push(campagneUserActionCreator({ campagneUsers: normalizedCampagneUsers }));

  return actions;
}

/**
 *  CaracteristiqueTemplate
 */
export function getActionsToNormalizeCaracteristiqueTemplate(
  caracteristiqueTemplates: CaracteristiqueTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const composantTemplatesToNormalize: { [id: number]: ComposantTemplateEntityState } = {};
  const patrimoineHierarchieTemplatesToNormalize: { [id: number]: PatrimoineHierarchieTemplateEntityState } = {};
  const societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[] = [];
  const caracteristiqueChoixTemplatesToNormalize: CaracteristiqueChoixTemplateEntityState[] = [];
  const usageCaracteristiqueTemplatesToNormalize: UsageCaracteristiqueTemplateEntityState[] = [];
  const usagesToNormalize: { [id: number]: UsageEntityState } = {};

  const normalizedCaracteristiqueTemplates = caracteristiqueTemplates.map((caracteristiqueTemplate: CaracteristiqueTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      caracteristiqueTemplate,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristiques',
      'caracteristiqueTemplate',
      'idCaracteristiqueTemplate',
      'idSocieteCaracteristique'
    );
    normalizeMultipleElements(
      caracteristiqueTemplate,
      caracteristiqueChoixTemplatesToNormalize,
      relations,
      'caracteristiqueChoixTemplates',
      'caracteristiqueTemplate',
      'idCaracteristiqueTemplate',
      'idCaracteristiqueChoixTemplate'
    );
    normalizeMultipleElements(
      caracteristiqueTemplate,
      usageCaracteristiqueTemplatesToNormalize,
      relations,
      'usageCaracteristiqueTemplates',
      'caracteristiqueTemplate',
      'idCaracteristiqueTemplate',
      'idUsageCaracteristiqueTemplate'
    );
    normalizeMultipleElementsDict(
      caracteristiqueTemplate,
      usagesToNormalize,
      relations,
      'usages',
      'caracteristiqueTemplates',
      'idCaracteristiqueTemplate',
      'idUsage'
    );
    normalizeSingleElement(
      caracteristiqueTemplate,
      composantTemplatesToNormalize,
      relations,
      'composantTemplate',
      'caracteristiqueTemplates',
      'idCaracteristiqueTemplate',
      'idComposantTemplate'
    );
    normalizeSingleElement(
      caracteristiqueTemplate,
      patrimoineHierarchieTemplatesToNormalize,
      relations,
      'patrimoineHierarchieTemplate',
      'caracteristiqueTemplates',
      'idCaracteristiqueTemplate',
      'idPatrimoineHierarchieTemplate'
    );

    return {
      ...caracteristiqueTemplate,
      ...relations
    };
  });
  const composantTemplatesToNormalizeArray = Object.values(composantTemplatesToNormalize);
  if (composantTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalizeArray, actionType));
  }
  const patrimoineHierarchieTemplatesToNormalizeArray = Object.values(patrimoineHierarchieTemplatesToNormalize);
  if (patrimoineHierarchieTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoineHierarchieTemplate(patrimoineHierarchieTemplatesToNormalizeArray, actionType));
  }
  if (societeCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalize, actionType));
  }
  if (caracteristiqueChoixTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueChoixTemplate(caracteristiqueChoixTemplatesToNormalize, actionType));
  }
  if (usageCaracteristiqueTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeUsageCaracteristiqueTemplate(usageCaracteristiqueTemplatesToNormalize, actionType));
  }
  const usagesToNormalizeArray = Object.values(usagesToNormalize);
  if (usagesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUsage(usagesToNormalizeArray, actionType));
  }

  const caracteristiqueTemplateActionCreator = CaracteristiqueTemplateGeneratedActions.upsertManyCaracteristiqueTemplatesSuccess;
  actions.push(caracteristiqueTemplateActionCreator({ caracteristiqueTemplates: normalizedCaracteristiqueTemplates }));

  return actions;
}

/**
 *  CaracteristiqueChoixTemplate
 */
export function getActionsToNormalizeCaracteristiqueChoixTemplate(
  caracteristiqueChoixTemplates: CaracteristiqueChoixTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const caracteristiqueTemplatesToNormalize: { [id: number]: CaracteristiqueTemplateEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[] = [];

  const normalizedCaracteristiqueChoixTemplates = caracteristiqueChoixTemplates.map((caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      caracteristiqueChoixTemplate,
      societeCaracteristiqueChoicesToNormalize,
      relations,
      'societeCaracteristiqueChoices',
      'caracteristiqueChoixTemplate',
      'idCaracteristiqueChoixTemplate',
      'idSocieteCaracteristiqueChoix'
    );
    normalizeSingleElement(
      caracteristiqueChoixTemplate,
      caracteristiqueTemplatesToNormalize,
      relations,
      'caracteristiqueTemplate',
      'caracteristiqueChoixTemplates',
      'idCaracteristiqueChoixTemplate',
      'idCaracteristiqueTemplate'
    );
    normalizeSingleElement(
      caracteristiqueChoixTemplate,
      fichiersToNormalize,
      relations,
      'fichier',
      'caracteristiqueChoixTemplates',
      'idCaracteristiqueChoixTemplate',
      'idFichier'
    );

    return {
      ...caracteristiqueChoixTemplate,
      ...relations
    };
  });
  const caracteristiqueTemplatesToNormalizeArray = Object.values(caracteristiqueTemplatesToNormalize);
  if (caracteristiqueTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (societeCaracteristiqueChoicesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoicesToNormalize, actionType));
  }

  const caracteristiqueChoixTemplateActionCreator = CaracteristiqueChoixTemplateGeneratedActions.upsertManyCaracteristiqueChoixTemplatesSuccess;
  actions.push(caracteristiqueChoixTemplateActionCreator({ caracteristiqueChoixTemplates: normalizedCaracteristiqueChoixTemplates }));

  return actions;
}

/**
 *  Composant
 */
export function getActionsToNormalizeComposant(
  composants: ComposantEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const espacesToNormalize: { [id: number]: EspaceEntityState } = {};
  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const valeurComposantsToNormalize: ValeurComposantEntityState[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};

  const normalizedComposants = composants.map((composant: ComposantEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      composant,
      valeurComposantsToNormalize,
      relations,
      'valeurComposants',
      'composant',
      'idComposant',
      'idValeurComposant'
    );
    normalizeMultipleElementsDict(
      composant,
      valeursToNormalize,
      relations,
      'valeurs',
      'composants',
      'idComposant',
      'idValeur'
    );
    normalizeSingleElement(
      composant,
      espacesToNormalize,
      relations,
      'espace',
      'composants',
      'idComposant',
      'idEspace'
    );
    normalizeSingleElement(
      composant,
      societeComposantsToNormalize,
      relations,
      'societeComposant',
      'composants',
      'idComposant',
      'idSocieteComposant'
    );
    normalizeSingleElement(
      composant,
      usersToNormalize,
      relations,
      'user',
      'composants',
      'idComposant',
      'idUser'
    );

    return {
      ...composant,
      ...relations
    };
  });
  const espacesToNormalizeArray = Object.values(espacesToNormalize);
  if (espacesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeEspace(espacesToNormalizeArray, actionType));
  }
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (valeurComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurComposant(valeurComposantsToNormalize, actionType));
  }
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }

  const composantActionCreator = ComposantGeneratedActions.upsertManyComposantsSuccess;
  actions.push(composantActionCreator({ composants: normalizedComposants }));

  return actions;
}

/**
 *  ComposantAttendu
 */
export function getActionsToNormalizeComposantAttendu(
  composantAttendus: ComposantAttenduEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedComposantAttendus = composantAttendus.map((composantAttendu: ComposantAttenduEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      composantAttendu,
      societeComposantsToNormalize,
      relations,
      'societeComposant',
      'composantAttendus',
      'idComposantAttendu',
      'idSocieteComposant'
    );
    normalizeSingleElement(
      composantAttendu,
      patrimoinesToNormalize,
      relations,
      'patrimoine',
      'composantAttendus',
      'idComposantAttendu',
      'idPatrimoine'
    );
    normalizeSingleElement(
      composantAttendu,
      usersToNormalize,
      relations,
      'user',
      'composantAttendus',
      'idComposantAttendu',
      'idUser'
    );

    return {
      ...composantAttendu,
      ...relations
    };
  });
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const composantAttenduActionCreator = ComposantAttenduGeneratedActions.upsertManyComposantAttendusSuccess;
  actions.push(composantAttenduActionCreator({ composantAttendus: normalizedComposantAttendus }));

  return actions;
}

/**
 *  ComposantGroupe
 */
export function getActionsToNormalizeComposantGroupe(
  composantGroupes: ComposantGroupeEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];

  const normalizedComposantGroupes = composantGroupes.map((composantGroupe: ComposantGroupeEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      composantGroupe,
      societeComposantsToNormalize,
      relations,
      'societeComposants',
      'composantGroupe',
      'idComposantGroupe',
      'idSocieteComposant'
    );
    normalizeSingleElement(
      composantGroupe,
      fichiersToNormalize,
      relations,
      'fichier',
      'composantGroupes',
      'idComposantGroupe',
      'idFichier'
    );
    normalizeSingleElement(
      composantGroupe,
      societesToNormalize,
      relations,
      'societe',
      'composantGroupes',
      'idComposantGroupe',
      'idSociete'
    );

    return {
      ...composantGroupe,
      ...relations
    };
  });
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }

  const composantGroupeActionCreator = ComposantGroupeGeneratedActions.upsertManyComposantGroupesSuccess;
  actions.push(composantGroupeActionCreator({ composantGroupes: normalizedComposantGroupes }));

  return actions;
}

/**
 *  ComposantTemplate
 */
export function getActionsToNormalizeComposantTemplate(
  composantTemplates: ComposantTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const caracteristiqueTemplatesToNormalize: CaracteristiqueTemplateEntityState[] = [];
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];
  const usageComposantTemplatesToNormalize: UsageComposantTemplateEntityState[] = [];
  const usagesToNormalize: { [id: number]: UsageEntityState } = {};

  const normalizedComposantTemplates = composantTemplates.map((composantTemplate: ComposantTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      composantTemplate,
      caracteristiqueTemplatesToNormalize,
      relations,
      'caracteristiqueTemplates',
      'composantTemplate',
      'idComposantTemplate',
      'idCaracteristiqueTemplate'
    );
    normalizeMultipleElements(
      composantTemplate,
      societeComposantsToNormalize,
      relations,
      'societeComposants',
      'composantTemplate',
      'idComposantTemplate',
      'idSocieteComposant'
    );
    normalizeMultipleElements(
      composantTemplate,
      usageComposantTemplatesToNormalize,
      relations,
      'usageComposantTemplates',
      'composantTemplate',
      'idComposantTemplate',
      'idUsageComposantTemplate'
    );
    normalizeMultipleElementsDict(
      composantTemplate,
      usagesToNormalize,
      relations,
      'usages',
      'composantTemplates',
      'idComposantTemplate',
      'idUsage'
    );
    normalizeSingleElement(
      composantTemplate,
      fichiersToNormalize,
      relations,
      'fichier',
      'composantTemplates',
      'idComposantTemplate',
      'idFichier'
    );

    return {
      ...composantTemplate,
      ...relations
    };
  });
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (caracteristiqueTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalize, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }
  if (usageComposantTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeUsageComposantTemplate(usageComposantTemplatesToNormalize, actionType));
  }
  const usagesToNormalizeArray = Object.values(usagesToNormalize);
  if (usagesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUsage(usagesToNormalizeArray, actionType));
  }

  const composantTemplateActionCreator = ComposantTemplateGeneratedActions.upsertManyComposantTemplatesSuccess;
  actions.push(composantTemplateActionCreator({ composantTemplates: normalizedComposantTemplates }));

  return actions;
}

/**
 *  Droit
 */
export function getActionsToNormalizeDroit(
  droits: DroitEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeProfilDroitsToNormalize: SocieteProfilDroitEntityState[] = [];
  const societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState } = {};

  const normalizedDroits = droits.map((droit: DroitEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      droit,
      societeProfilDroitsToNormalize,
      relations,
      'societeProfilDroits',
      'droit',
      'idDroit',
      'idSocieteProfilDroit'
    );
    normalizeMultipleElementsDict(
      droit,
      societeProfilsToNormalize,
      relations,
      'societeProfils',
      'droits',
      'idDroit',
      'idSocieteProfil'
    );

    return {
      ...droit,
      ...relations
    };
  });
  if (societeProfilDroitsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteProfilDroit(societeProfilDroitsToNormalize, actionType));
  }
  const societeProfilsToNormalizeArray = Object.values(societeProfilsToNormalize);
  if (societeProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalizeArray, actionType));
  }

  const droitActionCreator = DroitGeneratedActions.upsertManyDroitsSuccess;
  actions.push(droitActionCreator({ droits: normalizedDroits }));

  return actions;
}

/**
 *  Espace
 */
export function getActionsToNormalizeEspace(
  espaces: EspaceEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const societeEspacesToNormalize: { [id: number]: SocieteEspaceEntityState } = {};
  const composantsToNormalize: ComposantEntityState[] = [];

  const normalizedEspaces = espaces.map((espace: EspaceEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      espace,
      composantsToNormalize,
      relations,
      'composants',
      'espace',
      'idEspace',
      'idComposant'
    );
    normalizeSingleElement(
      espace,
      patrimoinesToNormalize,
      relations,
      'patrimoine',
      'espaces',
      'idEspace',
      'idPatrimoine'
    );
    normalizeSingleElement(
      espace,
      societeEspacesToNormalize,
      relations,
      'societeEspace',
      'espaces',
      'idEspace',
      'idSocieteEspace'
    );

    return {
      ...espace,
      ...relations
    };
  });
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  const societeEspacesToNormalizeArray = Object.values(societeEspacesToNormalize);
  if (societeEspacesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteEspace(societeEspacesToNormalizeArray, actionType));
  }
  if (composantsToNormalize.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalize, actionType));
  }

  const espaceActionCreator = EspaceGeneratedActions.upsertManyEspacesSuccess;
  actions.push(espaceActionCreator({ espaces: normalizedEspaces }));

  return actions;
}

/**
 *  Fichier
 */
export function getActionsToNormalizeFichier(
  fichiers: FichierEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];
  const societeComposantFamillesToNormalize: SocieteComposantFamilleEntityState[] = [];
  const composantGroupesToNormalize: ComposantGroupeEntityState[] = [];
  const composantTemplatesToNormalize: ComposantTemplateEntityState[] = [];
  const societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[] = [];
  const caracteristiqueChoixTemplatesToNormalize: CaracteristiqueChoixTemplateEntityState[] = [];
  const valeurFichiersToNormalize: ValeurFichierEntityState[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};

  const normalizedFichiers = fichiers.map((fichier: FichierEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      fichier,
      societeComposantsToNormalize,
      relations,
      'societeComposants',
      'fichier',
      'idFichier',
      'idSocieteComposant'
    );
    normalizeMultipleElements(
      fichier,
      societeComposantFamillesToNormalize,
      relations,
      'societeComposantFamilles',
      'fichier',
      'idFichier',
      'idSocieteComposantFamille'
    );
    normalizeMultipleElements(
      fichier,
      composantGroupesToNormalize,
      relations,
      'composantGroupes',
      'fichier',
      'idFichier',
      'idComposantGroupe'
    );
    normalizeMultipleElements(
      fichier,
      composantTemplatesToNormalize,
      relations,
      'composantTemplates',
      'fichier',
      'idFichier',
      'idComposantTemplate'
    );
    normalizeMultipleElements(
      fichier,
      societeCaracteristiqueChoicesToNormalize,
      relations,
      'societeCaracteristiqueChoices',
      'fichier',
      'idFichier',
      'idSocieteCaracteristiqueChoix'
    );
    normalizeMultipleElements(
      fichier,
      caracteristiqueChoixTemplatesToNormalize,
      relations,
      'caracteristiqueChoixTemplates',
      'fichier',
      'idFichier',
      'idCaracteristiqueChoixTemplate'
    );
    normalizeMultipleElements(
      fichier,
      valeurFichiersToNormalize,
      relations,
      'valeurFichiers',
      'fichier',
      'idFichier',
      'idValeurFichier'
    );
    normalizeMultipleElementsDict(
      fichier,
      valeursToNormalize,
      relations,
      'valeurs',
      'fichiers',
      'idFichier',
      'idValeur'
    );
    normalizeSingleElement(
      fichier,
      organisationsToNormalize,
      relations,
      'organisation',
      'fichiers',
      'idFichier',
      'idOrganisation'
    );

    return {
      ...fichier,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }
  if (societeComposantFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantFamille(societeComposantFamillesToNormalize, actionType));
  }
  if (composantGroupesToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantGroupe(composantGroupesToNormalize, actionType));
  }
  if (composantTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalize, actionType));
  }
  if (societeCaracteristiqueChoicesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoicesToNormalize, actionType));
  }
  if (caracteristiqueChoixTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueChoixTemplate(caracteristiqueChoixTemplatesToNormalize, actionType));
  }
  if (valeurFichiersToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurFichier(valeurFichiersToNormalize, actionType));
  }
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }

  const fichierActionCreator = FichierGeneratedActions.upsertManyFichiersSuccess;
  actions.push(fichierActionCreator({ fichiers: normalizedFichiers }));

  return actions;
}

/**
 *  Newsletter
 */
export function getActionsToNormalizeNewsletter(
  newsletters: NewsletterEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const userNewslettersToNormalize: UserNewsletterEntityState[] = [];
  const organisationNewslettersToNormalize: OrganisationNewsletterEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};

  const normalizedNewsletters = newsletters.map((newsletter: NewsletterEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      newsletter,
      userNewslettersToNormalize,
      relations,
      'userNewsletters',
      'newsletter',
      'idNewsletter',
      'idUserNewsletter'
    );
    normalizeMultipleElements(
      newsletter,
      organisationNewslettersToNormalize,
      relations,
      'organisationNewsletters',
      'newsletter',
      'idNewsletter',
      'idOrganisationNewsletter'
    );
    normalizeMultipleElementsDict(
      newsletter,
      usersToNormalize,
      relations,
      'users',
      'newsletters',
      'idNewsletter',
      'idUser'
    );
    normalizeMultipleElementsDict(
      newsletter,
      organisationsToNormalize,
      relations,
      'organisations',
      'newsletters',
      'idNewsletter',
      'idOrganisation'
    );

    return {
      ...newsletter,
      ...relations
    };
  });
  if (userNewslettersToNormalize.length) {
    actions.push(...getActionsToNormalizeUserNewsletter(userNewslettersToNormalize, actionType));
  }
  if (organisationNewslettersToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganisationNewsletter(organisationNewslettersToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }

  const newsletterActionCreator = NewsletterGeneratedActions.upsertManyNewslettersSuccess;
  actions.push(newsletterActionCreator({ newsletters: normalizedNewsletters }));

  return actions;
}

/**
 *  OrganisationAzureLogin
 */
export function getActionsToNormalizeOrganisationAzureLogin(
  organisationAzureLogins: OrganisationAzureLoginEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};

  const normalizedOrganisationAzureLogins = organisationAzureLogins.map((organisationAzureLogin: OrganisationAzureLoginEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      organisationAzureLogin,
      organisationsToNormalize,
      relations,
      'organisation',
      'organisationAzureLogins',
      'idOrganisationAzureLogin',
      'idOrganisation'
    );

    return {
      ...organisationAzureLogin,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }

  const organisationAzureLoginActionCreator = OrganisationAzureLoginGeneratedActions.upsertManyOrganisationAzureLoginsSuccess;
  actions.push(organisationAzureLoginActionCreator({ organisationAzureLogins: normalizedOrganisationAzureLogins }));

  return actions;
}

/**
 *  Organisation
 */
export function getActionsToNormalizeOrganisation(
  organisations: OrganisationEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: SocieteEntityState[] = [];
  const usersToNormalize: UserEntityState[] = [];
  const fichiersToNormalize: FichierEntityState[] = [];
  const organisationAzureLoginsToNormalize: OrganisationAzureLoginEntityState[] = [];
  const organisationNewslettersToNormalize: OrganisationNewsletterEntityState[] = [];
  const newslettersToNormalize: { [id: number]: NewsletterEntityState } = {};

  const normalizedOrganisations = organisations.map((organisation: OrganisationEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      organisation,
      societesToNormalize,
      relations,
      'societes',
      'organisation',
      'idOrganisation',
      'idSociete'
    );
    normalizeMultipleElements(
      organisation,
      usersToNormalize,
      relations,
      'users',
      'organisation',
      'idOrganisation',
      'idUser'
    );
    normalizeMultipleElements(
      organisation,
      fichiersToNormalize,
      relations,
      'fichiers',
      'organisation',
      'idOrganisation',
      'idFichier'
    );
    normalizeMultipleElements(
      organisation,
      organisationAzureLoginsToNormalize,
      relations,
      'organisationAzureLogins',
      'organisation',
      'idOrganisation',
      'idOrganisationAzureLogin'
    );
    normalizeMultipleElements(
      organisation,
      organisationNewslettersToNormalize,
      relations,
      'organisationNewsletters',
      'organisation',
      'idOrganisation',
      'idOrganisationNewsletter'
    );
    normalizeMultipleElementsDict(
      organisation,
      newslettersToNormalize,
      relations,
      'newsletters',
      'organisations',
      'idOrganisation',
      'idNewsletter'
    );

    return {
      ...organisation,
      ...relations
    };
  });
  if (societesToNormalize.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalize, actionType));
  }
  if (usersToNormalize.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalize, actionType));
  }
  if (fichiersToNormalize.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalize, actionType));
  }
  if (organisationAzureLoginsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganisationAzureLogin(organisationAzureLoginsToNormalize, actionType));
  }
  if (organisationNewslettersToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganisationNewsletter(organisationNewslettersToNormalize, actionType));
  }
  const newslettersToNormalizeArray = Object.values(newslettersToNormalize);
  if (newslettersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeNewsletter(newslettersToNormalizeArray, actionType));
  }

  const organisationActionCreator = OrganisationGeneratedActions.upsertManyOrganisationsSuccess;
  actions.push(organisationActionCreator({ organisations: normalizedOrganisations }));

  return actions;
}

/**
 *  OrganisationNewsletter
 */
export function getActionsToNormalizeOrganisationNewsletter(
  organisationNewsletters: OrganisationNewsletterEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};
  const newslettersToNormalize: { [id: number]: NewsletterEntityState } = {};

  const normalizedOrganisationNewsletters = organisationNewsletters.map((organisationNewsletter: OrganisationNewsletterEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      organisationNewsletter,
      organisationsToNormalize,
      relations,
      'organisation',
      'organisationNewsletters',
      'idOrganisationNewsletter',
      'idOrganisation'
    );
    normalizeSingleElement(
      organisationNewsletter,
      newslettersToNormalize,
      relations,
      'newsletter',
      'organisationNewsletters',
      'idOrganisationNewsletter',
      'idNewsletter'
    );

    return {
      ...organisationNewsletter,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }
  const newslettersToNormalizeArray = Object.values(newslettersToNormalize);
  if (newslettersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeNewsletter(newslettersToNormalizeArray, actionType));
  }

  const organisationNewsletterActionCreator = OrganisationNewsletterGeneratedActions.upsertManyOrganisationNewslettersSuccess;
  actions.push(organisationNewsletterActionCreator({ organisationNewsletters: normalizedOrganisationNewsletters }));

  return actions;
}

/**
 *  Patrimoine
 */
export function getActionsToNormalizePatrimoine(
  patrimoines: PatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const userPatrimoinesToNormalize: UserPatrimoineEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const valeurPatrimoinesToNormalize: ValeurPatrimoineEntityState[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const ancetresToNormalize: PatrimoineAncetreEntityState[] = [];
  const descendantsToNormalize: PatrimoineAncetreEntityState[] = [];
  const espacesToNormalize: EspaceEntityState[] = [];
  const campagnePatrimoinesToNormalize: CampagnePatrimoineEntityState[] = [];
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const societeTerritoirePatrimoinesToNormalize: SocieteTerritoirePatrimoineEntityState[] = [];
  const societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState } = {};
  const composantAttendusToNormalize: ComposantAttenduEntityState[] = [];
  const progPatrimoineInfosToNormalize: ProgPatrimoineInfoEntityState[] = [];
  const progComposantsToNormalize: { [id: number]: ProgComposantEntityState } = {};
  const progInterventionsToNormalize: { [id: number]: ProgInterventionEntityState } = {};
  const valeurAnswersToNormalize: ValeurAnswerEntityState[] = [];

  const normalizedPatrimoines = patrimoines.map((patrimoine: PatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      patrimoine,
      userPatrimoinesToNormalize,
      relations,
      'userPatrimoines',
      'patrimoine',
      'idPatrimoine',
      'idUserPatrimoine'
    );
    normalizeMultipleElementsDict(
      patrimoine,
      usersToNormalize,
      relations,
      'users',
      'patrimoines',
      'idPatrimoine',
      'idUser'
    );
    normalizeMultipleElements(
      patrimoine,
      valeurPatrimoinesToNormalize,
      relations,
      'valeurPatrimoines',
      'patrimoine',
      'idPatrimoine',
      'idValeurPatrimoine'
    );
    normalizeMultipleElementsDict(
      patrimoine,
      valeursToNormalize,
      relations,
      'valeurs',
      'patrimoines',
      'idPatrimoine',
      'idValeur'
    );
    normalizeMultipleElements(
      patrimoine,
      ancetresToNormalize,
      relations,
      'ancetres',
      'descendantPatrimoine',
      'idPatrimoine',
      'idPatrimoineAncetre'
    );
    normalizeMultipleElements(
      patrimoine,
      descendantsToNormalize,
      relations,
      'descendants',
      'ancetrePatrimoine',
      'idPatrimoine',
      'idPatrimoineAncetre'
    );
    normalizeMultipleElements(
      patrimoine,
      espacesToNormalize,
      relations,
      'espaces',
      'patrimoine',
      'idPatrimoine',
      'idEspace'
    );
    normalizeMultipleElements(
      patrimoine,
      campagnePatrimoinesToNormalize,
      relations,
      'campagnePatrimoines',
      'patrimoine',
      'idPatrimoine',
      'idCampagnePatrimoine'
    );
    normalizeMultipleElementsDict(
      patrimoine,
      campagnesToNormalize,
      relations,
      'campagnes',
      'patrimoines',
      'idPatrimoine',
      'idCampagne'
    );
    normalizeMultipleElements(
      patrimoine,
      societeTerritoirePatrimoinesToNormalize,
      relations,
      'societeTerritoirePatrimoines',
      'patrimoine',
      'idPatrimoine',
      'idSocieteTerritoirePatrimoine'
    );
    normalizeMultipleElementsDict(
      patrimoine,
      societeTerritoiresToNormalize,
      relations,
      'societeTerritoires',
      'patrimoines',
      'idPatrimoine',
      'idSocieteTerritoire'
    );
    normalizeMultipleElements(
      patrimoine,
      composantAttendusToNormalize,
      relations,
      'composantAttendus',
      'patrimoine',
      'idPatrimoine',
      'idComposantAttendu'
    );
    normalizeMultipleElements(
      patrimoine,
      progPatrimoineInfosToNormalize,
      relations,
      'progPatrimoineInfos',
      'patrimoine',
      'idPatrimoine',
      'idProgPatrimoineInfo'
    );
    normalizeMultipleElementsDict(
      patrimoine,
      progComposantsToNormalize,
      relations,
      'progComposants',
      'patrimoines',
      'idPatrimoine',
      'idProgComposant'
    );
    normalizeMultipleElementsDict(
      patrimoine,
      progInterventionsToNormalize,
      relations,
      'progInterventions',
      'patrimoines',
      'idPatrimoine',
      'idProgIntervention'
    );
    normalizeMultipleElements(
      patrimoine,
      valeurAnswersToNormalize,
      relations,
      'valeurAnswers',
      'patrimoine',
      'idPatrimoine',
      'idValeurAnswer'
    );
    normalizeSingleElement(
      patrimoine,
      societePatrimoineHierarchiesToNormalize,
      relations,
      'societePatrimoineHierarchie',
      'patrimoines',
      'idPatrimoine',
      'idSocietePatrimoineHierarchie'
    );
    normalizeSingleElement(
      patrimoine,
      societesToNormalize,
      relations,
      'societe',
      'patrimoines',
      'idPatrimoine',
      'idSociete'
    );

    return {
      ...patrimoine,
      ...relations
    };
  });
  const societePatrimoineHierarchiesToNormalizeArray = Object.values(societePatrimoineHierarchiesToNormalize);
  if (societePatrimoineHierarchiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalizeArray, actionType));
  }
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (userPatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeUserPatrimoine(userPatrimoinesToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (valeurPatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurPatrimoine(valeurPatrimoinesToNormalize, actionType));
  }
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  if (ancetresToNormalize.length) {
    actions.push(...getActionsToNormalizePatrimoineAncetre(ancetresToNormalize, actionType));
  }
  if (descendantsToNormalize.length) {
    actions.push(...getActionsToNormalizePatrimoineAncetre(descendantsToNormalize, actionType));
  }
  if (espacesToNormalize.length) {
    actions.push(...getActionsToNormalizeEspace(espacesToNormalize, actionType));
  }
  if (campagnePatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagnePatrimoine(campagnePatrimoinesToNormalize, actionType));
  }
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  if (societeTerritoirePatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoirePatrimoine(societeTerritoirePatrimoinesToNormalize, actionType));
  }
  const societeTerritoiresToNormalizeArray = Object.values(societeTerritoiresToNormalize);
  if (societeTerritoiresToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoire(societeTerritoiresToNormalizeArray, actionType));
  }
  if (composantAttendusToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantAttendu(composantAttendusToNormalize, actionType));
  }
  if (progPatrimoineInfosToNormalize.length) {
    actions.push(...getActionsToNormalizeProgPatrimoineInfo(progPatrimoineInfosToNormalize, actionType));
  }
  const progComposantsToNormalizeArray = Object.values(progComposantsToNormalize);
  if (progComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgComposant(progComposantsToNormalizeArray, actionType));
  }
  const progInterventionsToNormalizeArray = Object.values(progInterventionsToNormalize);
  if (progInterventionsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgIntervention(progInterventionsToNormalizeArray, actionType));
  }
  if (valeurAnswersToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurAnswer(valeurAnswersToNormalize, actionType));
  }

  const patrimoineActionCreator = PatrimoineGeneratedActions.upsertManyPatrimoinesSuccess;
  actions.push(patrimoineActionCreator({ patrimoines: normalizedPatrimoines }));

  return actions;
}

/**
 *  PatrimoineAncetre
 */
export function getActionsToNormalizePatrimoineAncetre(
  patrimoineAncetres: PatrimoineAncetreEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const ancetrePatrimoineToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const descendantPatrimoineToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedPatrimoineAncetres = patrimoineAncetres.map((patrimoineAncetre: PatrimoineAncetreEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      patrimoineAncetre,
      ancetrePatrimoineToNormalize,
      relations,
      'ancetrePatrimoine',
      'descendants',
      'idPatrimoineAncetre',
      'idPatrimoine'
    );
    normalizeSingleElement(
      patrimoineAncetre,
      descendantPatrimoineToNormalize,
      relations,
      'descendantPatrimoine',
      'ancetres',
      'idPatrimoineAncetre',
      'idPatrimoine'
    );

    return {
      ...patrimoineAncetre,
      ...relations
    };
  });
  const ancetrePatrimoineToNormalizeArray = Object.values(ancetrePatrimoineToNormalize);
  if (ancetrePatrimoineToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(ancetrePatrimoineToNormalizeArray, actionType));
  }
  const descendantPatrimoineToNormalizeArray = Object.values(descendantPatrimoineToNormalize);
  if (descendantPatrimoineToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(descendantPatrimoineToNormalizeArray, actionType));
  }

  const patrimoineAncetreActionCreator = PatrimoineAncetreGeneratedActions.upsertManyPatrimoineAncetresSuccess;
  actions.push(patrimoineAncetreActionCreator({ patrimoineAncetres: normalizedPatrimoineAncetres }));

  return actions;
}

/**
 *  PatrimoineHierarchieTemplate
 */
export function getActionsToNormalizePatrimoineHierarchieTemplate(
  patrimoineHierarchieTemplates: PatrimoineHierarchieTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societePatrimoineHierarchiesToNormalize: SocietePatrimoineHierarchieEntityState[] = [];
  const caracteristiqueTemplatesToNormalize: CaracteristiqueTemplateEntityState[] = [];

  const normalizedPatrimoineHierarchieTemplates = patrimoineHierarchieTemplates.map((patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      patrimoineHierarchieTemplate,
      societePatrimoineHierarchiesToNormalize,
      relations,
      'societePatrimoineHierarchies',
      'patrimoineHierarchieTemplate',
      'idPatrimoineHierarchieTemplate',
      'idSocietePatrimoineHierarchie'
    );
    normalizeMultipleElements(
      patrimoineHierarchieTemplate,
      caracteristiqueTemplatesToNormalize,
      relations,
      'caracteristiqueTemplates',
      'patrimoineHierarchieTemplate',
      'idPatrimoineHierarchieTemplate',
      'idCaracteristiqueTemplate'
    );

    return {
      ...patrimoineHierarchieTemplate,
      ...relations
    };
  });
  if (societePatrimoineHierarchiesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalize, actionType));
  }
  if (caracteristiqueTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalize, actionType));
  }

  const patrimoineHierarchieTemplateActionCreator = PatrimoineHierarchieTemplateGeneratedActions.upsertManyPatrimoineHierarchieTemplatesSuccess;
  actions.push(patrimoineHierarchieTemplateActionCreator({ patrimoineHierarchieTemplates: normalizedPatrimoineHierarchieTemplates }));

  return actions;
}

/**
 *  ProgBiblioIntervention
 */
export function getActionsToNormalizeProgBiblioIntervention(
  progBiblioInterventions: ProgBiblioInterventionEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progComposantsToNormalize: { [id: number]: ProgComposantEntityState } = {};
  const progBudgetsToNormalize: { [id: number]: ProgBudgetEntityState } = {};
  const progUnitesToNormalize: { [id: number]: ProgUniteEntityState } = {};
  const progInterventionFamillesToNormalize: { [id: number]: ProgInterventionFamilleEntityState } = {};
  const progBiblioInterventionScenariosToNormalize: ProgBiblioInterventionScenarioEntityState[] = [];
  const progScenariosToNormalize: { [id: number]: ProgScenarioEntityState } = {};

  const normalizedProgBiblioInterventions = progBiblioInterventions.map((progBiblioIntervention: ProgBiblioInterventionEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progBiblioIntervention,
      progBiblioInterventionScenariosToNormalize,
      relations,
      'progBiblioInterventionScenarios',
      'progBiblioIntervention',
      'idProgBiblioIntervention',
      'idProgBiblioInterventionScenario'
    );
    normalizeMultipleElementsDict(
      progBiblioIntervention,
      progScenariosToNormalize,
      relations,
      'progScenarios',
      'progBiblioInterventions',
      'idProgBiblioIntervention',
      'idProgScenario'
    );
    normalizeSingleElement(
      progBiblioIntervention,
      societesToNormalize,
      relations,
      'societe',
      'progBiblioInterventions',
      'idProgBiblioIntervention',
      'idSociete'
    );
    normalizeSingleElement(
      progBiblioIntervention,
      progComposantsToNormalize,
      relations,
      'progComposant',
      'progBiblioInterventions',
      'idProgBiblioIntervention',
      'idProgComposant'
    );
    normalizeSingleElement(
      progBiblioIntervention,
      progBudgetsToNormalize,
      relations,
      'progBudget',
      'progBiblioInterventions',
      'idProgBiblioIntervention',
      'idProgBudget'
    );
    normalizeSingleElement(
      progBiblioIntervention,
      progUnitesToNormalize,
      relations,
      'progUnite',
      'progBiblioInterventions',
      'idProgBiblioIntervention',
      'idProgUnite'
    );
    normalizeSingleElement(
      progBiblioIntervention,
      progInterventionFamillesToNormalize,
      relations,
      'progInterventionFamille',
      'progBiblioInterventions',
      'idProgBiblioIntervention',
      'idProgInterventionFamille'
    );

    return {
      ...progBiblioIntervention,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const progComposantsToNormalizeArray = Object.values(progComposantsToNormalize);
  if (progComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgComposant(progComposantsToNormalizeArray, actionType));
  }
  const progBudgetsToNormalizeArray = Object.values(progBudgetsToNormalize);
  if (progBudgetsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgBudget(progBudgetsToNormalizeArray, actionType));
  }
  const progUnitesToNormalizeArray = Object.values(progUnitesToNormalize);
  if (progUnitesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgUnite(progUnitesToNormalizeArray, actionType));
  }
  const progInterventionFamillesToNormalizeArray = Object.values(progInterventionFamillesToNormalize);
  if (progInterventionFamillesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgInterventionFamille(progInterventionFamillesToNormalizeArray, actionType));
  }
  if (progBiblioInterventionScenariosToNormalize.length) {
    actions.push(...getActionsToNormalizeProgBiblioInterventionScenario(progBiblioInterventionScenariosToNormalize, actionType));
  }
  const progScenariosToNormalizeArray = Object.values(progScenariosToNormalize);
  if (progScenariosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgScenario(progScenariosToNormalizeArray, actionType));
  }

  const progBiblioInterventionActionCreator = ProgBiblioInterventionGeneratedActions.upsertManyProgBiblioInterventionsSuccess;
  actions.push(progBiblioInterventionActionCreator({ progBiblioInterventions: normalizedProgBiblioInterventions }));

  return actions;
}

/**
 *  ProgBiblioInterventionScenario
 */
export function getActionsToNormalizeProgBiblioInterventionScenario(
  progBiblioInterventionScenarios: ProgBiblioInterventionScenarioEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const progScenariosToNormalize: { [id: number]: ProgScenarioEntityState } = {};
  const progBiblioInterventionsToNormalize: { [id: number]: ProgBiblioInterventionEntityState } = {};

  const normalizedProgBiblioInterventionScenarios = progBiblioInterventionScenarios.map((progBiblioInterventionScenario: ProgBiblioInterventionScenarioEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      progBiblioInterventionScenario,
      progScenariosToNormalize,
      relations,
      'progScenario',
      'progBiblioInterventionScenarios',
      'idProgBiblioInterventionScenario',
      'idProgScenario'
    );
    normalizeSingleElement(
      progBiblioInterventionScenario,
      progBiblioInterventionsToNormalize,
      relations,
      'progBiblioIntervention',
      'progBiblioInterventionScenarios',
      'idProgBiblioInterventionScenario',
      'idProgBiblioIntervention'
    );

    return {
      ...progBiblioInterventionScenario,
      ...relations
    };
  });
  const progScenariosToNormalizeArray = Object.values(progScenariosToNormalize);
  if (progScenariosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgScenario(progScenariosToNormalizeArray, actionType));
  }
  const progBiblioInterventionsToNormalizeArray = Object.values(progBiblioInterventionsToNormalize);
  if (progBiblioInterventionsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgBiblioIntervention(progBiblioInterventionsToNormalizeArray, actionType));
  }

  const progBiblioInterventionScenarioActionCreator = ProgBiblioInterventionScenarioGeneratedActions.upsertManyProgBiblioInterventionScenariosSuccess;
  actions.push(progBiblioInterventionScenarioActionCreator({ progBiblioInterventionScenarios: normalizedProgBiblioInterventionScenarios }));

  return actions;
}

/**
 *  ProgBudget
 */
export function getActionsToNormalizeProgBudget(
  progBudgets: ProgBudgetEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progBiblioInterventionsToNormalize: ProgBiblioInterventionEntityState[] = [];

  const normalizedProgBudgets = progBudgets.map((progBudget: ProgBudgetEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progBudget,
      progBiblioInterventionsToNormalize,
      relations,
      'progBiblioInterventions',
      'progBudget',
      'idProgBudget',
      'idProgBiblioIntervention'
    );
    normalizeSingleElement(
      progBudget,
      societesToNormalize,
      relations,
      'societe',
      'progBudgets',
      'idProgBudget',
      'idSociete'
    );

    return {
      ...progBudget,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (progBiblioInterventionsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgBiblioIntervention(progBiblioInterventionsToNormalize, actionType));
  }

  const progBudgetActionCreator = ProgBudgetGeneratedActions.upsertManyProgBudgetsSuccess;
  actions.push(progBudgetActionCreator({ progBudgets: normalizedProgBudgets }));

  return actions;
}

/**
 *  ProgComposant
 */
export function getActionsToNormalizeProgComposant(
  progComposants: ProgComposantEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progComposantSousGroupesToNormalize: { [id: number]: ProgComposantSousGroupeEntityState } = {};
  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const progBiblioInterventionsToNormalize: ProgBiblioInterventionEntityState[] = [];
  const progPatrimoineInfosToNormalize: ProgPatrimoineInfoEntityState[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedProgComposants = progComposants.map((progComposant: ProgComposantEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progComposant,
      progBiblioInterventionsToNormalize,
      relations,
      'progBiblioInterventions',
      'progComposant',
      'idProgComposant',
      'idProgBiblioIntervention'
    );
    normalizeMultipleElements(
      progComposant,
      progPatrimoineInfosToNormalize,
      relations,
      'progPatrimoineInfos',
      'progComposant',
      'idProgComposant',
      'idProgPatrimoineInfo'
    );
    normalizeMultipleElementsDict(
      progComposant,
      patrimoinesToNormalize,
      relations,
      'patrimoines',
      'progComposants',
      'idProgComposant',
      'idPatrimoine'
    );
    normalizeSingleElement(
      progComposant,
      societesToNormalize,
      relations,
      'societe',
      'progComposants',
      'idProgComposant',
      'idSociete'
    );
    normalizeSingleElement(
      progComposant,
      progComposantSousGroupesToNormalize,
      relations,
      'progComposantSousGroupe',
      'progComposants',
      'idProgComposant',
      'idProgComposantSousGroupe'
    );
    normalizeSingleElement(
      progComposant,
      societeComposantsToNormalize,
      relations,
      'societeComposant',
      'progComposants',
      'idProgComposant',
      'idSocieteComposant'
    );

    return {
      ...progComposant,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const progComposantSousGroupesToNormalizeArray = Object.values(progComposantSousGroupesToNormalize);
  if (progComposantSousGroupesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgComposantSousGroupe(progComposantSousGroupesToNormalizeArray, actionType));
  }
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  if (progBiblioInterventionsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgBiblioIntervention(progBiblioInterventionsToNormalize, actionType));
  }
  if (progPatrimoineInfosToNormalize.length) {
    actions.push(...getActionsToNormalizeProgPatrimoineInfo(progPatrimoineInfosToNormalize, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const progComposantActionCreator = ProgComposantGeneratedActions.upsertManyProgComposantsSuccess;
  actions.push(progComposantActionCreator({ progComposants: normalizedProgComposants }));

  return actions;
}

/**
 *  ProgComposantGroupe
 */
export function getActionsToNormalizeProgComposantGroupe(
  progComposantGroupes: ProgComposantGroupeEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progComposantSousGroupesToNormalize: ProgComposantSousGroupeEntityState[] = [];

  const normalizedProgComposantGroupes = progComposantGroupes.map((progComposantGroupe: ProgComposantGroupeEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progComposantGroupe,
      progComposantSousGroupesToNormalize,
      relations,
      'progComposantSousGroupes',
      'progComposantGroupe',
      'idProgComposantGroupe',
      'idProgComposantSousGroupe'
    );
    normalizeSingleElement(
      progComposantGroupe,
      societesToNormalize,
      relations,
      'societe',
      'progComposantGroupes',
      'idProgComposantGroupe',
      'idSociete'
    );

    return {
      ...progComposantGroupe,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (progComposantSousGroupesToNormalize.length) {
    actions.push(...getActionsToNormalizeProgComposantSousGroupe(progComposantSousGroupesToNormalize, actionType));
  }

  const progComposantGroupeActionCreator = ProgComposantGroupeGeneratedActions.upsertManyProgComposantGroupesSuccess;
  actions.push(progComposantGroupeActionCreator({ progComposantGroupes: normalizedProgComposantGroupes }));

  return actions;
}

/**
 *  ProgComposantSousGroupe
 */
export function getActionsToNormalizeProgComposantSousGroupe(
  progComposantSousGroupes: ProgComposantSousGroupeEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progComposantGroupesToNormalize: { [id: number]: ProgComposantGroupeEntityState } = {};
  const progComposantsToNormalize: ProgComposantEntityState[] = [];

  const normalizedProgComposantSousGroupes = progComposantSousGroupes.map((progComposantSousGroupe: ProgComposantSousGroupeEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progComposantSousGroupe,
      progComposantsToNormalize,
      relations,
      'progComposants',
      'progComposantSousGroupe',
      'idProgComposantSousGroupe',
      'idProgComposant'
    );
    normalizeSingleElement(
      progComposantSousGroupe,
      societesToNormalize,
      relations,
      'societe',
      'progComposantSousGroupes',
      'idProgComposantSousGroupe',
      'idSociete'
    );
    normalizeSingleElement(
      progComposantSousGroupe,
      progComposantGroupesToNormalize,
      relations,
      'progComposantGroupe',
      'progComposantSousGroupes',
      'idProgComposantSousGroupe',
      'idProgComposantGroupe'
    );

    return {
      ...progComposantSousGroupe,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const progComposantGroupesToNormalizeArray = Object.values(progComposantGroupesToNormalize);
  if (progComposantGroupesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgComposantGroupe(progComposantGroupesToNormalizeArray, actionType));
  }
  if (progComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgComposant(progComposantsToNormalize, actionType));
  }

  const progComposantSousGroupeActionCreator = ProgComposantSousGroupeGeneratedActions.upsertManyProgComposantSousGroupesSuccess;
  actions.push(progComposantSousGroupeActionCreator({ progComposantSousGroupes: normalizedProgComposantSousGroupes }));

  return actions;
}

/**
 *  ProgEtatTechnique
 */
export function getActionsToNormalizeProgEtatTechnique(
  progEtatTechniques: ProgEtatTechniqueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progPatrimoineInfosToNormalize: ProgPatrimoineInfoEntityState[] = [];

  const normalizedProgEtatTechniques = progEtatTechniques.map((progEtatTechnique: ProgEtatTechniqueEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progEtatTechnique,
      progPatrimoineInfosToNormalize,
      relations,
      'progPatrimoineInfos',
      'progEtatTechnique',
      'idProgEtatTechnique',
      'idProgPatrimoineInfo'
    );
    normalizeSingleElement(
      progEtatTechnique,
      societesToNormalize,
      relations,
      'societe',
      'progEtatTechniques',
      'idProgEtatTechnique',
      'idSociete'
    );

    return {
      ...progEtatTechnique,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (progPatrimoineInfosToNormalize.length) {
    actions.push(...getActionsToNormalizeProgPatrimoineInfo(progPatrimoineInfosToNormalize, actionType));
  }

  const progEtatTechniqueActionCreator = ProgEtatTechniqueGeneratedActions.upsertManyProgEtatTechniquesSuccess;
  actions.push(progEtatTechniqueActionCreator({ progEtatTechniques: normalizedProgEtatTechniques }));

  return actions;
}

/**
 *  ProgIntervention
 */
export function getActionsToNormalizeProgIntervention(
  progInterventions: ProgInterventionEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedProgInterventions = progInterventions.map((progIntervention: ProgInterventionEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElementsDict(
      progIntervention,
      patrimoinesToNormalize,
      relations,
      'patrimoines',
      'progInterventions',
      'idProgIntervention',
      'idPatrimoine'
    );

    return {
      ...progIntervention,
      ...relations
    };
  });
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const progInterventionActionCreator = ProgInterventionGeneratedActions.upsertManyProgInterventionsSuccess;
  actions.push(progInterventionActionCreator({ progInterventions: normalizedProgInterventions }));

  return actions;
}

/**
 *  ProgInterventionFamille
 */
export function getActionsToNormalizeProgInterventionFamille(
  progInterventionFamilles: ProgInterventionFamilleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progBiblioInterventionsToNormalize: ProgBiblioInterventionEntityState[] = [];

  const normalizedProgInterventionFamilles = progInterventionFamilles.map((progInterventionFamille: ProgInterventionFamilleEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progInterventionFamille,
      progBiblioInterventionsToNormalize,
      relations,
      'progBiblioInterventions',
      'progInterventionFamille',
      'idProgInterventionFamille',
      'idProgBiblioIntervention'
    );
    normalizeSingleElement(
      progInterventionFamille,
      societesToNormalize,
      relations,
      'societe',
      'progInterventionFamilles',
      'idProgInterventionFamille',
      'idSociete'
    );

    return {
      ...progInterventionFamille,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (progBiblioInterventionsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgBiblioIntervention(progBiblioInterventionsToNormalize, actionType));
  }

  const progInterventionFamilleActionCreator = ProgInterventionFamilleGeneratedActions.upsertManyProgInterventionFamillesSuccess;
  actions.push(progInterventionFamilleActionCreator({ progInterventionFamilles: normalizedProgInterventionFamilles }));

  return actions;
}

/**
 *  ProgInterventionPatrimoine
 */
export function getActionsToNormalizeProgInterventionPatrimoine(
  progInterventionPatrimoines: ProgInterventionPatrimoineEntityState[]
): Action[] {
  const actions: Action[] = [];

  const normalizedProgInterventionPatrimoines = progInterventionPatrimoines.map((progInterventionPatrimoine: ProgInterventionPatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};

    return {
      ...progInterventionPatrimoine,
      ...relations
    };
  });

  const progInterventionPatrimoineActionCreator = ProgInterventionPatrimoineGeneratedActions.upsertManyProgInterventionPatrimoinesSuccess;
  actions.push(progInterventionPatrimoineActionCreator({ progInterventionPatrimoines: normalizedProgInterventionPatrimoines }));

  return actions;
}

/**
 *  ProgInterventionScenario
 */
export function getActionsToNormalizeProgInterventionScenario(
  progInterventionScenarios: ProgInterventionScenarioEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const progScenariosToNormalize: { [id: number]: ProgScenarioEntityState } = {};

  const normalizedProgInterventionScenarios = progInterventionScenarios.map((progInterventionScenario: ProgInterventionScenarioEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      progInterventionScenario,
      progScenariosToNormalize,
      relations,
      'progScenario',
      'progInterventionScenarios',
      'idProgInterventionScenario',
      'idProgScenario'
    );

    return {
      ...progInterventionScenario,
      ...relations
    };
  });
  const progScenariosToNormalizeArray = Object.values(progScenariosToNormalize);
  if (progScenariosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgScenario(progScenariosToNormalizeArray, actionType));
  }

  const progInterventionScenarioActionCreator = ProgInterventionScenarioGeneratedActions.upsertManyProgInterventionScenariosSuccess;
  actions.push(progInterventionScenarioActionCreator({ progInterventionScenarios: normalizedProgInterventionScenarios }));

  return actions;
}

/**
 *  ProgPatrimoineInfo
 */
export function getActionsToNormalizeProgPatrimoineInfo(
  progPatrimoineInfos: ProgPatrimoineInfoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const progComposantsToNormalize: { [id: number]: ProgComposantEntityState } = {};
  const progEtatTechniquesToNormalize: { [id: number]: ProgEtatTechniqueEntityState } = {};

  const normalizedProgPatrimoineInfos = progPatrimoineInfos.map((progPatrimoineInfo: ProgPatrimoineInfoEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      progPatrimoineInfo,
      patrimoinesToNormalize,
      relations,
      'patrimoine',
      'progPatrimoineInfos',
      'idProgPatrimoineInfo',
      'idPatrimoine'
    );
    normalizeSingleElement(
      progPatrimoineInfo,
      progComposantsToNormalize,
      relations,
      'progComposant',
      'progPatrimoineInfos',
      'idProgPatrimoineInfo',
      'idProgComposant'
    );
    normalizeSingleElement(
      progPatrimoineInfo,
      progEtatTechniquesToNormalize,
      relations,
      'progEtatTechnique',
      'progPatrimoineInfos',
      'idProgPatrimoineInfo',
      'idProgEtatTechnique'
    );

    return {
      ...progPatrimoineInfo,
      ...relations
    };
  });
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  const progComposantsToNormalizeArray = Object.values(progComposantsToNormalize);
  if (progComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgComposant(progComposantsToNormalizeArray, actionType));
  }
  const progEtatTechniquesToNormalizeArray = Object.values(progEtatTechniquesToNormalize);
  if (progEtatTechniquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgEtatTechnique(progEtatTechniquesToNormalizeArray, actionType));
  }

  const progPatrimoineInfoActionCreator = ProgPatrimoineInfoGeneratedActions.upsertManyProgPatrimoineInfosSuccess;
  actions.push(progPatrimoineInfoActionCreator({ progPatrimoineInfos: normalizedProgPatrimoineInfos }));

  return actions;
}

/**
 *  ProgScenario
 */
export function getActionsToNormalizeProgScenario(
  progScenarios: ProgScenarioEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progInterventionScenariosToNormalize: ProgInterventionScenarioEntityState[] = [];
  const progBiblioInterventionScenariosToNormalize: ProgBiblioInterventionScenarioEntityState[] = [];
  const progBiblioInterventionsToNormalize: { [id: number]: ProgBiblioInterventionEntityState } = {};

  const normalizedProgScenarios = progScenarios.map((progScenario: ProgScenarioEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progScenario,
      progInterventionScenariosToNormalize,
      relations,
      'progInterventionScenarios',
      'progScenario',
      'idProgScenario',
      'idProgInterventionScenario'
    );
    normalizeMultipleElements(
      progScenario,
      progBiblioInterventionScenariosToNormalize,
      relations,
      'progBiblioInterventionScenarios',
      'progScenario',
      'idProgScenario',
      'idProgBiblioInterventionScenario'
    );
    normalizeMultipleElementsDict(
      progScenario,
      progBiblioInterventionsToNormalize,
      relations,
      'progBiblioInterventions',
      'progScenarios',
      'idProgScenario',
      'idProgBiblioIntervention'
    );
    normalizeSingleElement(
      progScenario,
      societesToNormalize,
      relations,
      'societe',
      'progScenarios',
      'idProgScenario',
      'idSociete'
    );

    return {
      ...progScenario,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (progInterventionScenariosToNormalize.length) {
    actions.push(...getActionsToNormalizeProgInterventionScenario(progInterventionScenariosToNormalize, actionType));
  }
  if (progBiblioInterventionScenariosToNormalize.length) {
    actions.push(...getActionsToNormalizeProgBiblioInterventionScenario(progBiblioInterventionScenariosToNormalize, actionType));
  }
  const progBiblioInterventionsToNormalizeArray = Object.values(progBiblioInterventionsToNormalize);
  if (progBiblioInterventionsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProgBiblioIntervention(progBiblioInterventionsToNormalizeArray, actionType));
  }

  const progScenarioActionCreator = ProgScenarioGeneratedActions.upsertManyProgScenariosSuccess;
  actions.push(progScenarioActionCreator({ progScenarios: normalizedProgScenarios }));

  return actions;
}

/**
 *  ProgUnite
 */
export function getActionsToNormalizeProgUnite(
  progUnites: ProgUniteEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const progBiblioInterventionsToNormalize: ProgBiblioInterventionEntityState[] = [];

  const normalizedProgUnites = progUnites.map((progUnite: ProgUniteEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      progUnite,
      progBiblioInterventionsToNormalize,
      relations,
      'progBiblioInterventions',
      'progUnite',
      'idProgUnite',
      'idProgBiblioIntervention'
    );
    normalizeSingleElement(
      progUnite,
      societesToNormalize,
      relations,
      'societe',
      'progUnites',
      'idProgUnite',
      'idSociete'
    );

    return {
      ...progUnite,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (progBiblioInterventionsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgBiblioIntervention(progBiblioInterventionsToNormalize, actionType));
  }

  const progUniteActionCreator = ProgUniteGeneratedActions.upsertManyProgUnitesSuccess;
  actions.push(progUniteActionCreator({ progUnites: normalizedProgUnites }));

  return actions;
}

/**
 *  Societe
 */
export function getActionsToNormalizeSociete(
  societes: SocieteEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];
  const societePatrimoineHierarchiesToNormalize: SocietePatrimoineHierarchieEntityState[] = [];
  const societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[] = [];
  const societeComposantFamillesToNormalize: SocieteComposantFamilleEntityState[] = [];
  const societeEspaceFamillesToNormalize: SocieteEspaceFamilleEntityState[] = [];
  const campagnesToNormalize: CampagneEntityState[] = [];
  const societeTerritoiresToNormalize: SocieteTerritoireEntityState[] = [];
  const societeProfilsToNormalize: SocieteProfilEntityState[] = [];
  const patrimoinesToNormalize: PatrimoineEntityState[] = [];
  const societeConfigAnalyseSynthesesToNormalize: SocieteConfigAnalyseSyntheseEntityState[] = [];
  const composantGroupesToNormalize: ComposantGroupeEntityState[] = [];
  const progComposantsToNormalize: ProgComposantEntityState[] = [];
  const progComposantGroupesToNormalize: ProgComposantGroupeEntityState[] = [];
  const progComposantSousGroupesToNormalize: ProgComposantSousGroupeEntityState[] = [];
  const progBiblioInterventionsToNormalize: ProgBiblioInterventionEntityState[] = [];
  const progBudgetsToNormalize: ProgBudgetEntityState[] = [];
  const progUnitesToNormalize: ProgUniteEntityState[] = [];
  const progInterventionFamillesToNormalize: ProgInterventionFamilleEntityState[] = [];
  const progScenariosToNormalize: ProgScenarioEntityState[] = [];
  const progEtatTechniquesToNormalize: ProgEtatTechniqueEntityState[] = [];

  const normalizedSocietes = societes.map((societe: SocieteEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societe,
      societeComposantsToNormalize,
      relations,
      'societeComposants',
      'societe',
      'idSociete',
      'idSocieteComposant'
    );
    normalizeMultipleElements(
      societe,
      societePatrimoineHierarchiesToNormalize,
      relations,
      'societePatrimoineHierarchies',
      'societe',
      'idSociete',
      'idSocietePatrimoineHierarchie'
    );
    normalizeMultipleElements(
      societe,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristiques',
      'societe',
      'idSociete',
      'idSocieteCaracteristique'
    );
    normalizeMultipleElements(
      societe,
      societeComposantFamillesToNormalize,
      relations,
      'societeComposantFamilles',
      'societe',
      'idSociete',
      'idSocieteComposantFamille'
    );
    normalizeMultipleElements(
      societe,
      societeEspaceFamillesToNormalize,
      relations,
      'societeEspaceFamilles',
      'societe',
      'idSociete',
      'idSocieteEspaceFamille'
    );
    normalizeMultipleElements(
      societe,
      campagnesToNormalize,
      relations,
      'campagnes',
      'societe',
      'idSociete',
      'idCampagne'
    );
    normalizeMultipleElements(
      societe,
      societeTerritoiresToNormalize,
      relations,
      'societeTerritoires',
      'societe',
      'idSociete',
      'idSocieteTerritoire'
    );
    normalizeMultipleElements(
      societe,
      societeProfilsToNormalize,
      relations,
      'societeProfils',
      'societe',
      'idSociete',
      'idSocieteProfil'
    );
    normalizeMultipleElements(
      societe,
      patrimoinesToNormalize,
      relations,
      'patrimoines',
      'societe',
      'idSociete',
      'idPatrimoine'
    );
    normalizeMultipleElements(
      societe,
      societeConfigAnalyseSynthesesToNormalize,
      relations,
      'societeConfigAnalyseSyntheses',
      'societe',
      'idSociete',
      'idSocieteConfigAnalyseSynthese'
    );
    normalizeMultipleElements(
      societe,
      composantGroupesToNormalize,
      relations,
      'composantGroupes',
      'societe',
      'idSociete',
      'idComposantGroupe'
    );
    normalizeMultipleElements(
      societe,
      progComposantsToNormalize,
      relations,
      'progComposants',
      'societe',
      'idSociete',
      'idProgComposant'
    );
    normalizeMultipleElements(
      societe,
      progComposantGroupesToNormalize,
      relations,
      'progComposantGroupes',
      'societe',
      'idSociete',
      'idProgComposantGroupe'
    );
    normalizeMultipleElements(
      societe,
      progComposantSousGroupesToNormalize,
      relations,
      'progComposantSousGroupes',
      'societe',
      'idSociete',
      'idProgComposantSousGroupe'
    );
    normalizeMultipleElements(
      societe,
      progBiblioInterventionsToNormalize,
      relations,
      'progBiblioInterventions',
      'societe',
      'idSociete',
      'idProgBiblioIntervention'
    );
    normalizeMultipleElements(
      societe,
      progBudgetsToNormalize,
      relations,
      'progBudgets',
      'societe',
      'idSociete',
      'idProgBudget'
    );
    normalizeMultipleElements(
      societe,
      progUnitesToNormalize,
      relations,
      'progUnites',
      'societe',
      'idSociete',
      'idProgUnite'
    );
    normalizeMultipleElements(
      societe,
      progInterventionFamillesToNormalize,
      relations,
      'progInterventionFamilles',
      'societe',
      'idSociete',
      'idProgInterventionFamille'
    );
    normalizeMultipleElements(
      societe,
      progScenariosToNormalize,
      relations,
      'progScenarios',
      'societe',
      'idSociete',
      'idProgScenario'
    );
    normalizeMultipleElements(
      societe,
      progEtatTechniquesToNormalize,
      relations,
      'progEtatTechniques',
      'societe',
      'idSociete',
      'idProgEtatTechnique'
    );
    normalizeSingleElement(
      societe,
      organisationsToNormalize,
      relations,
      'organisation',
      'societes',
      'idSociete',
      'idOrganisation'
    );

    return {
      ...societe,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }
  if (societePatrimoineHierarchiesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalize, actionType));
  }
  if (societeCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalize, actionType));
  }
  if (societeComposantFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantFamille(societeComposantFamillesToNormalize, actionType));
  }
  if (societeEspaceFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamillesToNormalize, actionType));
  }
  if (campagnesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalize, actionType));
  }
  if (societeTerritoiresToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoire(societeTerritoiresToNormalize, actionType));
  }
  if (societeProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalize, actionType));
  }
  if (patrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalize, actionType));
  }
  if (societeConfigAnalyseSynthesesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthesesToNormalize, actionType));
  }
  if (composantGroupesToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantGroupe(composantGroupesToNormalize, actionType));
  }
  if (progComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgComposant(progComposantsToNormalize, actionType));
  }
  if (progComposantGroupesToNormalize.length) {
    actions.push(...getActionsToNormalizeProgComposantGroupe(progComposantGroupesToNormalize, actionType));
  }
  if (progComposantSousGroupesToNormalize.length) {
    actions.push(...getActionsToNormalizeProgComposantSousGroupe(progComposantSousGroupesToNormalize, actionType));
  }
  if (progBiblioInterventionsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgBiblioIntervention(progBiblioInterventionsToNormalize, actionType));
  }
  if (progBudgetsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgBudget(progBudgetsToNormalize, actionType));
  }
  if (progUnitesToNormalize.length) {
    actions.push(...getActionsToNormalizeProgUnite(progUnitesToNormalize, actionType));
  }
  if (progInterventionFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeProgInterventionFamille(progInterventionFamillesToNormalize, actionType));
  }
  if (progScenariosToNormalize.length) {
    actions.push(...getActionsToNormalizeProgScenario(progScenariosToNormalize, actionType));
  }
  if (progEtatTechniquesToNormalize.length) {
    actions.push(...getActionsToNormalizeProgEtatTechnique(progEtatTechniquesToNormalize, actionType));
  }

  const societeActionCreator = SocieteGeneratedActions.upsertManySocietesSuccess;
  actions.push(societeActionCreator({ societes: normalizedSocietes }));

  return actions;
}

/**
 *  SocieteCaracteristique
 */
export function getActionsToNormalizeSocieteCaracteristique(
  societeCaracteristiques: SocieteCaracteristiqueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const caracteristiqueTemplatesToNormalize: { [id: number]: CaracteristiqueTemplateEntityState } = {};
  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};
  const societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[] = [];
  const valeursToNormalize: ValeurEntityState[] = [];
  const campagneSocieteCaracteristiquesToNormalize: CampagneSocieteCaracteristiqueEntityState[] = [];
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[] = [];
  const societeConfigAnalyseSynthesesToNormalize: { [id: number]: SocieteConfigAnalyseSyntheseEntityState } = {};

  const normalizedSocieteCaracteristiques = societeCaracteristiques.map((societeCaracteristique: SocieteCaracteristiqueEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societeCaracteristique,
      societeCaracteristiqueChoicesToNormalize,
      relations,
      'societeCaracteristiqueChoices',
      'societeCaracteristique',
      'idSocieteCaracteristique',
      'idSocieteCaracteristiqueChoix'
    );
    normalizeMultipleElements(
      societeCaracteristique,
      valeursToNormalize,
      relations,
      'valeurs',
      'societeCaracteristique',
      'idSocieteCaracteristique',
      'idValeur'
    );
    normalizeMultipleElements(
      societeCaracteristique,
      campagneSocieteCaracteristiquesToNormalize,
      relations,
      'campagneSocieteCaracteristiques',
      'societeCaracteristique',
      'idSocieteCaracteristique',
      'idCampagneSocieteCaracteristique'
    );
    normalizeMultipleElementsDict(
      societeCaracteristique,
      campagnesToNormalize,
      relations,
      'campagnes',
      'societeCaracteristiques',
      'idSocieteCaracteristique',
      'idCampagne'
    );
    normalizeMultipleElements(
      societeCaracteristique,
      societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize,
      relations,
      'societeConfigAnalyseSyntheseSocieteCaracteristiques',
      'societeCaracteristique',
      'idSocieteCaracteristique',
      'idSocieteConfigAnalyseSyntheseSocieteCaracteristique'
    );
    normalizeMultipleElementsDict(
      societeCaracteristique,
      societeConfigAnalyseSynthesesToNormalize,
      relations,
      'societeConfigAnalyseSyntheses',
      'societeCaracteristiques',
      'idSocieteCaracteristique',
      'idSocieteConfigAnalyseSynthese'
    );
    normalizeSingleElement(
      societeCaracteristique,
      societesToNormalize,
      relations,
      'societe',
      'societeCaracteristiques',
      'idSocieteCaracteristique',
      'idSociete'
    );
    normalizeSingleElement(
      societeCaracteristique,
      caracteristiqueTemplatesToNormalize,
      relations,
      'caracteristiqueTemplate',
      'societeCaracteristiques',
      'idSocieteCaracteristique',
      'idCaracteristiqueTemplate'
    );
    normalizeSingleElement(
      societeCaracteristique,
      societeComposantsToNormalize,
      relations,
      'societeComposant',
      'societeCaracteristiques',
      'idSocieteCaracteristique',
      'idSocieteComposant'
    );
    normalizeSingleElement(
      societeCaracteristique,
      societePatrimoineHierarchiesToNormalize,
      relations,
      'societePatrimoineHierarchie',
      'societeCaracteristiques',
      'idSocieteCaracteristique',
      'idSocietePatrimoineHierarchie'
    );

    return {
      ...societeCaracteristique,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const caracteristiqueTemplatesToNormalizeArray = Object.values(caracteristiqueTemplatesToNormalize);
  if (caracteristiqueTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalizeArray, actionType));
  }
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  const societePatrimoineHierarchiesToNormalizeArray = Object.values(societePatrimoineHierarchiesToNormalize);
  if (societePatrimoineHierarchiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalizeArray, actionType));
  }
  if (societeCaracteristiqueChoicesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoicesToNormalize, actionType));
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }
  if (campagneSocieteCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagneSocieteCaracteristique(campagneSocieteCaracteristiquesToNormalize, actionType));
  }
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  if (societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize, actionType));
  }
  const societeConfigAnalyseSynthesesToNormalizeArray = Object.values(societeConfigAnalyseSynthesesToNormalize);
  if (societeConfigAnalyseSynthesesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthesesToNormalizeArray, actionType));
  }

  const societeCaracteristiqueActionCreator = SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiquesSuccess;
  actions.push(societeCaracteristiqueActionCreator({ societeCaracteristiques: normalizedSocieteCaracteristiques }));

  return actions;
}

/**
 *  SocieteCaracteristiqueChoix
 */
export function getActionsToNormalizeSocieteCaracteristiqueChoix(
  societeCaracteristiqueChoices: SocieteCaracteristiqueChoixEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const caracteristiqueChoixTemplatesToNormalize: { [id: number]: CaracteristiqueChoixTemplateEntityState } = {};
  const valeursToNormalize: ValeurEntityState[] = [];

  const normalizedSocieteCaracteristiqueChoices = societeCaracteristiqueChoices.map((societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societeCaracteristiqueChoix,
      valeursToNormalize,
      relations,
      'valeurs',
      'societeCaracteristiqueChoix',
      'idSocieteCaracteristiqueChoix',
      'idValeur'
    );
    normalizeSingleElement(
      societeCaracteristiqueChoix,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristique',
      'societeCaracteristiqueChoices',
      'idSocieteCaracteristiqueChoix',
      'idSocieteCaracteristique'
    );
    normalizeSingleElement(
      societeCaracteristiqueChoix,
      fichiersToNormalize,
      relations,
      'fichier',
      'societeCaracteristiqueChoices',
      'idSocieteCaracteristiqueChoix',
      'idFichier'
    );
    normalizeSingleElement(
      societeCaracteristiqueChoix,
      caracteristiqueChoixTemplatesToNormalize,
      relations,
      'caracteristiqueChoixTemplate',
      'societeCaracteristiqueChoices',
      'idSocieteCaracteristiqueChoix',
      'idCaracteristiqueChoixTemplate'
    );

    return {
      ...societeCaracteristiqueChoix,
      ...relations
    };
  });
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  const caracteristiqueChoixTemplatesToNormalizeArray = Object.values(caracteristiqueChoixTemplatesToNormalize);
  if (caracteristiqueChoixTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueChoixTemplate(caracteristiqueChoixTemplatesToNormalizeArray, actionType));
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }

  const societeCaracteristiqueChoixActionCreator = SocieteCaracteristiqueChoixGeneratedActions.upsertManySocieteCaracteristiqueChoicesSuccess;
  actions.push(societeCaracteristiqueChoixActionCreator({ societeCaracteristiqueChoices: normalizedSocieteCaracteristiqueChoices }));

  return actions;
}

/**
 *  SocieteConfigAnalyseSynthese
 */
export function getActionsToNormalizeSocieteConfigAnalyseSynthese(
  societeConfigAnalyseSyntheses: SocieteConfigAnalyseSyntheseEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[] = [];
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};

  const normalizedSocieteConfigAnalyseSyntheses = societeConfigAnalyseSyntheses.map((societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societeConfigAnalyseSynthese,
      societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize,
      relations,
      'societeConfigAnalyseSyntheseSocieteCaracteristiques',
      'societeConfigAnalyseSynthese',
      'idSocieteConfigAnalyseSynthese',
      'idSocieteConfigAnalyseSyntheseSocieteCaracteristique'
    );
    normalizeMultipleElementsDict(
      societeConfigAnalyseSynthese,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristiques',
      'societeConfigAnalyseSyntheses',
      'idSocieteConfigAnalyseSynthese',
      'idSocieteCaracteristique'
    );
    normalizeSingleElement(
      societeConfigAnalyseSynthese,
      societesToNormalize,
      relations,
      'societe',
      'societeConfigAnalyseSyntheses',
      'idSocieteConfigAnalyseSynthese',
      'idSociete'
    );
    normalizeSingleElement(
      societeConfigAnalyseSynthese,
      usersToNormalize,
      relations,
      'user',
      'societeConfigAnalyseSyntheses',
      'idSocieteConfigAnalyseSynthese',
      'idUser'
    );

    return {
      ...societeConfigAnalyseSynthese,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }

  const societeConfigAnalyseSyntheseActionCreator = SocieteConfigAnalyseSyntheseGeneratedActions.upsertManySocieteConfigAnalyseSynthesesSuccess;
  actions.push(societeConfigAnalyseSyntheseActionCreator({ societeConfigAnalyseSyntheses: normalizedSocieteConfigAnalyseSyntheses }));

  return actions;
}

/**
 *  SocieteConfigAnalyseSyntheseSocieteCaracteristique
 */
export function getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(
  societeConfigAnalyseSyntheseSocieteCaracteristiques: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeConfigAnalyseSynthesesToNormalize: { [id: number]: SocieteConfigAnalyseSyntheseEntityState } = {};
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};

  const normalizedSocieteConfigAnalyseSyntheseSocieteCaracteristiques = societeConfigAnalyseSyntheseSocieteCaracteristiques.map((societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      societeConfigAnalyseSyntheseSocieteCaracteristique,
      societeConfigAnalyseSynthesesToNormalize,
      relations,
      'societeConfigAnalyseSynthese',
      'societeConfigAnalyseSyntheseSocieteCaracteristiques',
      'idSocieteConfigAnalyseSyntheseSocieteCaracteristique',
      'idSocieteConfigAnalyseSynthese'
    );
    normalizeSingleElement(
      societeConfigAnalyseSyntheseSocieteCaracteristique,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristique',
      'societeConfigAnalyseSyntheseSocieteCaracteristiques',
      'idSocieteConfigAnalyseSyntheseSocieteCaracteristique',
      'idSocieteCaracteristique'
    );

    return {
      ...societeConfigAnalyseSyntheseSocieteCaracteristique,
      ...relations
    };
  });
  const societeConfigAnalyseSynthesesToNormalizeArray = Object.values(societeConfigAnalyseSynthesesToNormalize);
  if (societeConfigAnalyseSynthesesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthesesToNormalizeArray, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }

  const societeConfigAnalyseSyntheseSocieteCaracteristiqueActionCreator = SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesSuccess;
  actions.push(societeConfigAnalyseSyntheseSocieteCaracteristiqueActionCreator({ societeConfigAnalyseSyntheseSocieteCaracteristiques: normalizedSocieteConfigAnalyseSyntheseSocieteCaracteristiques }));

  return actions;
}

/**
 *  SocieteComposant
 */
export function getActionsToNormalizeSocieteComposant(
  societeComposants: SocieteComposantEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const composantTemplatesToNormalize: { [id: number]: ComposantTemplateEntityState } = {};
  const societeComposantFamillesToNormalize: { [id: number]: SocieteComposantFamilleEntityState } = {};
  const composantGroupesToNormalize: { [id: number]: ComposantGroupeEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[] = [];
  const composantsToNormalize: ComposantEntityState[] = [];
  const composantAttendusToNormalize: ComposantAttenduEntityState[] = [];
  const societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[] = [];
  const progComposantsToNormalize: ProgComposantEntityState[] = [];

  const normalizedSocieteComposants = societeComposants.map((societeComposant: SocieteComposantEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societeComposant,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristiques',
      'societeComposant',
      'idSocieteComposant',
      'idSocieteCaracteristique'
    );
    normalizeMultipleElements(
      societeComposant,
      composantsToNormalize,
      relations,
      'composants',
      'societeComposant',
      'idSocieteComposant',
      'idComposant'
    );
    normalizeMultipleElements(
      societeComposant,
      composantAttendusToNormalize,
      relations,
      'composantAttendus',
      'societeComposant',
      'idSocieteComposant',
      'idComposantAttendu'
    );
    normalizeMultipleElements(
      societeComposant,
      societeComposantRattachementsToNormalize,
      relations,
      'societeComposantRattachements',
      'societeComposant',
      'idSocieteComposant',
      'idSocieteComposantRattachement'
    );
    normalizeMultipleElements(
      societeComposant,
      progComposantsToNormalize,
      relations,
      'progComposants',
      'societeComposant',
      'idSocieteComposant',
      'idProgComposant'
    );
    normalizeSingleElement(
      societeComposant,
      societesToNormalize,
      relations,
      'societe',
      'societeComposants',
      'idSocieteComposant',
      'idSociete'
    );
    normalizeSingleElement(
      societeComposant,
      composantTemplatesToNormalize,
      relations,
      'composantTemplate',
      'societeComposants',
      'idSocieteComposant',
      'idComposantTemplate'
    );
    normalizeSingleElement(
      societeComposant,
      societeComposantFamillesToNormalize,
      relations,
      'societeComposantFamille',
      'societeComposants',
      'idSocieteComposant',
      'idSocieteComposantFamille'
    );
    normalizeSingleElement(
      societeComposant,
      composantGroupesToNormalize,
      relations,
      'composantGroupe',
      'societeComposants',
      'idSocieteComposant',
      'idComposantGroupe'
    );
    normalizeSingleElement(
      societeComposant,
      fichiersToNormalize,
      relations,
      'fichier',
      'societeComposants',
      'idSocieteComposant',
      'idFichier'
    );

    return {
      ...societeComposant,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const composantTemplatesToNormalizeArray = Object.values(composantTemplatesToNormalize);
  if (composantTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalizeArray, actionType));
  }
  const societeComposantFamillesToNormalizeArray = Object.values(societeComposantFamillesToNormalize);
  if (societeComposantFamillesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposantFamille(societeComposantFamillesToNormalizeArray, actionType));
  }
  const composantGroupesToNormalizeArray = Object.values(composantGroupesToNormalize);
  if (composantGroupesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantGroupe(composantGroupesToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (societeCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalize, actionType));
  }
  if (composantsToNormalize.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalize, actionType));
  }
  if (composantAttendusToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantAttendu(composantAttendusToNormalize, actionType));
  }
  if (societeComposantRattachementsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantRattachement(societeComposantRattachementsToNormalize, actionType));
  }
  if (progComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeProgComposant(progComposantsToNormalize, actionType));
  }

  const societeComposantActionCreator = SocieteComposantGeneratedActions.upsertManySocieteComposantsSuccess;
  actions.push(societeComposantActionCreator({ societeComposants: normalizedSocieteComposants }));

  return actions;
}

/**
 *  SocieteComposantFamille
 */
export function getActionsToNormalizeSocieteComposantFamille(
  societeComposantFamilles: SocieteComposantFamilleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];

  const normalizedSocieteComposantFamilles = societeComposantFamilles.map((societeComposantFamille: SocieteComposantFamilleEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societeComposantFamille,
      societeComposantsToNormalize,
      relations,
      'societeComposants',
      'societeComposantFamille',
      'idSocieteComposantFamille',
      'idSocieteComposant'
    );
    normalizeSingleElement(
      societeComposantFamille,
      societesToNormalize,
      relations,
      'societe',
      'societeComposantFamilles',
      'idSocieteComposantFamille',
      'idSociete'
    );
    normalizeSingleElement(
      societeComposantFamille,
      fichiersToNormalize,
      relations,
      'fichier',
      'societeComposantFamilles',
      'idSocieteComposantFamille',
      'idFichier'
    );

    return {
      ...societeComposantFamille,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }

  const societeComposantFamilleActionCreator = SocieteComposantFamilleGeneratedActions.upsertManySocieteComposantFamillesSuccess;
  actions.push(societeComposantFamilleActionCreator({ societeComposantFamilles: normalizedSocieteComposantFamilles }));

  return actions;
}

/**
 *  SocieteComposantRattachement
 */
export function getActionsToNormalizeSocieteComposantRattachement(
  societeComposantRattachements: SocieteComposantRattachementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const societeEspaceFamillesToNormalize: { [id: number]: SocieteEspaceFamilleEntityState } = {};
  const societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};

  const normalizedSocieteComposantRattachements = societeComposantRattachements.map((societeComposantRattachement: SocieteComposantRattachementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      societeComposantRattachement,
      societeComposantsToNormalize,
      relations,
      'societeComposant',
      'societeComposantRattachements',
      'idSocieteComposantRattachement',
      'idSocieteComposant'
    );
    normalizeSingleElement(
      societeComposantRattachement,
      societeEspaceFamillesToNormalize,
      relations,
      'societeEspaceFamille',
      'societeComposantRattachements',
      'idSocieteComposantRattachement',
      'idSocieteEspaceFamille'
    );
    normalizeSingleElement(
      societeComposantRattachement,
      societePatrimoineHierarchiesToNormalize,
      relations,
      'societePatrimoineHierarchie',
      'societeComposantRattachements',
      'idSocieteComposantRattachement',
      'idSocietePatrimoineHierarchie'
    );

    return {
      ...societeComposantRattachement,
      ...relations
    };
  });
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  const societeEspaceFamillesToNormalizeArray = Object.values(societeEspaceFamillesToNormalize);
  if (societeEspaceFamillesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamillesToNormalizeArray, actionType));
  }
  const societePatrimoineHierarchiesToNormalizeArray = Object.values(societePatrimoineHierarchiesToNormalize);
  if (societePatrimoineHierarchiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalizeArray, actionType));
  }

  const societeComposantRattachementActionCreator = SocieteComposantRattachementGeneratedActions.upsertManySocieteComposantRattachementsSuccess;
  actions.push(societeComposantRattachementActionCreator({ societeComposantRattachements: normalizedSocieteComposantRattachements }));

  return actions;
}

/**
 *  SocieteEspace
 */
export function getActionsToNormalizeSocieteEspace(
  societeEspaces: SocieteEspaceEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeEspaceFamillesToNormalize: { [id: number]: SocieteEspaceFamilleEntityState } = {};
  const espacesToNormalize: EspaceEntityState[] = [];

  const normalizedSocieteEspaces = societeEspaces.map((societeEspace: SocieteEspaceEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societeEspace,
      espacesToNormalize,
      relations,
      'espaces',
      'societeEspace',
      'idSocieteEspace',
      'idEspace'
    );
    normalizeSingleElement(
      societeEspace,
      societeEspaceFamillesToNormalize,
      relations,
      'societeEspaceFamille',
      'societeEspaces',
      'idSocieteEspace',
      'idSocieteEspaceFamille'
    );

    return {
      ...societeEspace,
      ...relations
    };
  });
  const societeEspaceFamillesToNormalizeArray = Object.values(societeEspaceFamillesToNormalize);
  if (societeEspaceFamillesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamillesToNormalizeArray, actionType));
  }
  if (espacesToNormalize.length) {
    actions.push(...getActionsToNormalizeEspace(espacesToNormalize, actionType));
  }

  const societeEspaceActionCreator = SocieteEspaceGeneratedActions.upsertManySocieteEspacesSuccess;
  actions.push(societeEspaceActionCreator({ societeEspaces: normalizedSocieteEspaces }));

  return actions;
}

/**
 *  SocieteEspaceFamille
 */
export function getActionsToNormalizeSocieteEspaceFamille(
  societeEspaceFamilles: SocieteEspaceFamilleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};
  const societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[] = [];
  const societeEspacesToNormalize: SocieteEspaceEntityState[] = [];

  const normalizedSocieteEspaceFamilles = societeEspaceFamilles.map((societeEspaceFamille: SocieteEspaceFamilleEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societeEspaceFamille,
      societeComposantRattachementsToNormalize,
      relations,
      'societeComposantRattachements',
      'societeEspaceFamille',
      'idSocieteEspaceFamille',
      'idSocieteComposantRattachement'
    );
    normalizeMultipleElements(
      societeEspaceFamille,
      societeEspacesToNormalize,
      relations,
      'societeEspaces',
      'societeEspaceFamille',
      'idSocieteEspaceFamille',
      'idSocieteEspace'
    );
    normalizeSingleElement(
      societeEspaceFamille,
      societesToNormalize,
      relations,
      'societe',
      'societeEspaceFamilles',
      'idSocieteEspaceFamille',
      'idSociete'
    );
    normalizeSingleElement(
      societeEspaceFamille,
      societePatrimoineHierarchiesToNormalize,
      relations,
      'societePatrimoineHierarchie',
      'societeEspaceFamilles',
      'idSocieteEspaceFamille',
      'idSocietePatrimoineHierarchie'
    );

    return {
      ...societeEspaceFamille,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const societePatrimoineHierarchiesToNormalizeArray = Object.values(societePatrimoineHierarchiesToNormalize);
  if (societePatrimoineHierarchiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalizeArray, actionType));
  }
  if (societeComposantRattachementsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantRattachement(societeComposantRattachementsToNormalize, actionType));
  }
  if (societeEspacesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteEspace(societeEspacesToNormalize, actionType));
  }

  const societeEspaceFamilleActionCreator = SocieteEspaceFamilleGeneratedActions.upsertManySocieteEspaceFamillesSuccess;
  actions.push(societeEspaceFamilleActionCreator({ societeEspaceFamilles: normalizedSocieteEspaceFamilles }));

  return actions;
}

/**
 *  SocietePatrimoineHierarchie
 */
export function getActionsToNormalizeSocietePatrimoineHierarchie(
  societePatrimoineHierarchies: SocietePatrimoineHierarchieEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const patrimoineHierarchieTemplatesToNormalize: { [id: number]: PatrimoineHierarchieTemplateEntityState } = {};
  const societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[] = [];
  const patrimoinesToNormalize: PatrimoineEntityState[] = [];
  const hierarchieAncetresToNormalize: SocietePatrimoineHierarchieAncetreEntityState[] = [];
  const hierarchieDescendantsToNormalize: SocietePatrimoineHierarchieAncetreEntityState[] = [];
  const societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[] = [];
  const societeEspaceFamillesToNormalize: SocieteEspaceFamilleEntityState[] = [];

  const normalizedSocietePatrimoineHierarchies = societePatrimoineHierarchies.map((societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societePatrimoineHierarchie,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristiques',
      'societePatrimoineHierarchie',
      'idSocietePatrimoineHierarchie',
      'idSocieteCaracteristique'
    );
    normalizeMultipleElements(
      societePatrimoineHierarchie,
      patrimoinesToNormalize,
      relations,
      'patrimoines',
      'societePatrimoineHierarchie',
      'idSocietePatrimoineHierarchie',
      'idPatrimoine'
    );
    normalizeMultipleElements(
      societePatrimoineHierarchie,
      hierarchieAncetresToNormalize,
      relations,
      'hierarchieAncetres',
      'hierarchieDescendant',
      'idSocietePatrimoineHierarchie',
      'idSocietePatrimoineHierarchieAncetre'
    );
    normalizeMultipleElements(
      societePatrimoineHierarchie,
      hierarchieDescendantsToNormalize,
      relations,
      'hierarchieDescendants',
      'hierarchieAncetre',
      'idSocietePatrimoineHierarchie',
      'idSocietePatrimoineHierarchieAncetre'
    );
    normalizeMultipleElements(
      societePatrimoineHierarchie,
      societeComposantRattachementsToNormalize,
      relations,
      'societeComposantRattachements',
      'societePatrimoineHierarchie',
      'idSocietePatrimoineHierarchie',
      'idSocieteComposantRattachement'
    );
    normalizeMultipleElements(
      societePatrimoineHierarchie,
      societeEspaceFamillesToNormalize,
      relations,
      'societeEspaceFamilles',
      'societePatrimoineHierarchie',
      'idSocietePatrimoineHierarchie',
      'idSocieteEspaceFamille'
    );
    normalizeSingleElement(
      societePatrimoineHierarchie,
      societesToNormalize,
      relations,
      'societe',
      'societePatrimoineHierarchies',
      'idSocietePatrimoineHierarchie',
      'idSociete'
    );
    normalizeSingleElement(
      societePatrimoineHierarchie,
      patrimoineHierarchieTemplatesToNormalize,
      relations,
      'patrimoineHierarchieTemplate',
      'societePatrimoineHierarchies',
      'idSocietePatrimoineHierarchie',
      'idPatrimoineHierarchieTemplate'
    );

    return {
      ...societePatrimoineHierarchie,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const patrimoineHierarchieTemplatesToNormalizeArray = Object.values(patrimoineHierarchieTemplatesToNormalize);
  if (patrimoineHierarchieTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoineHierarchieTemplate(patrimoineHierarchieTemplatesToNormalizeArray, actionType));
  }
  if (societeCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalize, actionType));
  }
  if (patrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalize, actionType));
  }
  if (hierarchieAncetresToNormalize.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchieAncetre(hierarchieAncetresToNormalize, actionType));
  }
  if (hierarchieDescendantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchieAncetre(hierarchieDescendantsToNormalize, actionType));
  }
  if (societeComposantRattachementsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantRattachement(societeComposantRattachementsToNormalize, actionType));
  }
  if (societeEspaceFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamillesToNormalize, actionType));
  }

  const societePatrimoineHierarchieActionCreator = SocietePatrimoineHierarchieGeneratedActions.upsertManySocietePatrimoineHierarchiesSuccess;
  actions.push(societePatrimoineHierarchieActionCreator({ societePatrimoineHierarchies: normalizedSocietePatrimoineHierarchies }));

  return actions;
}

/**
 *  SocietePatrimoineHierarchieAncetre
 */
export function getActionsToNormalizeSocietePatrimoineHierarchieAncetre(
  societePatrimoineHierarchieAncetres: SocietePatrimoineHierarchieAncetreEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const hierarchieAncetreToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};
  const hierarchieDescendantToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};

  const normalizedSocietePatrimoineHierarchieAncetres = societePatrimoineHierarchieAncetres.map((societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      societePatrimoineHierarchieAncetre,
      hierarchieAncetreToNormalize,
      relations,
      'hierarchieAncetre',
      'hierarchieAncetres',
      'idSocietePatrimoineHierarchieAncetre',
      'idSocietePatrimoineHierarchie'
    );
    normalizeSingleElement(
      societePatrimoineHierarchieAncetre,
      hierarchieDescendantToNormalize,
      relations,
      'hierarchieDescendant',
      'hierarchieDescendants',
      'idSocietePatrimoineHierarchieAncetre',
      'idSocietePatrimoineHierarchie'
    );

    return {
      ...societePatrimoineHierarchieAncetre,
      ...relations
    };
  });
  const hierarchieAncetreToNormalizeArray = Object.values(hierarchieAncetreToNormalize);
  if (hierarchieAncetreToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(hierarchieAncetreToNormalizeArray, actionType));
  }
  const hierarchieDescendantToNormalizeArray = Object.values(hierarchieDescendantToNormalize);
  if (hierarchieDescendantToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(hierarchieDescendantToNormalizeArray, actionType));
  }

  const societePatrimoineHierarchieAncetreActionCreator = SocietePatrimoineHierarchieAncetreGeneratedActions.upsertManySocietePatrimoineHierarchieAncetresSuccess;
  actions.push(societePatrimoineHierarchieAncetreActionCreator({ societePatrimoineHierarchieAncetres: normalizedSocietePatrimoineHierarchieAncetres }));

  return actions;
}

/**
 *  SocieteProfil
 */
export function getActionsToNormalizeSocieteProfil(
  societeProfils: SocieteProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const userSocieteProfilsToNormalize: UserSocieteProfilEntityState[] = [];
  const societeProfilDroitsToNormalize: SocieteProfilDroitEntityState[] = [];
  const droitsToNormalize: { [id: number]: DroitEntityState } = {};

  const normalizedSocieteProfils = societeProfils.map((societeProfil: SocieteProfilEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElementsDict(
      societeProfil,
      usersToNormalize,
      relations,
      'users',
      'societeProfils',
      'idSocieteProfil',
      'idUser'
    );
    normalizeMultipleElements(
      societeProfil,
      userSocieteProfilsToNormalize,
      relations,
      'userSocieteProfils',
      'societeProfil',
      'idSocieteProfil',
      'idUserSocieteProfil'
    );
    normalizeMultipleElements(
      societeProfil,
      societeProfilDroitsToNormalize,
      relations,
      'societeProfilDroits',
      'societeProfil',
      'idSocieteProfil',
      'idSocieteProfilDroit'
    );
    normalizeMultipleElementsDict(
      societeProfil,
      droitsToNormalize,
      relations,
      'droits',
      'societeProfils',
      'idSocieteProfil',
      'idDroit'
    );
    normalizeSingleElement(
      societeProfil,
      societesToNormalize,
      relations,
      'societe',
      'societeProfils',
      'idSocieteProfil',
      'idSociete'
    );

    return {
      ...societeProfil,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (userSocieteProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserSocieteProfil(userSocieteProfilsToNormalize, actionType));
  }
  if (societeProfilDroitsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteProfilDroit(societeProfilDroitsToNormalize, actionType));
  }
  const droitsToNormalizeArray = Object.values(droitsToNormalize);
  if (droitsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeDroit(droitsToNormalizeArray, actionType));
  }

  const societeProfilActionCreator = SocieteProfilGeneratedActions.upsertManySocieteProfilsSuccess;
  actions.push(societeProfilActionCreator({ societeProfils: normalizedSocieteProfils }));

  return actions;
}

/**
 *  SocieteProfilDroit
 */
export function getActionsToNormalizeSocieteProfilDroit(
  societeProfilDroits: SocieteProfilDroitEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState } = {};
  const droitsToNormalize: { [id: number]: DroitEntityState } = {};

  const normalizedSocieteProfilDroits = societeProfilDroits.map((societeProfilDroit: SocieteProfilDroitEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      societeProfilDroit,
      societeProfilsToNormalize,
      relations,
      'societeProfil',
      'societeProfilDroits',
      'idSocieteProfilDroit',
      'idSocieteProfil'
    );
    normalizeSingleElement(
      societeProfilDroit,
      droitsToNormalize,
      relations,
      'droit',
      'societeProfilDroits',
      'idSocieteProfilDroit',
      'idDroit'
    );

    return {
      ...societeProfilDroit,
      ...relations
    };
  });
  const societeProfilsToNormalizeArray = Object.values(societeProfilsToNormalize);
  if (societeProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalizeArray, actionType));
  }
  const droitsToNormalizeArray = Object.values(droitsToNormalize);
  if (droitsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeDroit(droitsToNormalizeArray, actionType));
  }

  const societeProfilDroitActionCreator = SocieteProfilDroitGeneratedActions.upsertManySocieteProfilDroitsSuccess;
  actions.push(societeProfilDroitActionCreator({ societeProfilDroits: normalizedSocieteProfilDroits }));

  return actions;
}

/**
 *  SocieteTerritoire
 */
export function getActionsToNormalizeSocieteTerritoire(
  societeTerritoires: SocieteTerritoireEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const societeTerritoirePatrimoinesToNormalize: SocieteTerritoirePatrimoineEntityState[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const societeTerritoireUsersToNormalize: SocieteTerritoireUserEntityState[] = [];

  const normalizedSocieteTerritoires = societeTerritoires.map((societeTerritoire: SocieteTerritoireEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      societeTerritoire,
      societeTerritoirePatrimoinesToNormalize,
      relations,
      'societeTerritoirePatrimoines',
      'societeTerritoire',
      'idSocieteTerritoire',
      'idSocieteTerritoirePatrimoine'
    );
    normalizeMultipleElementsDict(
      societeTerritoire,
      patrimoinesToNormalize,
      relations,
      'patrimoines',
      'societeTerritoires',
      'idSocieteTerritoire',
      'idPatrimoine'
    );
    normalizeMultipleElements(
      societeTerritoire,
      societeTerritoireUsersToNormalize,
      relations,
      'societeTerritoireUsers',
      'societeTerritoire',
      'idSocieteTerritoire',
      'idSocieteTerritoireUser'
    );
    normalizeSingleElement(
      societeTerritoire,
      societesToNormalize,
      relations,
      'societe',
      'societeTerritoires',
      'idSocieteTerritoire',
      'idSociete'
    );

    return {
      ...societeTerritoire,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (societeTerritoirePatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoirePatrimoine(societeTerritoirePatrimoinesToNormalize, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  if (societeTerritoireUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoireUser(societeTerritoireUsersToNormalize, actionType));
  }

  const societeTerritoireActionCreator = SocieteTerritoireGeneratedActions.upsertManySocieteTerritoiresSuccess;
  actions.push(societeTerritoireActionCreator({ societeTerritoires: normalizedSocieteTerritoires }));

  return actions;
}

/**
 *  SocieteTerritoirePatrimoine
 */
export function getActionsToNormalizeSocieteTerritoirePatrimoine(
  societeTerritoirePatrimoines: SocieteTerritoirePatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedSocieteTerritoirePatrimoines = societeTerritoirePatrimoines.map((societeTerritoirePatrimoine: SocieteTerritoirePatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      societeTerritoirePatrimoine,
      societeTerritoiresToNormalize,
      relations,
      'societeTerritoire',
      'societeTerritoirePatrimoines',
      'idSocieteTerritoirePatrimoine',
      'idSocieteTerritoire'
    );
    normalizeSingleElement(
      societeTerritoirePatrimoine,
      patrimoinesToNormalize,
      relations,
      'patrimoine',
      'societeTerritoirePatrimoines',
      'idSocieteTerritoirePatrimoine',
      'idPatrimoine'
    );

    return {
      ...societeTerritoirePatrimoine,
      ...relations
    };
  });
  const societeTerritoiresToNormalizeArray = Object.values(societeTerritoiresToNormalize);
  if (societeTerritoiresToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoire(societeTerritoiresToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const societeTerritoirePatrimoineActionCreator = SocieteTerritoirePatrimoineGeneratedActions.upsertManySocieteTerritoirePatrimoinesSuccess;
  actions.push(societeTerritoirePatrimoineActionCreator({ societeTerritoirePatrimoines: normalizedSocieteTerritoirePatrimoines }));

  return actions;
}

/**
 *  SocieteTerritoireUser
 */
export function getActionsToNormalizeSocieteTerritoireUser(
  societeTerritoireUsers: SocieteTerritoireUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedSocieteTerritoireUsers = societeTerritoireUsers.map((societeTerritoireUser: SocieteTerritoireUserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      societeTerritoireUser,
      societeTerritoiresToNormalize,
      relations,
      'societeTerritoire',
      'societeTerritoireUsers',
      'idSocieteTerritoireUser',
      'idSocieteTerritoire'
    );
    normalizeSingleElement(
      societeTerritoireUser,
      usersToNormalize,
      relations,
      'user',
      'societeTerritoireUsers',
      'idSocieteTerritoireUser',
      'idUser'
    );

    return {
      ...societeTerritoireUser,
      ...relations
    };
  });
  const societeTerritoiresToNormalizeArray = Object.values(societeTerritoiresToNormalize);
  if (societeTerritoiresToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoire(societeTerritoiresToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const societeTerritoireUserActionCreator = SocieteTerritoireUserGeneratedActions.upsertManySocieteTerritoireUsersSuccess;
  actions.push(societeTerritoireUserActionCreator({ societeTerritoireUsers: normalizedSocieteTerritoireUsers }));

  return actions;
}

/**
 *  Usage
 */
export function getActionsToNormalizeUsage(
  usages: UsageEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const usageComposantTemplatesToNormalize: UsageComposantTemplateEntityState[] = [];
  const usageCaracteristiqueTemplatesToNormalize: UsageCaracteristiqueTemplateEntityState[] = [];
  const composantTemplatesToNormalize: { [id: number]: ComposantTemplateEntityState } = {};
  const caracteristiqueTemplatesToNormalize: { [id: number]: CaracteristiqueTemplateEntityState } = {};

  const normalizedUsages = usages.map((usage: UsageEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      usage,
      usageComposantTemplatesToNormalize,
      relations,
      'usageComposantTemplates',
      'usage',
      'idUsage',
      'idUsageComposantTemplate'
    );
    normalizeMultipleElements(
      usage,
      usageCaracteristiqueTemplatesToNormalize,
      relations,
      'usageCaracteristiqueTemplates',
      'usage',
      'idUsage',
      'idUsageCaracteristiqueTemplate'
    );
    normalizeMultipleElementsDict(
      usage,
      composantTemplatesToNormalize,
      relations,
      'composantTemplates',
      'usages',
      'idUsage',
      'idComposantTemplate'
    );
    normalizeMultipleElementsDict(
      usage,
      caracteristiqueTemplatesToNormalize,
      relations,
      'caracteristiqueTemplates',
      'usages',
      'idUsage',
      'idCaracteristiqueTemplate'
    );

    return {
      ...usage,
      ...relations
    };
  });
  if (usageComposantTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeUsageComposantTemplate(usageComposantTemplatesToNormalize, actionType));
  }
  if (usageCaracteristiqueTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeUsageCaracteristiqueTemplate(usageCaracteristiqueTemplatesToNormalize, actionType));
  }
  const composantTemplatesToNormalizeArray = Object.values(composantTemplatesToNormalize);
  if (composantTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalizeArray, actionType));
  }
  const caracteristiqueTemplatesToNormalizeArray = Object.values(caracteristiqueTemplatesToNormalize);
  if (caracteristiqueTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalizeArray, actionType));
  }

  const usageActionCreator = UsageGeneratedActions.upsertManyUsagesSuccess;
  actions.push(usageActionCreator({ usages: normalizedUsages }));

  return actions;
}

/**
 *  UsageComposantTemplate
 */
export function getActionsToNormalizeUsageComposantTemplate(
  usageComposantTemplates: UsageComposantTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const usagesToNormalize: { [id: number]: UsageEntityState } = {};
  const composantTemplatesToNormalize: { [id: number]: ComposantTemplateEntityState } = {};

  const normalizedUsageComposantTemplates = usageComposantTemplates.map((usageComposantTemplate: UsageComposantTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      usageComposantTemplate,
      usagesToNormalize,
      relations,
      'usage',
      'usageComposantTemplates',
      'idUsageComposantTemplate',
      'idUsage'
    );
    normalizeSingleElement(
      usageComposantTemplate,
      composantTemplatesToNormalize,
      relations,
      'composantTemplate',
      'usageComposantTemplates',
      'idUsageComposantTemplate',
      'idComposantTemplate'
    );

    return {
      ...usageComposantTemplate,
      ...relations
    };
  });
  const usagesToNormalizeArray = Object.values(usagesToNormalize);
  if (usagesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUsage(usagesToNormalizeArray, actionType));
  }
  const composantTemplatesToNormalizeArray = Object.values(composantTemplatesToNormalize);
  if (composantTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalizeArray, actionType));
  }

  const usageComposantTemplateActionCreator = UsageComposantTemplateGeneratedActions.upsertManyUsageComposantTemplatesSuccess;
  actions.push(usageComposantTemplateActionCreator({ usageComposantTemplates: normalizedUsageComposantTemplates }));

  return actions;
}

/**
 *  UsageCaracteristiqueTemplate
 */
export function getActionsToNormalizeUsageCaracteristiqueTemplate(
  usageCaracteristiqueTemplates: UsageCaracteristiqueTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const usagesToNormalize: { [id: number]: UsageEntityState } = {};
  const caracteristiqueTemplatesToNormalize: { [id: number]: CaracteristiqueTemplateEntityState } = {};

  const normalizedUsageCaracteristiqueTemplates = usageCaracteristiqueTemplates.map((usageCaracteristiqueTemplate: UsageCaracteristiqueTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      usageCaracteristiqueTemplate,
      usagesToNormalize,
      relations,
      'usage',
      'usageCaracteristiqueTemplates',
      'idUsageCaracteristiqueTemplate',
      'idUsage'
    );
    normalizeSingleElement(
      usageCaracteristiqueTemplate,
      caracteristiqueTemplatesToNormalize,
      relations,
      'caracteristiqueTemplate',
      'usageCaracteristiqueTemplates',
      'idUsageCaracteristiqueTemplate',
      'idCaracteristiqueTemplate'
    );

    return {
      ...usageCaracteristiqueTemplate,
      ...relations
    };
  });
  const usagesToNormalizeArray = Object.values(usagesToNormalize);
  if (usagesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUsage(usagesToNormalizeArray, actionType));
  }
  const caracteristiqueTemplatesToNormalizeArray = Object.values(caracteristiqueTemplatesToNormalize);
  if (caracteristiqueTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalizeArray, actionType));
  }

  const usageCaracteristiqueTemplateActionCreator = UsageCaracteristiqueTemplateGeneratedActions.upsertManyUsageCaracteristiqueTemplatesSuccess;
  actions.push(usageCaracteristiqueTemplateActionCreator({ usageCaracteristiqueTemplates: normalizedUsageCaracteristiqueTemplates }));

  return actions;
}

/**
 *  User
 */
export function getActionsToNormalizeUser(
  users: UserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};
  const societeTerritoireUsersToNormalize: SocieteTerritoireUserEntityState[] = [];
  const campagneUsersToNormalize: CampagneUserEntityState[] = [];
  const composantAttendusToNormalize: ComposantAttenduEntityState[] = [];
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const userSocieteProfilsToNormalize: UserSocieteProfilEntityState[] = [];
  const societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState } = {};
  const userPatrimoinesToNormalize: UserPatrimoineEntityState[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const composantsToNormalize: ComposantEntityState[] = [];
  const valeursToNormalize: ValeurEntityState[] = [];
  const userNewslettersToNormalize: UserNewsletterEntityState[] = [];
  const newslettersToNormalize: { [id: number]: NewsletterEntityState } = {};
  const societeConfigAnalyseSynthesesToNormalize: SocieteConfigAnalyseSyntheseEntityState[] = [];
  const boardStatesToNormalize: BoardStateEntityState[] = [];

  const normalizedUsers = users.map((user: UserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      user,
      societeTerritoireUsersToNormalize,
      relations,
      'societeTerritoireUsers',
      'user',
      'idUser',
      'idSocieteTerritoireUser'
    );
    normalizeMultipleElements(
      user,
      campagneUsersToNormalize,
      relations,
      'campagneUsers',
      'user',
      'idUser',
      'idCampagneUser'
    );
    normalizeMultipleElements(
      user,
      composantAttendusToNormalize,
      relations,
      'composantAttendus',
      'user',
      'idUser',
      'idComposantAttendu'
    );
    normalizeMultipleElementsDict(
      user,
      campagnesToNormalize,
      relations,
      'campagnes',
      'users',
      'idUser',
      'idCampagne'
    );
    normalizeMultipleElements(
      user,
      userSocieteProfilsToNormalize,
      relations,
      'userSocieteProfils',
      'user',
      'idUser',
      'idUserSocieteProfil'
    );
    normalizeMultipleElementsDict(
      user,
      societeProfilsToNormalize,
      relations,
      'societeProfils',
      'users',
      'idUser',
      'idSocieteProfil'
    );
    normalizeMultipleElements(
      user,
      userPatrimoinesToNormalize,
      relations,
      'userPatrimoines',
      'user',
      'idUser',
      'idUserPatrimoine'
    );
    normalizeMultipleElementsDict(
      user,
      patrimoinesToNormalize,
      relations,
      'patrimoines',
      'users',
      'idUser',
      'idPatrimoine'
    );
    normalizeMultipleElements(
      user,
      composantsToNormalize,
      relations,
      'composants',
      'user',
      'idUser',
      'idComposant'
    );
    normalizeMultipleElements(
      user,
      valeursToNormalize,
      relations,
      'valeurs',
      'user',
      'idUser',
      'idValeur'
    );
    normalizeMultipleElements(
      user,
      userNewslettersToNormalize,
      relations,
      'userNewsletters',
      'user',
      'idUser',
      'idUserNewsletter'
    );
    normalizeMultipleElementsDict(
      user,
      newslettersToNormalize,
      relations,
      'newsletters',
      'users',
      'idUser',
      'idNewsletter'
    );
    normalizeMultipleElements(
      user,
      societeConfigAnalyseSynthesesToNormalize,
      relations,
      'societeConfigAnalyseSyntheses',
      'user',
      'idUser',
      'idSocieteConfigAnalyseSynthese'
    );
    normalizeMultipleElements(
      user,
      boardStatesToNormalize,
      relations,
      'boardStates',
      'user',
      'idUser',
      'idBoardState'
    );
    normalizeSingleElement(
      user,
      organisationsToNormalize,
      relations,
      'organisation',
      'users',
      'idUser',
      'idOrganisation'
    );

    return {
      ...user,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }
  if (societeTerritoireUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoireUser(societeTerritoireUsersToNormalize, actionType));
  }
  if (campagneUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagneUser(campagneUsersToNormalize, actionType));
  }
  if (composantAttendusToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantAttendu(composantAttendusToNormalize, actionType));
  }
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  if (userSocieteProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserSocieteProfil(userSocieteProfilsToNormalize, actionType));
  }
  const societeProfilsToNormalizeArray = Object.values(societeProfilsToNormalize);
  if (societeProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalizeArray, actionType));
  }
  if (userPatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeUserPatrimoine(userPatrimoinesToNormalize, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  if (composantsToNormalize.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalize, actionType));
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }
  if (userNewslettersToNormalize.length) {
    actions.push(...getActionsToNormalizeUserNewsletter(userNewslettersToNormalize, actionType));
  }
  const newslettersToNormalizeArray = Object.values(newslettersToNormalize);
  if (newslettersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeNewsletter(newslettersToNormalizeArray, actionType));
  }
  if (societeConfigAnalyseSynthesesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthesesToNormalize, actionType));
  }
  if (boardStatesToNormalize.length) {
    actions.push(...getActionsToNormalizeBoardState(boardStatesToNormalize, actionType));
  }

  const userActionCreator = UserGeneratedActions.upsertManyUsersSuccess;
  actions.push(userActionCreator({ users: normalizedUsers }));

  return actions;
}

/**
 *  UserNewsletter
 */
export function getActionsToNormalizeUserNewsletter(
  userNewsletters: UserNewsletterEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const newslettersToNormalize: { [id: number]: NewsletterEntityState } = {};

  const normalizedUserNewsletters = userNewsletters.map((userNewsletter: UserNewsletterEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      userNewsletter,
      usersToNormalize,
      relations,
      'user',
      'userNewsletters',
      'idUserNewsletter',
      'idUser'
    );
    normalizeSingleElement(
      userNewsletter,
      newslettersToNormalize,
      relations,
      'newsletter',
      'userNewsletters',
      'idUserNewsletter',
      'idNewsletter'
    );

    return {
      ...userNewsletter,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const newslettersToNormalizeArray = Object.values(newslettersToNormalize);
  if (newslettersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeNewsletter(newslettersToNormalizeArray, actionType));
  }

  const userNewsletterActionCreator = UserNewsletterGeneratedActions.upsertManyUserNewslettersSuccess;
  actions.push(userNewsletterActionCreator({ userNewsletters: normalizedUserNewsletters }));

  return actions;
}

/**
 *  UserPatrimoine
 */
export function getActionsToNormalizeUserPatrimoine(
  userPatrimoines: UserPatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedUserPatrimoines = userPatrimoines.map((userPatrimoine: UserPatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      userPatrimoine,
      usersToNormalize,
      relations,
      'user',
      'userPatrimoines',
      'idUserPatrimoine',
      'idUser'
    );
    normalizeSingleElement(
      userPatrimoine,
      patrimoinesToNormalize,
      relations,
      'patrimoine',
      'userPatrimoines',
      'idUserPatrimoine',
      'idPatrimoine'
    );

    return {
      ...userPatrimoine,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const userPatrimoineActionCreator = UserPatrimoineGeneratedActions.upsertManyUserPatrimoinesSuccess;
  actions.push(userPatrimoineActionCreator({ userPatrimoines: normalizedUserPatrimoines }));

  return actions;
}

/**
 *  UserSocieteProfil
 */
export function getActionsToNormalizeUserSocieteProfil(
  userSocieteProfils: UserSocieteProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState } = {};

  const normalizedUserSocieteProfils = userSocieteProfils.map((userSocieteProfil: UserSocieteProfilEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      userSocieteProfil,
      usersToNormalize,
      relations,
      'user',
      'userSocieteProfils',
      'idUserSocieteProfil',
      'idUser'
    );
    normalizeSingleElement(
      userSocieteProfil,
      societeProfilsToNormalize,
      relations,
      'societeProfil',
      'userSocieteProfils',
      'idUserSocieteProfil',
      'idSocieteProfil'
    );

    return {
      ...userSocieteProfil,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const societeProfilsToNormalizeArray = Object.values(societeProfilsToNormalize);
  if (societeProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalizeArray, actionType));
  }

  const userSocieteProfilActionCreator = UserSocieteProfilGeneratedActions.upsertManyUserSocieteProfilsSuccess;
  actions.push(userSocieteProfilActionCreator({ userSocieteProfils: normalizedUserSocieteProfils }));

  return actions;
}

/**
 *  Valeur
 */
export function getActionsToNormalizeValeur(
  valeurs: ValeurEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const societeCaracteristiqueChoicesToNormalize: { [id: number]: SocieteCaracteristiqueChoixEntityState } = {};
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const valeurComposantsToNormalize: ValeurComposantEntityState[] = [];
  const composantsToNormalize: { [id: number]: ComposantEntityState } = {};
  const valeurPatrimoinesToNormalize: ValeurPatrimoineEntityState[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const valeurFichiersToNormalize: ValeurFichierEntityState[] = [];
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const valeurAnswersToNormalize: ValeurAnswerEntityState[] = [];

  const normalizedValeurs = valeurs.map((valeur: ValeurEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeMultipleElements(
      valeur,
      valeurComposantsToNormalize,
      relations,
      'valeurComposants',
      'valeur',
      'idValeur',
      'idValeurComposant'
    );
    normalizeMultipleElementsDict(
      valeur,
      composantsToNormalize,
      relations,
      'composants',
      'valeurs',
      'idValeur',
      'idComposant'
    );
    normalizeMultipleElements(
      valeur,
      valeurPatrimoinesToNormalize,
      relations,
      'valeurPatrimoines',
      'valeur',
      'idValeur',
      'idValeurPatrimoine'
    );
    normalizeMultipleElementsDict(
      valeur,
      patrimoinesToNormalize,
      relations,
      'patrimoines',
      'valeurs',
      'idValeur',
      'idPatrimoine'
    );
    normalizeMultipleElements(
      valeur,
      valeurFichiersToNormalize,
      relations,
      'valeurFichiers',
      'valeur',
      'idValeur',
      'idValeurFichier'
    );
    normalizeMultipleElementsDict(
      valeur,
      fichiersToNormalize,
      relations,
      'fichiers',
      'valeurs',
      'idValeur',
      'idFichier'
    );
    normalizeMultipleElements(
      valeur,
      valeurAnswersToNormalize,
      relations,
      'valeurAnswers',
      'valeur',
      'idValeur',
      'idValeurAnswer'
    );
    normalizeSingleElement(
      valeur,
      societeCaracteristiqueChoicesToNormalize,
      relations,
      'societeCaracteristiqueChoix',
      'valeurs',
      'idValeur',
      'idSocieteCaracteristiqueChoix'
    );
    normalizeSingleElement(
      valeur,
      societeCaracteristiquesToNormalize,
      relations,
      'societeCaracteristique',
      'valeurs',
      'idValeur',
      'idSocieteCaracteristique'
    );
    normalizeSingleElement(
      valeur,
      usersToNormalize,
      relations,
      'user',
      'valeurs',
      'idValeur',
      'idUser'
    );

    return {
      ...valeur,
      ...relations
    };
  });
  const societeCaracteristiqueChoicesToNormalizeArray = Object.values(societeCaracteristiqueChoicesToNormalize);
  if (societeCaracteristiqueChoicesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoicesToNormalizeArray, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (valeurComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurComposant(valeurComposantsToNormalize, actionType));
  }
  const composantsToNormalizeArray = Object.values(composantsToNormalize);
  if (composantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalizeArray, actionType));
  }
  if (valeurPatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurPatrimoine(valeurPatrimoinesToNormalize, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  if (valeurFichiersToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurFichier(valeurFichiersToNormalize, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (valeurAnswersToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurAnswer(valeurAnswersToNormalize, actionType));
  }

  const valeurActionCreator = ValeurGeneratedActions.upsertManyValeursSuccess;
  actions.push(valeurActionCreator({ valeurs: normalizedValeurs }));

  return actions;
}

/**
 *  ValeurFichier
 */
export function getActionsToNormalizeValeurFichier(
  valeurFichiers: ValeurFichierEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};

  const normalizedValeurFichiers = valeurFichiers.map((valeurFichier: ValeurFichierEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      valeurFichier,
      valeursToNormalize,
      relations,
      'valeur',
      'valeurFichiers',
      'idValeurFichier',
      'idValeur'
    );
    normalizeSingleElement(
      valeurFichier,
      fichiersToNormalize,
      relations,
      'fichier',
      'valeurFichiers',
      'idValeurFichier',
      'idFichier'
    );

    return {
      ...valeurFichier,
      ...relations
    };
  });
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }

  const valeurFichierActionCreator = ValeurFichierGeneratedActions.upsertManyValeurFichiersSuccess;
  actions.push(valeurFichierActionCreator({ valeurFichiers: normalizedValeurFichiers }));

  return actions;
}

/**
 *  ValeurAnswer
 */
export function getActionsToNormalizeValeurAnswer(
  valeurAnswers: ValeurAnswerEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedValeurAnswers = valeurAnswers.map((valeurAnswer: ValeurAnswerEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      valeurAnswer,
      valeursToNormalize,
      relations,
      'valeur',
      'valeurAnswers',
      'idValeurAnswer',
      'idValeur'
    );
    normalizeSingleElement(
      valeurAnswer,
      patrimoinesToNormalize,
      relations,
      'patrimoine',
      'valeurAnswers',
      'idValeurAnswer',
      'idPatrimoine'
    );

    return {
      ...valeurAnswer,
      ...relations
    };
  });
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const valeurAnswerActionCreator = ValeurAnswerGeneratedActions.upsertManyValeurAnswersSuccess;
  actions.push(valeurAnswerActionCreator({ valeurAnswers: normalizedValeurAnswers }));

  return actions;
}

/**
 *  ValeurComposant
 */
export function getActionsToNormalizeValeurComposant(
  valeurComposants: ValeurComposantEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const composantsToNormalize: { [id: number]: ComposantEntityState } = {};

  const normalizedValeurComposants = valeurComposants.map((valeurComposant: ValeurComposantEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      valeurComposant,
      valeursToNormalize,
      relations,
      'valeur',
      'valeurComposants',
      'idValeurComposant',
      'idValeur'
    );
    normalizeSingleElement(
      valeurComposant,
      composantsToNormalize,
      relations,
      'composant',
      'valeurComposants',
      'idValeurComposant',
      'idComposant'
    );

    return {
      ...valeurComposant,
      ...relations
    };
  });
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  const composantsToNormalizeArray = Object.values(composantsToNormalize);
  if (composantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalizeArray, actionType));
  }

  const valeurComposantActionCreator = ValeurComposantGeneratedActions.upsertManyValeurComposantsSuccess;
  actions.push(valeurComposantActionCreator({ valeurComposants: normalizedValeurComposants }));

  return actions;
}

/**
 *  ValeurPatrimoine
 */
export function getActionsToNormalizeValeurPatrimoine(
  valeurPatrimoines: ValeurPatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedValeurPatrimoines = valeurPatrimoines.map((valeurPatrimoine: ValeurPatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    normalizeSingleElement(
      valeurPatrimoine,
      valeursToNormalize,
      relations,
      'valeur',
      'valeurPatrimoines',
      'idValeurPatrimoine',
      'idValeur'
    );
    normalizeSingleElement(
      valeurPatrimoine,
      patrimoinesToNormalize,
      relations,
      'patrimoine',
      'valeurPatrimoines',
      'idValeurPatrimoine',
      'idPatrimoine'
    );

    return {
      ...valeurPatrimoine,
      ...relations
    };
  });
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const valeurPatrimoineActionCreator = ValeurPatrimoineGeneratedActions.upsertManyValeurPatrimoinesSuccess;
  actions.push(valeurPatrimoineActionCreator({ valeurPatrimoines: normalizedValeurPatrimoines }));

  return actions;
}

/**
 *  ValeurProgIntervention
 */
export function getActionsToNormalizeValeurProgIntervention(
  valeurProgInterventions: ValeurProgInterventionEntityState[]
): Action[] {
  const actions: Action[] = [];

  const normalizedValeurProgInterventions = valeurProgInterventions.map((valeurProgIntervention: ValeurProgInterventionEntityState) => {
    const relations: { [key: string]: number | number[] } = {};

    return {
      ...valeurProgIntervention,
      ...relations
    };
  });

  const valeurProgInterventionActionCreator = ValeurProgInterventionGeneratedActions.upsertManyValeurProgInterventionsSuccess;
  actions.push(valeurProgInterventionActionCreator({ valeurProgInterventions: normalizedValeurProgInterventions }));

  return actions;
}
