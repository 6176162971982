import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ValeurAnswerApiService } from '@get/store/api-services';
import { GeneratedValeurAnswerEffects } from './valeur-answer-generated.effects';

@Injectable()
export class ValeurAnswerEffects extends GeneratedValeurAnswerEffects {
  constructor(actions$: Actions, valeurAnswerApiService: ValeurAnswerApiService, store$: Store<AppState>) {
    super(actions$, valeurAnswerApiService, store$);
  }
}
