import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurEntityState } from '@get/api-interfaces';
import { ValeurApiService } from '@get/store/api-services';
import { ValeurGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeur } from '@get/store/configs/normalization';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { UserGeneratedActions } from '@get/store/actions';
import { ValeurComposantGeneratedActions } from '@get/store/actions';
import { ComposantGeneratedActions } from '@get/store/actions';
import { ValeurPatrimoineGeneratedActions } from '@get/store/actions';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { ValeurFichierGeneratedActions } from '@get/store/actions';
import { FichierGeneratedActions } from '@get/store/actions';
import { ValeurAnswerGeneratedActions } from '@get/store/actions';

export function getDefaultDeleteValeurActions(valeur: ValeurEntityState): Action[] {
  const actions: Action[] = [ValeurGeneratedActions.deleteOneValeurSuccess({ idValeur: valeur.idValeur })];

  if (valeur.societeCaracteristiqueChoix) {
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idSocieteCaracteristiqueChoices: [valeur.societeCaracteristiqueChoix as number]
      })
    );
  }

  if (valeur.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idSocieteCaracteristiques: [valeur.societeCaracteristique as number]
      })
    );
  }

  if (valeur.user) {
    actions.push(
      UserGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idUsers: [valeur.user as number]
      })
    );
  }

  if (valeur.valeurComposants) {
    actions.push(
      ValeurComposantGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idValeurComposants: valeur.valeurComposants as number[]
      })
    );
  }

  if (valeur.composants) {
    actions.push(
      ComposantGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idComposants: valeur.composants as number[]
      })
    );
  }

  if (valeur.valeurPatrimoines) {
    actions.push(
      ValeurPatrimoineGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idValeurPatrimoines: valeur.valeurPatrimoines as number[]
      })
    );
  }

  if (valeur.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idPatrimoines: valeur.patrimoines as number[]
      })
    );
  }

  if (valeur.valeurFichiers) {
    actions.push(
      ValeurFichierGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idValeurFichiers: valeur.valeurFichiers as number[]
      })
    );
  }

  if (valeur.fichiers) {
    actions.push(
      FichierGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idFichiers: valeur.fichiers as number[]
      })
    );
  }

  if (valeur.valeurAnswers) {
    actions.push(
      ValeurAnswerGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idValeurAnswers: valeur.valeurAnswers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedValeurEffects {
  constructor(
    protected actions$: Actions,
    protected valeurApiService: ValeurApiService,
    protected store$: Store<AppState>
  ) {}

  normalizeManyValeursAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.normalizeManyValeursAfterUpsert),
      concatMap(({ valeurs }) => {
        const actions: Action[] = getActionsToNormalizeValeur(valeurs, StoreActionType.upsert);
        return [getMultiAction(actions, '[Valeur] Normalization After Upsert Success')];
      })
    );
  });
}
