export enum CaracteristiqueTypeEnum {
  integer = 'Entier',
  decimal = 'Décimal',
  booleen = 'Binaire',
  date = 'Date',
  string = 'Texte court',
  longText = 'Texte long',
  choix = 'Liste',
  documents = 'Documents',
  images = 'Images',
  hyperlink = 'Lien hypertexte',
  phone = 'Téléphone',
  email = 'Email',
  patrimoines = 'Patrimoines'
}
