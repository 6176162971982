import { Injectable } from '@angular/core';
import { Newsletter, UserNewsletter } from '@get/api-interfaces';
import { NewsletterActions, NewsletterGeneratedActions, UserNewsletterGeneratedActions } from '@get/store/actions';
import { NewsletterApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, switchMap } from 'rxjs';
import { GeneratedNewsletterEffects } from './newsletter-generated.effects';

@Injectable()
export class NewsletterEffects extends GeneratedNewsletterEffects {
  constructor(actions$: Actions, newsletterApiService: NewsletterApiService, store$: Store<AppState>) {
    super(actions$, newsletterApiService, store$);
  }

  getNewslettersUnread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsletterActions.getNewslettersUnread),
      switchMap(({ params }) =>
        this.newsletterApiService.getNewslettersUnread(params).pipe(
          map((newsletters: Newsletter[]) => {
            return NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert({ newsletters });
          }),
          catchError(error => of(NewsletterGeneratedActions.newslettersFailure({ error })))
        )
      )
    );
  });

  markAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsletterActions.markAsRead),
      concatMap(({ idsNewsletter }: { idsNewsletter: number[] }) => {
        return this.newsletterApiService.markAsRead(idsNewsletter).pipe(
          map((userNewsletterReturned: UserNewsletter[]) => {
            return UserNewsletterGeneratedActions.normalizeManyUserNewslettersAfterUpsert({
              userNewsletters: userNewsletterReturned
            });
          }),
          catchError(error => of(NewsletterGeneratedActions.newslettersFailure({ error })))
        );
      })
    );
  });
}
