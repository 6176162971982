import { Injectable } from '@angular/core';
import { CampaignMonitoringInfos } from '@get/api-interfaces';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignMonitoringService {
  private infos$ = new ReplaySubject<CampaignMonitoringInfos | null>(1);

  constructor() {
    this.resetMonitoring();
  }

  public resetMonitoring() {
    this.infos$.next(null);
  }

  public updateMonitoring(infos: CampaignMonitoringInfos): void {
    this.infos$.next(infos);
  }

  public getSelectedInfos(): Observable<CampaignMonitoringInfos | null> {
    return this.infos$.asObservable();
  }
}
