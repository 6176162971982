import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValeurAnswer, ValeurAnswerEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ValeurAnswerState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const valeurAnswerRelations: string[] = ['valeurs','patrimoines',];

export const { selectEntities, selectAll } = ValeurAnswerState.adapter.getSelectors();

export const selectValeurAnswerState = createFeatureSelector<ValeurAnswerState.IState>(ValeurAnswerState.valeurAnswerFeatureKey);

export const selectIsLoadedValeurAnswer = createSelector(
  selectValeurAnswerState,
  (state: ValeurAnswerState.IState) => state.isLoaded
);

export const selectIsLoadingValeurAnswer = createSelector(
  selectValeurAnswerState,
  (state: ValeurAnswerState.IState) => state.isLoading
);

export const selectIsReadyValeurAnswer = createSelector(
  selectValeurAnswerState,
  (state: ValeurAnswerState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedValeurAnswer = createSelector(
  selectValeurAnswerState,
  (state: ValeurAnswerState.IState) => state.isLoaded && !state.isLoading
);

export const selectValeurAnswersEntities = createSelector(selectValeurAnswerState, selectEntities);

export const selectValeurAnswersArray = createSelector(selectValeurAnswerState, selectAll);

const valeurAnswersInObject = (valeurAnswers: Dictionary<ValeurAnswerEntityState>) => ({ valeurAnswers })

const selectValeurAnswersEntitiesDictionary = createSelector(selectValeurAnswersEntities, valeurAnswersInObject);

const selectAllValeurAnswersObject = createSelector(selectValeurAnswersEntities, valeurAnswers => {
  return hydrateAll({ valeurAnswers });
});

const selectOneValeurAnswerDictionary = (idValeurAnswer : number) =>
  createSelector(selectValeurAnswersEntities, valeurAnswers => ({
    valeurAnswers: { [idValeurAnswer]: valeurAnswers[idValeurAnswer] }
  }));

const selectOneValeurAnswerDictionaryWithoutChild = (idValeurAnswer : number) =>
  createSelector(selectValeurAnswersEntities, valeurAnswers => ({
    valeurAnswer: valeurAnswers[idValeurAnswer]
  }));

const selectAllValeurAnswersSelectors: Dictionary<Selector> = {};
export function selectAllValeurAnswers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ValeurAnswer>(
      schema,
      selectAllValeurAnswersSelectors,
      selectValeurAnswersEntitiesDictionary,
      getRelationSelectors,
      valeurAnswerRelations,
      hydrateAll,
      'valeurAnswer'
    );
  } else {
    return selectAllValeurAnswersObject;
  }
}

export function selectAllValeurAnswersDictionary(
  schema: SelectSchema = {},
  customKey = 'valeurAnswers'
): Selector {
  return createSelector(selectAllValeurAnswers(schema), result => {
    const res = { [customKey]: {} as Dictionary<ValeurAnswerEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.valeurAnswers.length; i++) {
      res[customKey][result.valeurAnswers[i].idValeurAnswer] = result.valeurAnswers[i];
    }
    return res;
  });
}

export function selectOneValeurAnswer(
  schema: SelectSchema = {},
  idValeurAnswer: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneValeurAnswerDictionary(idValeurAnswer)];
  selectors.push(...getRelationSelectors(schema, valeurAnswerRelations, 'valeurAnswer'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneValeurAnswerDictionaryWithoutChild(idValeurAnswer);
  }
}

interface hydrateArgs {
  valeurAnswers: Dictionary<ValeurAnswerEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { valeurAnswers: (ValeurAnswer | null)[] } {
  const {
    valeurAnswers,
    valeurs,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    valeurAnswers: Object.keys(valeurAnswers).map(idValeurAnswer =>
      hydrate(
        valeurAnswers[idValeurAnswer] as ValeurAnswerEntityState,
        valeurs,
        patrimoines
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { valeurAnswer: ValeurAnswerEntityState | null } {
  const {
    valeurAnswers,
    valeurs,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const valeurAnswer = Object.values(valeurAnswers)[0];
  return {
    valeurAnswer: hydrate(
      valeurAnswer as ValeurAnswerEntityState,
      valeurs,
      patrimoines
    )
  };
}

function hydrate(
  valeurAnswer: ValeurAnswerEntityState,
  valeurEntities?: Dictionary<ValeurEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
): ValeurAnswer | null {
  if (!valeurAnswer) {
    return null;
  }

  const valeurAnswerHydrated: ValeurAnswerEntityState = { ...valeurAnswer };
  if (valeurEntities) {
    valeurAnswerHydrated.valeur = valeurEntities[valeurAnswer.valeur as number] as Valeur;
  } else {
    delete valeurAnswerHydrated.valeur;
  }
  if (patrimoineEntities) {
    valeurAnswerHydrated.patrimoine = patrimoineEntities[valeurAnswer.patrimoine as number] as Patrimoine;
  } else {
    delete valeurAnswerHydrated.patrimoine;
  }

  return valeurAnswerHydrated as ValeurAnswer;
}
