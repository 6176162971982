import { CaracteristiqueTypeEnum, FileTypeEnum } from '@enums';
import { Fichier, SocieteCaracteristique, Valeur, ValeurFichier } from '@get/api-interfaces';

export function isFichierImage(fichier: Fichier): boolean {
  return fichier?.fileType === FileTypeEnum.image;
}

export function isFichierDocument(fichier: Fichier): boolean {
  return fichier?.fileType === FileTypeEnum.document;
}

export function isFichierIcon(fichier: Fichier): boolean {
  return fichier?.fileType === FileTypeEnum.icon;
}

export function filterValeurFichiers(
  valeurFichiers: ValeurFichier[],
  typeChecker: (fichier: Fichier) => boolean
): ValeurFichier[] {
  return valeurFichiers?.filter(valeurFichier => typeChecker(valeurFichier?.fichier));
}

export function getImagesAndDocumentCount(valeur: Valeur, societeCaracteristique: SocieteCaracteristique) {
  if (valeur?.valeurFichiers?.length) {
    return `${valeur?.valeurFichiers?.length} ${
      societeCaracteristique?.type === CaracteristiqueTypeEnum.images ? 'Photo' : 'Document'
    }${valeur?.valeurFichiers?.length > 1 ? 's' : ''}`;
  }
  return `Aucun${societeCaracteristique?.type === CaracteristiqueTypeEnum.documents ? '' : 'e'} ${
    valeur?.societeCaracteristique?.type === CaracteristiqueTypeEnum.images ? 'Photo' : 'Document'
  }`;
}
